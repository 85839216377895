export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKETS_LOADING = 'GET_TICKETS_LOADING';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_ERROR = 'GET_TICKETS_ERROR';

export const GET_SEARCH_TICKETS = 'GET_SEARCH_TICKETS';
export const GET_SEARCH_TICKETS_LOADING = 'GET_SEARCH_TICKETS_LOADING';
export const GET_SEARCH_TICKETS_SUCCESS = 'GET_SEARCH_TICKETS_SUCCESS';
export const GET_SEARCH_TICKETS_ERROR = 'GET_SEARCH_TICKETS_ERROR';

export const SET_CHAT_INFO_LIST = 'SET_CHAT_INFO_LIST';
export const SET_CHAT_INFO_LIST_LOADING = 'SET_CHAT_INFO_LIST_LOADING';
export const SET_CHAT_INFO_LIST_SUCCESS = 'SET_CHAT_INFO_LIST_SUCCESS';
export const SET_CHAT_INFO_LIST_ERROR = 'SET_CHAT_INFO_LIST_ERROR';

export const GET_CHAT_PREVIEW_INFO = 'GET_CHAT_PREVIEW_INFO';
export const GET_CHAT_PREVIEW_INFO_LOADING = 'GET_CHAT_PREVIEW_INFO_LOADING';
export const GET_CHAT_PREVIEW_INFO_SUCCESS = 'GET_CHAT_PREVIEW_INFO_SUCCESS';
export const GET_CHAT_PREVIEW_INFO_ERROR = 'GET_CHAT_PREVIEW_INFO_ERROR';

export const SET_VIEW_STATUS_AGENT = 'SET_VIEW_STATUS_AGENT';
export const SET_VIEW_STATUS_AGENT_LOADING = 'SET_VIEW_STATUS_AGENT_LOADING';
export const SET_VIEW_STATUS_AGENT_SUCCESS = 'SET_VIEW_STATUS_AGENT_SUCCESS';
export const SET_VIEW_STATUS_AGENT_ERROR = 'SET_VIEW_STATUS_AGENT_ERROR';

export const GET_VIEW_STATUS_OF_AGENT = 'GET_VIEW_STATUS_OF_AGENT';
export const GET_VIEW_STATUS_OF_AGENT_LOADING = 'GET_VIEW_STATUS_OF_AGENT_LOADING';
export const GET_VIEW_STATUS_OF_AGENT_SUCCESS = 'GET_VIEW_STATUS_OF_AGENT_SUCCESS';
export const GET_VIEW_STATUS_OF_AGENT_ERROR = 'GET_VIEW_STATUS_OF_AGENT_ERROR';

export const SET_UPLOAD_FILE_INFO = 'SET_UPLOAD_FILE_INFO';
export const SET_UPLOAD_FILE_INFO_LOADING = 'SET_UPLOAD_FILE_INFO_LOADING';
export const SET_UPLOAD_FILE_INFO_SUCCESS = 'SET_UPLOAD_FILE_INFO_SUCCESS';
export const SET_UPLOAD_FILE_INFO_ERROR = 'SET_UPLOAD_FILE_INFO_ERROR';

export const SET_ACTIVE_TABS = 'SET_ACTIVE_TABS';
export const SET_ACTIVE_TABS_LOADING = 'SET_ACTIVE_TABS_LOADING';
export const SET_ACTIVE_TABS_SUCCESS = 'SET_ACTIVE_TABS_SUCCESS';
export const SET_ACTIVE_TABS_ERROR = 'SET_ACTIVE_TABS_ERROR';

export const GET_ACTIVE_TABS = 'GET_ACTIVE_TABS';
export const GET_ACTIVE_TABS_LOADING = 'GET_ACTIVE_TABS_LOADING';
export const GET_ACTIVE_TABS_SUCCESS = 'GET_ACTIVE_TABS_SUCCESS';
export const GET_ACTIVE_TABS_ERROR = 'GET_ACTIVE_TABS_ERROR';

export const GET_TYPING_MESSAGE = 'GET_TYPING_MESSAGE';
export const GET_TYPING_MESSAGE_LOADING = 'GET_TYPING_MESSAGE_LOADING';
export const GET_TYPING_MESSAGE_SUCCESS = 'GET_TYPING_MESSAGE_SUCCESS';
export const GET_TYPING_MESSAGE_ERROR = 'GET_TYPING_MESSAGE_ERROR';

export const SET_NEW_EXTERNAL_TICKET = 'SET_NEW_EXTERNAL_TICKET';
export const SET_NEW_EXTERNAL_TICKET_LOADING = 'SET_NEW_EXTERNAL_TICKET_LOADING';
export const SET_NEW_EXTERNAL_TICKET_SUCCESS = 'SET_NEW_EXTERNAL_TICKET_SUCCESS';
export const SET_NEW_EXTERNAL_TICKET_ERROR = 'SET_NEW_EXTERNAL_TICKET_ERROR';

export const SET_NEW_INTERNAL_TICKET = 'SET_NEW_INTERNAL_TICKET';
export const SET_NEW_INTERNAL_TICKET_LOADING = 'SET_NEW_INTERNAL_TICKET_LOADING';
export const SET_NEW_INTERNAL_TICKET_SUCCESS = 'SET_NEW_INTERNAL_TICKET_SUCCESS';
export const SET_NEW_INTERNAL_TICKET_ERROR = 'SET_NEW_INTERNAL_TICKET_ERROR';

export const GET_PARTICIPANTS = 'GET_PARTICIPANTS';
export const GET_PARTICIPANTS_LOADING = 'GET_PARTICIPANTS_LOADING';
export const GET_PARTICIPANTS_SUCCESS = 'GET_PARTICIPANTS_SUCCESS';
export const GET_PARTICIPANTS_ERROR = 'GET_PARTICIPANTS_ERROR';

export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const SET_PARTICIPANTS_LOADING = 'SET_PARTICIPANTS_LOADING';
export const SET_PARTICIPANTS_SUCCESS = 'SET_PARTICIPANTS_SUCCESS';
export const SET_PARTICIPANTS_ERROR = 'SET_PARTICIPANTS_ERROR';

export const SET_SPLIT_TICKET = 'SET_SPLIT_TICKET';
export const SET_SPLIT_TICKET_LOADING = 'SET_SPLIT_TICKET_LOADING';
export const SET_SPLIT_TICKET_SUCCESS = 'SET_SPLIT_TICKET_SUCCESS';
export const SET_SPLIT_TICKET_ERROR = 'SET_SPLIT_TICKET_ERROR';

export const SET_TRANSFER_TICKET = 'SET_TRANSFER_TICKET';
export const SET_TRANSFER_TICKET_LOADING = 'SET_TRANSFER_TICKET_LOADING';
export const SET_TRANSFER_TICKET_SUCCESS = 'SET_TRANSFER_TICKET_SUCCESS';
export const SET_TRANSFER_TICKET_ERROR = 'SET_TRANSFER_TICKET_ERROR';

export const GET_TEMP_CODE_INFO = 'GET_TEMP_CODE_INFO';
export const GET_TEMP_CODE_INFO_LOADING = 'GET_TEMP_CODE_INFO_LOADING';
export const GET_TEMP_CODE_INFO_SUCCESS = 'GET_TEMP_CODE_INFO_SUCCESS';
export const GET_TEMP_CODE_INFO_ERROR = 'GET_TEMP_CODE_INFO_ERROR';

export const GET_UPLOAD_LINK = 'GET_UPLOAD_LINK';
export const GET_UPLOAD_LINK_LOADING = 'GET_UPLOAD_LINK_LOADING';
export const GET_UPLOAD_LINK_SUCCESS = 'GET_UPLOAD_LINK_SUCCESS';
export const GET_UPLOAD_LINK_ERROR = 'GET_UPLOAD_LINK_ERROR';

export const GET_CUSTOM_SEARCH_TICKET = 'GET_CUSTOM_SEARCH_TICKET';
export const GET_CUSTOM_SEARCH_TICKET_LOADING = 'GET_CUSTOM_SEARCH_TICKET_LOADING';
export const GET_CUSTOM_SEARCH_TICKET_SUCCESS = 'GET_CUSTOM_SEARCH_TICKET_SUCCESS';
export const GET_CUSTOM_SEARCH_TICKET_ERROR = 'GET_CUSTOM_SEARCH_TICKET_ERROR';

export const SET_PARTICIPANTS_MODAL = 'SET_PARTICIPANTS_MODAL';

export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_LOADING_STATE_SUCCESS = 'SET_LOADING_STATE_SUCCESS';

export const SET_ACTIVE_TAB_LOADING = 'SET_ACTIVE_TAB_LOADING';
export const SET_ACTIVE_TAB_LOADING_SUCCESS = 'SET_ACTIVE_TAB_LOADING_SUCCESS';

// data push into reducer
// ---------------------------
export const UPDATE_MESSAGE_DATA = 'UPDATE_MESSAGE_DATA';
export const UPDATE_NOTE_DATA = 'UPDATE_NOTE_DATA';
export const UPDATE_REOPEN_TICKET = 'UPDATE_REOPEN_TICKET';
export const UPDATE_RESOLVED_TICKET = 'UPDATE_RESOLVED_TICKET';
export const UPDATE_TRANSFER_TICKET = 'UPDATE_TRANSFER_TICKET';
// ---------------------------

export const SET_SEARCHED_TICKETS = 'SET_SEARCHED_TICKETS';
export const SET_INPUT_TYPE = 'SET_INPUT_TYPE';
export const SET_INPUT_SUBMIT_TYPE = 'SET_INPUT_SUBMIT_TYPE';
export const SET_MESSAGES_READ = 'SET_MESSAGES_READ';
export const PUSH_ACTIVE_TABS = 'PUSH_ACTIVE_TABS';
export const SET_MAIN_TICKET_SCROLL = 'SET_MAIN_TICKET_SCROLL';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';
export const REMOVE_ACTIVE_TAB = 'REMOVE_ACTIVE_TAB';
export const REMOVE_ACTIVE_TAB_SUCCESS = 'REMOVE_ACTIVE_TAB_SUCCESS';
export const SET_CUSTOM_SEARCH_MODAL = 'SET_CUSTOM_SEARCH_MODAL';
export const SET_NEW_TICKET_MODAL = 'SET_NEW_TICKET_MODAL';
export const SET_TYPING_MESSAGE = 'SET_TYPING_MESSAGE';
export const UPDATE_POSTPONE_TICKET = 'UPDATE_POSTPONE_TICKET';
export const SET_BUTTON_INPUT_CLICK = 'SET_BUTTON_INPUT_CLICK';
export const REMOVE_TYPING_MESSAGE = 'REMOVE_TYPING_MESSAGE';
export const SET_TICKET_LOADING = 'SET_TICKET_LOADING';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const SET_MAX_DATE = 'SET_MAX_DATE';
export const SET_FORWARD_EMAIL_INPUT = 'SET_FORWARD_EMAIL_INPUT';
export const SET_INPUT_MESSAGE = 'SET_INPUT_MESSAGE';
export const SET_NOTE_MESSAGE = 'SET_NOTE_MESSAGE';
export const SET_MESSAGE_SNIPPET = 'SET_MESSAGE_SNIPPET';
export const SET_TAB_TITLE = 'SET_TAB_TITLE';
export const ATTCHMENT_VISIBLE = 'ATTCHMENT_VISIBLE';
export const REMOVE_ATTCHMENT_ITEM = 'REMOVE_ATTCHMENT_ITEM';
export const FOOTER_ACTIONS_LOADING = 'FOOTER_ACTIONS_LOADING';
export const CREATE_NEW_TICKET_LOADING = 'CREATE_NEW_TICKET_LOADING';
export const SET_FORWARD_MESSAGE_INFO = 'SET_FORWARD_MESSAGE_INFO';
export const SET_WEB_NOTIFICATION = 'SET_WEB_NOTIFICATION';
export const SET_PREV_ACTIVE_TAB_INFO = 'SET_PREV_ACTIVE_TAB_INFO';
export const SAVE_ASSIGN_TICKETS_LENGTH = 'SAVE_ASSIGN_TICKETS_LENGTH';
export const SET_GROUP_CHAT_DIALOG = 'SET_GROUP_CHAT_DIALOG';
export const SET_GROUP_CHAT_INFO = 'SET_GROUP_CHAT_INFO';
export const PUSHED_GROUP_CHAT_MESSAGE = 'PUSHED_GROUP_CHAT_MESSAGE';
export const CHANGE_RENDER_MESSAGE_TYPE = 'CHANGE_RENDER_MESSAGE_TYPE';
export const SET_GROUP_CHAT_ID = 'SET_GROUP_CHAT_ID';
export const PUSH_ACTIVE_TAB_LIST = 'PUSH_ACTIVE_TAB_LIST';
// export const REMOVE_CHAT_OBJECT = 'REMOVE_CHAT_OBJECT';
export const SET_GROUP_CHAT_INPUT_VALIUE = 'SET_GROUP_CHAT_INPUT_VALIUE';
export const SET_TICKET_SEARCH_VALIUE = 'SET_TICKET_SEARCH_VALIUE';
export const SET_GROUP_CHAT_WIDTH = 'SET_GROUP_CHAT_WIDTH';
export const SET_EDIT_NOTE = 'SET_EDIT_NOTE';
export const SET_GROUP_CHAT_LOADING = 'SET_GROUP_CHAT_LOADING';
