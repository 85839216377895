import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { reducer as authReducer } from '../modules/authForms';
import { reducer as sideNavbarReducer } from '../shared/components/AppNavigation';
import { reducer as deviceDetectorReducer } from '../shared/components/DeviceDetector';
import { reducer as ticketsReducer } from '../modules/tickets';
import todoReducer from '../shared/TodoRedux/reducer';

const appReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  sideNavbar: sideNavbarReducer,
  tickets: ticketsReducer,
  deviceDetector: deviceDetectorReducer,
  localInfo: todoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
