import React from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ChangePasswordForm from './ChangePasswordForm';
import { actions } from '../../../../../modules/authForms';
import { required, isEqual } from '../../../../../utils/validators';
import { noop } from '../../../../../utils';
import { showNotification } from '../../../../../utils/Notifications';

import './change_password_form.scss';

const ChangePasswordFormContainer = ({
  setChangePasswordDialog,
  setChangeUserPassword,
  resetForm,
}) => {
  const history = useHistory();

  const onSubmit = (values) => {
    setChangeUserPassword(values.currentpassword, values.password, values.reenterpassword)
      .then((res) => {
        if (res.value.data.response_msg === 'Success') {
          alert('Your password is changed successfully, please login again');
          setChangePasswordDialog(false);
          resetForm('ChangePasswordForm');
          history.push('/logout');
        } else {
          showNotification(res.value.data.response_msg, 'error', 5000);
        }
      });
  };

  const validateForm = (values) => {
    const errors = {};
    errors.currentpassword = required(values.currentpassword) && 'Password is required';
    errors.password = required(values.password) && 'Password is required';
    errors.reenterpassword = required(values.reenterpassword) && 'Password is required';
    if (!(errors.password && errors.reenterpassword)) {
      errors.reenterpassword = !isEqual(values.password, values.reenterpassword) && 'Password and Re-Enter Password should match';
    }
    return errors;
  };
  return (
    <ChangePasswordForm
      onSubmit={onSubmit}
      validate={validateForm}
    />
  );
};

ChangePasswordFormContainer.propTypes = {
  resetForm: PropTypes.func,
  setChangeUserPassword: PropTypes.func,
  setChangePasswordDialog: PropTypes.func,
};

ChangePasswordFormContainer.defaultProps = {
  resetForm: noop,
  setChangeUserPassword: noop,
  setChangePasswordDialog: noop,
};

const mapDispatchToProps = (dispatch) => ({
  resetForm: (formName) => dispatch(reset(formName)),
  setChangeUserPassword: (cpass, npass, rpass) => dispatch(
    actions.setChangeUserPassword(cpass, npass, rpass),
  ),
  setChangePasswordDialog: (isVisible) => dispatch(
    actions.setChangePasswordDialog(isVisible),
  ),
});

export default connect(
  null, mapDispatchToProps,
)(ChangePasswordFormContainer);
