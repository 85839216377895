import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';

import { RenderInput, RenderButton } from '../../../FormControl';
import { noop } from '../../../../../utils';

const RecipientsForm = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)} className="participants_form">
    <div className="participants_input_wrapper">
      <span>To: </span>
      <Field
        autoFocus
        name="To"
        id="To"
        placeholder="To"
        type="text"
        component={RenderInput}
      />
    </div>
    <div className="participants_input_wrapper">
      <span>CC: </span>
      <Field
        name="CC"
        id="CC"
        placeholder="CC"
        type="text"
        component={RenderInput}
      />
    </div>
    <div className="participants_input_wrapper">
      <span>BCC: </span>
      <Field
        name="BCC"
        id="BCC"
        placeholder="BCC"
        type="text"
        component={RenderInput}
      />
    </div>
    <div className="participants_btn">
      <RenderButton>
        Submit
      </RenderButton>
    </div>
  </Form>
);

RecipientsForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

RecipientsForm.defaultProps = {
  onSubmit: noop,
};

export default reduxForm({
  form: 'participantsForm',
})(RecipientsForm);
