import * as actionTypes from './actionTypes';

export const setTodoList = (todo) => ({
  type: actionTypes.SET_TODO_LIST,
  payload: todo,
});

export const removeTodo = (id) => ({
  type: actionTypes.REMOVE_TODO,
  payload: id,
});

export const setHiddenFeaturesModel = (isOpen) => ({
  type: actionTypes.SET_HIDDEN_FEATURES_MODAL,
  payload: isOpen,
});

export const handleHiddenFeatures = (type, isChecked) => ({
  type: actionTypes.HANDLE_HIDDEN_FEATURES,
  payload: { type, value: isChecked },
});

export const setFlags = (data) => ({
  type: actionTypes.SET_FLAGS,
  payload: data,
});
