import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  FaTimes, FaHome, FaCaretDown, FaRegEnvelope, FaEnvelope,
} from 'react-icons/fa';

import CustomBadge from '../../../../shared/components/CustomBadge/CustomBadgeContainer';
import { noop } from '../../../../utils';
import { RenderDropDown } from '../../../../shared/components/FormControl';

const TabRenderedList = ({
  handleCreateTicket,
  handleActiveTab,
  activeTabsInfo,
  homeTabLoading,
  assignTickets,
  removeTicket,
  isActiveTab,
  location,
}) => (
  <div className="tab_rendered_seciton">
    <Link to="/ticket">
      <div
        className={classnames('home_tab', { active: !location.search && location.pathname === '/ticket' })}
        role="presentation"
      >
        <RenderDropDown
          icon={<FaCaretDown />}
          id="create-new-ticket-dropdown"
          className="create_ticket_dropdown"
          popoverClass="create_ticket_popover"
        >
          <li onClick={(e) => handleCreateTicket(e, 'External')} role="presentation">
            <FaRegEnvelope />
            New ticket
          </li>
          <li onClick={(e) => handleCreateTicket(e, 'Internal')} role="presentation">
            <FaEnvelope />
            New internal ticket
          </li>
        </RenderDropDown>
        <FaHome />
        <span className="items_length">{assignTickets.length}</span>
        {homeTabLoading && <mwc-linear-progress indeterminate />}
      </div>
    </Link>
    {activeTabsInfo && activeTabsInfo.map((tabsItem) => (
      <div
        onClick={() => handleActiveTab({
          subject: tabsItem.subject,
          conversationid: tabsItem.conversationid,
          code: tabsItem.code,
          status: tabsItem.status,
          agentid: tabsItem.agentid,
          unassigned: tabsItem.unassigned,
          invert: tabsItem.invert,
          dateStart: tabsItem.dateStart,
          dateEnd: tabsItem.dateEnd,
          meta: tabsItem.meta,
          tabName: tabsItem.tabName,
          lastDays: tabsItem.lastDays,
        })}
        key={tabsItem.key}
        title={tabsItem.tabName || tabsItem.subject || tabsItem.key}
        role="presentation"
        className="tab_item"
        id={tabsItem.key}
      >
        <CustomBadge
          title={tabsItem.tabName || tabsItem.subject || 'Custom Search'}
          rightIcon={<FaTimes />}
          rightClick={() => removeTicket(tabsItem.key, tabsItem.type)}
          active={isActiveTab(tabsItem.key)}
          tabType={tabsItem.type}
          totalcount={tabsItem.messageCount}
          length={tabsItem.length}
          linearLoading={tabsItem.isLoading}
          keyText={tabsItem.key}
        />
      </div>
    ))}
  </div>
);

TabRenderedList.propTypes = {
  homeTabLoading: PropTypes.bool,
  isActiveTab: PropTypes.func,
  removeTicket: PropTypes.func,
  handleActiveTab: PropTypes.func,
  handleCreateTicket: PropTypes.func,
  activeTabsInfo: PropTypes.instanceOf(Array),
  assignTickets: PropTypes.instanceOf(Array),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

TabRenderedList.defaultProps = {
  homeTabLoading: false,
  isActiveTab: noop,
  removeTicket: noop,
  handleActiveTab: noop,
  handleCreateTicket: noop,
  activeTabsInfo: [],
  assignTickets: [],
};

export default TabRenderedList;
