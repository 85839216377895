import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HiddenFeatureDialog from './HiddenFeatureDialog';
import * as localInfoActions from '../../../TodoRedux/actions';
import { hiddenFeatureOptions, noop } from '../../../../utils';

import './hidden_feature_dialog.scss';

const HiddenFeatureDialogContainer = ({
  hiddenFeaturesModel,
  setHiddenFeaturesModel,
  handleHiddenFeatures,
  isHiddenFeatures,
  setTodoList,
  isSorting,
  isFlagOnTop,
  isTodoOnTop,
  setFlags,
  isOpenTicket,
  isTheme,
  isFlag,
  isTodo,
}) => {
  const removeLocalStorage = () => {
    hiddenFeatureOptions.forEach((feature) => {
      localStorage.removeItem(feature);
      handleHiddenFeatures(feature, false);
    });
    localStorage.removeItem('flags');
    localStorage.removeItem('todo-list');
    localStorage.removeItem('filter');
    setHiddenFeaturesModel(false);
    setTodoList([]);
    setFlags([]);
  };

  return (
    <HiddenFeatureDialog
      isTodo={isTodo}
      isFlag={isFlag}
      isFlagOnTop={isFlagOnTop}
      isTodoOnTop={isTodoOnTop}
      isTheme={isTheme}
      isSorting={isSorting}
      isModal={hiddenFeaturesModel}
      isOpenTicket={isOpenTicket}
      isHiddenFeatures={isHiddenFeatures}
      handleHiddenFeatures={handleHiddenFeatures}
      removeLocalStorage={removeLocalStorage}
      handleModal={() => setHiddenFeaturesModel(false)}
    />
  );
};

HiddenFeatureDialogContainer.propTypes = {
  hiddenFeaturesModel: PropTypes.bool,
  isHiddenFeatures: PropTypes.bool,
  isTodo: PropTypes.bool,
  isSorting: PropTypes.bool,
  isFlag: PropTypes.bool,
  isFlagOnTop: PropTypes.bool,
  isTodoOnTop: PropTypes.bool,
  isTheme: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  setHiddenFeaturesModel: PropTypes.func,
  handleHiddenFeatures: PropTypes.func,
  setTodoList: PropTypes.func,
  setFlags: PropTypes.func,
};

HiddenFeatureDialogContainer.defaultProps = {
  hiddenFeaturesModel: false,
  isHiddenFeatures: false,
  isTodo: false,
  isSorting: false,
  isFlag: false,
  isFlagOnTop: false,
  isTheme: false,
  isTodoOnTop: false,
  isOpenTicket: false,
  setHiddenFeaturesModel: noop,
  handleHiddenFeatures: noop,
  setTodoList: noop,
  setFlags: noop,
};

const mapStateToProps = (state) => ({
  hiddenFeaturesModel: state.localInfo.hiddenFeaturesModel,
  isHiddenFeatures: state.localInfo.isHiddenFeatures,
  isTodo: state.localInfo.isTodo,
  isSorting: state.localInfo.isSorting,
  isFlag: state.localInfo.isFlag,
  isOpenTicket: state.localInfo.isOpenTicket,
  isTheme: state.localInfo.isTheme,
  isFlagOnTop: state.localInfo.isFlagOnTop,
  isTodoOnTop: state.localInfo.isTodoOnTop,
});

const mapDispatchToProps = (dispatch) => ({
  setHiddenFeaturesModel: (isOpen) => dispatch(localInfoActions.setHiddenFeaturesModel(isOpen)),
  handleHiddenFeatures: (type, isChecked) => dispatch(
    localInfoActions.handleHiddenFeatures(type, isChecked),
  ),
  setTodoList: (data) => dispatch(localInfoActions.setTodoList(data)),
  setFlags: (data) => dispatch(localInfoActions.setFlags(data)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(HiddenFeatureDialogContainer);
