import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'react-web-notification';

import { noop } from '../../../utils';

const WebNotification = ({
  handleNotificationOnClose,
  handleNotificationOnClick,
  handleNotificationOnShow,
  handleAudibleType,
  audibleType,
  timeout,
  audible,
  notify,
  title,
}) => (
  notify && (
    <>
      <Notification
        title={title}
        timeout={timeout}
        onShow={handleNotificationOnShow}
        onClose={handleNotificationOnClose}
        onClick={handleNotificationOnClick}
      />
      {audible && audibleType && handleAudibleType(audibleType)}
    </>
  )
);

WebNotification.propTypes = {
  title: PropTypes.string,
  audibleType: PropTypes.string,
  timeout: PropTypes.number,
  audible: PropTypes.bool,
  notify: PropTypes.bool,
  handleAudibleType: PropTypes.func,
  handleNotificationOnShow: PropTypes.func,
  handleNotificationOnClose: PropTypes.func,
  handleNotificationOnClick: PropTypes.func,
};

WebNotification.defaultProps = {
  title: undefined,
  audibleType: 'sent',
  timeout: 5000,
  audible: true,
  notify: false,
  handleAudibleType: noop,
  handleNotificationOnShow: noop,
  handleNotificationOnClose: noop,
  handleNotificationOnClick: noop,
};

export default WebNotification;
