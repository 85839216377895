/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';
import without from 'lodash.without';
import { connect } from 'react-redux';

import TypingDraft from './TypingDraft';

import './typing_draft.scss';

const preventRendering = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps.typingInfo) !== JSON.stringify(nextProps.typingInfo)) {
    return false;
  }
  return true;
};

const TypingDraftContainer = React.memo(({
  groupChatInfo,
  typingInfo,
  userInfo,
  users,
}) => {
  const fetchUser = (id) => {
    if (id) {
      const name = users
        .find((agent) => agent.accountuserid === id);
      return name && name.firstname;
    } return null;
  };

  const viewInfo = (viewList) => {
    if (viewList) {
      const viewMeta = viewList.map((typeItem) => fetchUser(typeItem.userid));
      const views = without(viewMeta, userInfo.firstname);
      return views ? views.join(', ') : '';
    }
    return [];
  };

  return (
    <TypingDraft
      viewInfo={viewInfo}
      typingInfo={typingInfo && typingInfo[groupChatInfo.groupChatId]}
    />
  );
},
(prevProps, nextProps) => preventRendering(prevProps, nextProps));

TypingDraftContainer.propTypes = {
  typingInfo: PropTypes.instanceOf(Object),
  groupChatInfo: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Array),
};

TypingDraftContainer.defaultProps = {
  typingInfo: {},
  groupChatInfo: {},
  userInfo: {},
  users: [],
};

const mapStateToProps = (state) => ({
  users: state.auth.users,
  userInfo: state.auth.userInfo,
  typingInfo: state.tickets.typingInfo,
  groupChatInfo: state.tickets.groupChatInfo,
});

export default connect(
  mapStateToProps,
)(TypingDraftContainer);
