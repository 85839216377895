import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChangePaawordDialog from './ChangePaawordDialog';
import RequestStates from '../../../../utils/request-states';
import { actions as authFormsActions } from '../../../../modules/authForms';
import { noop } from '../../../../utils';

const ChangePaawordDialogContainer = ({
  changePasswordDialog,
  setChangePasswordDialog,
  isLoading,
}) => (
  <ChangePaawordDialog
    isLoading={isLoading}
    isModalVisible={changePasswordDialog}
    setChangePasswordDialog={setChangePasswordDialog}
  />
);

ChangePaawordDialogContainer.propTypes = {
  isLoading: PropTypes.bool,
  changePasswordDialog: PropTypes.bool,
  setChangePasswordDialog: PropTypes.func,
};

ChangePaawordDialogContainer.defaultProps = {
  isLoading: false,
  changePasswordDialog: false,
  setChangePasswordDialog: noop,
};

const mapStateToProps = (state) => ({
  changePasswordDialog: state.auth.changePasswordDialog,
  isLoading: state.auth.changePasswordState === RequestStates.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setChangePasswordDialog: (isVisible) => dispatch(
    authFormsActions.setChangePasswordDialog(isVisible),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ChangePaawordDialogContainer);
