import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ActivityTimer from './ActivityTimer';
import { actions } from '../../../modules/authForms';

import './activity_timer.scss';
import { noop } from '../../../utils';

const ActivityTimerContainer = ({
  serverTotalTimeTracking,
  serverTotalMobileSecs,
  handleTimeTracking,
  serverTimeTracking,
  serverMobileSecs,
  lastActivityTime,
  pauseTracking,
  getTotalTime,
}) => (
  <ActivityTimer
    getTotalTime={getTotalTime}
    lastActivityTime={lastActivityTime}
    pauseTracking={pauseTracking}
    serverTimeTracking={serverTimeTracking}
    serverMobileSecs={serverMobileSecs}
    handleTimeTracking={handleTimeTracking}
    serverTotalTimeTracking={serverTotalTimeTracking}
    serverTotalMobileSecs={serverTotalMobileSecs}
  />
);

ActivityTimerContainer.propTypes = {
  serverTimeTracking: PropTypes.string,
  serverTotalTimeTracking: PropTypes.string,
  serverTotalMobileSecs: PropTypes.string,
  serverMobileSecs: PropTypes.string,
  lastActivityTime: PropTypes.string,
  pauseTracking: PropTypes.bool,
  handleTimeTracking: PropTypes.func,
  getTotalTime: PropTypes.func,
};

ActivityTimerContainer.defaultProps = {
  serverTimeTracking: undefined,
  serverTotalTimeTracking: undefined,
  serverTotalMobileSecs: undefined,
  serverMobileSecs: undefined,
  lastActivityTime: undefined,
  pauseTracking: false,
  handleTimeTracking: noop,
  getTotalTime: noop,
};

const mapStateToProps = (state) => ({
  serverTimeTracking: state.auth.serverTimeTracking,
  serverMobileSecs: state.auth.serverMobileSecs,
  serverTotalTimeTracking: state.auth.serverTotalTimeTracking,
  serverTotalMobileSecs: state.auth.serverTotalMobileSecs,
  pauseTracking: state.auth.pauseTracking,
  lastActivityTime: state.auth.lastActivityTime,
});

const mapDispatchToProps = (dispatch) => ({
  getTotalTime: () => dispatch(actions.getTotalTime()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ActivityTimerContainer);
