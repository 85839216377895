/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { noop } from '../../../utils';

const CustomDropZone = ({
  getInputProps,
  getRootProps,
  isDragActive,
  className,
  title,
  id,
}) => (
  <div {...getRootProps({ className: classnames(`custom_dropzone ${className}`, { dropzone_highlight: isDragActive }) })}>
    <input {...getInputProps()} id={id} />
    <p>{title}</p>
  </div>
);

CustomDropZone.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  isDragActive: PropTypes.bool,
  getRootProps: PropTypes.func,
  getInputProps: PropTypes.func,
};

CustomDropZone.defaultProps = {
  title: 'upload here',
  className: '',
  id: 'custom-dropzone',
  isDragActive: false,
  getRootProps: noop,
  getInputProps: noop,
};

export default CustomDropZone;
