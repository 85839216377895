/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import TicketDropZone from './TicketDropZone';
import { showNotification } from '../../../../utils/Notifications';
import { noop, validateSize } from '../../../../utils';
import { maxAllowedFileSize } from '../../../../config';
import './ticket_dropzone.scss';

const TicketDropZoneContainer = React.memo(({
  setUploadFiles,
  uploadFiles,
  id,
}) => {
  const previewAdded = (fileItem) => fileItem.map(
    (file) => Object.assign(file, { preview: URL.createObjectURL(file) }),
  );

  const fetchFile = (prevState, fileinfo) => {
    const fileItems = previewAdded(fileinfo);
    if (prevState) { fileItems.push(...prevState); }
    return previewAdded(fileItems);
  };

  const onDropFiles = async (info) => {
    const validFileSizeItems = info.filter((fitem) => validateSize(fitem.size));
    const notValidFileSizeItems = info.filter((fitem) => !validateSize(fitem.size));
    if (validFileSizeItems.length > 0) {
      setUploadFiles((prevState) => fetchFile(prevState, validFileSizeItems));
    }
    if (notValidFileSizeItems.length > 0) {
      showNotification(`${notValidFileSizeItems.length} file(s) skipped because of file size limit. Max allowed size is ${maxAllowedFileSize} MB`, 'error', false);
    }
  };

  const handleClosePreview = (preview) => {
    const removedItem = uploadFiles.filter((fileInfo) => fileInfo.preview !== preview);
    setUploadFiles(previewAdded(removedItem));
  };

  useEffect(() => () => {
    uploadFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [uploadFiles]);

  return (
    <TicketDropZone
      id={id}
      onDropFiles={onDropFiles}
      fileUploadInfo={uploadFiles}
      handleClosePreview={handleClosePreview}
    />
  );
});

TicketDropZoneContainer.propTypes = {
  id: PropTypes.string,
  setUploadFiles: PropTypes.func,
  uploadFiles: PropTypes.instanceOf(Array),
};

TicketDropZoneContainer.defaultProps = {
  id: 'chatsection-dropzone',
  setUploadFiles: noop,
  uploadFiles: [],
};

export default TicketDropZoneContainer;
