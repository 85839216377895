import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SanitizingHtml from '../../../../../shared/components/SanitizingHtml/SanitizingHtml';
import GroupChatAttachmentInfo from '../../../../../shared/components/AttachmentInfo/GroupChatAttachmentInfo';
import { formatHTML } from '../../../../../utils';
import { BASE_URL } from '../../../../../config';

const CardBox = ({
  handleAttachmentDownload,
  handleLightBoxPreview,
  chatPerosnalInfo,
  handleImageClick,
  fileAttachment,
  otherAgentReply,
  searchValue,
  newMessage,
  noteMsg,
  msgId,
}) => (
  <>
    {chatPerosnalInfo.date && (
    <p className="datetime">
      {chatPerosnalInfo.date}
    </p>
    )}
    {((noteMsg && noteMsg.length > 0) || (fileAttachment && fileAttachment.length > 0)) && (
      <div key={msgId} className={classnames('card_section', { agent_reply: !otherAgentReply })}>
        {chatPerosnalInfo.name && (
          <div className="card_preview_section">
            <p className="profile_image">{chatPerosnalInfo.name.charAt(0)}</p>
            <p className="agent_name">{chatPerosnalInfo.name}</p>
          </div>
        )}
        <div className="card_content">
          <div className={classnames('message_content', { newMessage })}>
            {noteMsg && noteMsg.map((messageItem) => (
              messageItem.message && (
              <SanitizingHtml
                key={messageItem.message}
                htmlToRender={formatHTML(
                  messageItem.message,
                  messageItem.format,
                  null,
                  searchValue,
                )}
              />
              )
            ))}
            {fileAttachment
              && fileAttachment.length !== 0
              && fileAttachment.map(
                (file, index) => file.attachment
                  && file.attachment.map((attachmentItem) => (
                    <GroupChatAttachmentInfo
                      src={`${BASE_URL}command.jsp?command=file_link&file=${file.message}`}
                      fileType={attachmentItem.filetype}
                      fileName={attachmentItem.filename}
                      handleImageClick={() => handleLightBoxPreview(index)}
                      handleViewClick={
                        () => handleImageClick(
                          attachmentItem.filename,
                          file.message,
                          attachmentItem.filetype,
                        )
                      }
                      handleDownload={() => handleAttachmentDownload(file.message)}
                    />
                  )),
              )}
          </div>
          {chatPerosnalInfo.time && <span className="reply_time">{chatPerosnalInfo.time}</span>}
        </div>
      </div>
    )}
  </>
);

CardBox.propTypes = {
  msgId: PropTypes.string,
  searchValue: PropTypes.string,
  otherAgentReply: PropTypes.bool,
  newMessage: PropTypes.bool,
  handleAttachmentDownload: PropTypes.func,
  handleImageClick: PropTypes.func,
  handleLightBoxPreview: PropTypes.func,
  noteMsg: PropTypes.instanceOf(Array),
  fileAttachment: PropTypes.instanceOf(Object),
  chatPerosnalInfo: PropTypes.instanceOf(Object),
};

CardBox.defaultProps = {
  msgId: '',
  searchValue: '',
  otherAgentReply: false,
  newMessage: false,
  handleAttachmentDownload: () => {},
  handleImageClick: () => {},
  handleLightBoxPreview: () => {},
  noteMsg: [],
  fileAttachment: {},
  chatPerosnalInfo: {},
};

export default CardBox;
