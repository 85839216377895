import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';

import CustomDialog from '../../CustomDialog/CustomDialog';
import RecipientsForm from './RecipientsForm/RecipientsFormContainer';
import { SpinnerLoader } from '../../Loader';
import { noop } from '../../../../utils';

const RecipientsDialog = ({
  setParticipantsModal,
  isModalVisible,
  isLoading,
}) => (
  <CustomDialog
    isOpen={isModalVisible}
    toggle={() => setParticipantsModal(false)}
    title="Recipients"
  >
    <BlockUi
      tag="div"
      blocking={isLoading}
      loader={<SpinnerLoader />}
      className="full_height full_width block-ui-background"
    >
      <RecipientsForm />
    </BlockUi>
  </CustomDialog>
);

RecipientsDialog.propTypes = {
  isLoading: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  setParticipantsModal: PropTypes.func,
};

RecipientsDialog.defaultProps = {
  isLoading: false,
  isModalVisible: false,
  setParticipantsModal: noop,
};

export default RecipientsDialog;
