import React from 'react';
import PropTypes from 'prop-types';
import {
  Popper, Reference, Manager,
} from 'react-popper';

import { FaAngleDoubleDown, FaAngleDoubleUp, FaEye } from 'react-icons/fa';
import { noop } from '../../../../utils';
import Portal from '../../../../utils/portal';

const LiveStatusInfo = ({
  toggleExpand,
  getUserName,
  viewStatus,
  newJoining,
  newLeaves,
  expand,
}) => (
  ((viewStatus.length > 0) || newLeaves.length > 0 || newJoining.length > 0) && (
    <div className="livestatus_info_section">
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              className="livestatus_info_header"
              role="presentation"
              onClick={toggleExpand}
              ref={ref}
            >
              {newJoining[0] && <span><FaEye /> {getUserName(newJoining[0])} joined</span>}
              {newLeaves[0] && <span><FaEye /> {getUserName(newLeaves[0])} left</span>}
              {!newJoining[0] && !newLeaves[0] && (
              <span><FaEye /> {viewStatus.length} viewing</span>
              )}
              {expand ? <FaAngleDoubleDown /> : <FaAngleDoubleUp />}
            </div>
          )}
        </Reference>
        {expand && (
        <Portal>
          <Popper placement="top">
            {({ ref, style, placement }) => (
              <div
                className="livestatus_info_content"
                ref={ref}
                style={style}
                data-placement={placement}
              >
                {viewStatus.map((status) => (
                  <span className="status_item">
                    <FaEye />
                    {getUserName(status.split(':')[0])}
                  </span>
                ))}
              </div>
            )}
          </Popper>
        </Portal>
        )}
      </Manager>
    </div>
  )
);

LiveStatusInfo.propTypes = {
  expand: PropTypes.bool,
  toggleExpand: PropTypes.func,
  getUserName: PropTypes.func,
  newJoining: PropTypes.instanceOf(Array),
  newLeaves: PropTypes.instanceOf(Array),
  viewStatus: PropTypes.instanceOf(Array),
};

LiveStatusInfo.defaultProps = {
  expand: false,
  toggleExpand: noop,
  getUserName: noop,
  newJoining: [],
  newLeaves: [],
  viewStatus: [],
};

export default LiveStatusInfo;
