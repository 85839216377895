import React from 'react';
import PropTypes from 'prop-types';

import './render_checkbox.scss';

const RenderCheckBox = ({
  name, value,
}) => (
  <div className="render_checkbox">
    <input
      type="checkbox"
      id={name}
      name={name}
      value={value}
      className="cursor_pointer"
    />
    <label htmlFor={name}>
      {value}
    </label>
  </div>
);

RenderCheckBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

RenderCheckBox.defaultProps = {
  name: '',
  value: '',
};

export default RenderCheckBox;
