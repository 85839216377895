import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { RenderInput, RenderButton } from '../../FormControl';
import { noop } from '../../../../utils';

const TodoDialog = ({
  handleSubmit,
  handleModal,
  isModal,
  onSubmit,
}) => (
  <CustomDialog
    isOpen={isModal}
    toggle={handleModal}
    title="Add Todo"
    classNames="todo_dialog"
  >
    <Form onSubmit={handleSubmit(onSubmit)} className="todo_form">
      <div className="todo_input_wrapper">
        <span>Title: </span>
        <Field
          name="title"
          id="title"
          placeholder="Title"
          type="text"
          component={RenderInput}
        />
      </div>
      <div className="todo_input_wrapper">
        <span>Description: </span>
        <Field
          name="description"
          id="description"
          placeholder="Description"
          type="text"
          component={RenderInput}
        />
      </div>
      <RenderButton type="submit">
        Add
      </RenderButton>
    </Form>
  </CustomDialog>
);

TodoDialog.propTypes = {
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleModal: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

TodoDialog.defaultProps = {
  isModal: false,
  onSubmit: noop,
  handleModal: noop,
};

export default reduxForm({
  form: 'TodoForm',
})(TodoDialog);
