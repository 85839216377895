const times = [
  {
    label: 'PST (USA)',
    timezone: 'America/Los_Angeles',
  }, {
    label: 'MST (USA)',
    timezone: 'America/Denver',
  }, {
    label: 'CST (USA)',
    timezone: 'America/Chicago',
  }, {
    label: 'EST (USA)',
    timezone: 'America/New_York',
  }, {
    label: 'London',
    timezone: 'Europe/London',
  }, {
    label: 'CET',
    timezone: 'Europe/Berlin',
  }, {
    label: 'Romania',
    timezone: 'Europe/Bucharest',
  }, {
    label: 'India',
    timezone: 'Asia/Calcutta',
  }, {
    label: 'AEST (AU)',
    timezone: 'Australia/ACT',
  },
];

export default times;
