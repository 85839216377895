/* eslint-disable dot-notation */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CardBox from './CardBox';
import ImageLightBox from '../../../../../shared/components/ImageLightBox/ImageLightBoxContainer';
import { getMimeType } from '../../../../../utils';
import { BASE_URL } from '../../../../../config';

import './card_box.scss';

const CardBoxContainer = React.memo(({
  chatPerosnalInfo,
  otherAgentReply,
  groupChatInfo,
  messageList,
  newMessage,
  chatInfo,
  msgId,
}) => {
  const [lightBoxPreview, setLightBoxPreview] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  // const toastMsg = messageList
  //   && messageList.find(
  //     (msgData) => msgData.rtype === 'T',
  //   );

  const noteMsg = messageList
    && messageList.filter(
      (msgData) => msgData.rtype === 'N' || msgData.rtype === 'U' || msgData.rtype === 'S',
    );

  const fileAttachment = messageList && messageList.filter((msgData) => msgData.rtype === 'L' || msgData.rtype === 'F');

  const handleAttachmentDownload = (link) => {
    const a = document.createElement('a');
    a.href = `${BASE_URL}command.jsp?command=file_link&file=${link}&download=true`;
    a.click();
  };

  const handleImageClick = (fileName, link, type) => {
    const ext = fileName.split('.').pop();
    const mimeType = getMimeType(ext, type);
    window.open(
      `${BASE_URL}command.jsp?command=file_link&file=${link}&mimetype=${mimeType}`,
    );
  };

  const fetchImageUrls = () => {
    if (fileAttachment && fileAttachment.length > 0) {
      const urls = fileAttachment.map((fileItem) => ({
        src: `${BASE_URL}command.jsp?command=file_link&file=${fileItem.message}`,
        alt: fileItem.attachment[0].filename,
      }));
      return urls;
    } return [];
  };

  const handleLightBoxPreview = (id) => {
    setImgIndex(id);
    setLightBoxPreview(true);
  };

  const handlePreviewClose = () => {
    const previewDiv = document.getElementsByClassName('react-viewer')[0].parentNode;
    previewDiv.remove();
    setLightBoxPreview(false);
  };

  return (
    <>
      <CardBox
        msgId={msgId}
        noteMsg={noteMsg}
        newMessage={newMessage}
        searchValue={chatInfo && chatInfo[groupChatInfo.groupChatId]
          && chatInfo[groupChatInfo.groupChatId].messageInfo
          && chatInfo[groupChatInfo.groupChatId].searchValue}
        otherAgentReply={otherAgentReply}
        fileAttachment={fileAttachment}
        handleImageClick={handleImageClick}
        chatPerosnalInfo={chatPerosnalInfo}
        handleLightBoxPreview={handleLightBoxPreview}
        handleAttachmentDownload={handleAttachmentDownload}
      />
      {lightBoxPreview && fetchImageUrls().length > 0 && (
        <ImageLightBox
          images={fetchImageUrls()}
          visible={lightBoxPreview}
          onClose={handlePreviewClose}
          activeIndex={imgIndex}
        />
      )}
    </>
  );
});

CardBoxContainer.propTypes = {
  msgId: PropTypes.string,
  otherAgentReply: PropTypes.bool,
  newMessage: PropTypes.bool,
  messageList: PropTypes.instanceOf(Array),
  chatPerosnalInfo: PropTypes.instanceOf(Object),
  groupChatInfo: PropTypes.instanceOf(Object),
  chatInfo: PropTypes.instanceOf(Object),
};

CardBoxContainer.defaultProps = {
  msgId: '',
  otherAgentReply: false,
  newMessage: false,
  messageList: [],
  chatPerosnalInfo: {},
  groupChatInfo: {},
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  chatInfo: state.tickets.chat,
  groupChatInfo: state.tickets.groupChatInfo,
});

export default connect(
  mapStateToProps,
)(CardBoxContainer);
