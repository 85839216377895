import axios from 'axios';

import { store } from '../index';
import { BASE_URL } from '../config';
import { showErrorModal } from '../modules/authForms/redux/actions';

const axiosApi = axios.create({
  withCredentials: true,
  baseURL: `${BASE_URL}`,
});

axiosApi.interceptors.response.use((response) => response, (err) => {
  if (err.response && err.response.status === 401) {
    const storeInfo = store.getState();
    if (!storeInfo.auth.errorModal) {
      store.dispatch(showErrorModal(true));
    }
  }
  return Promise.reject(err);
});

export default axiosApi;
