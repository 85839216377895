import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import ErrorHandlingDialog from './ErrorHandlingDialog';
import { actions } from '../../../../modules/authForms';

import './error_handling_dialog.scss';
import { noop } from '../../../../utils';

const ErrorHandlingDialogContainer = ({
  errorModal,
  showErrorModal,
}) => {
  const history = useHistory();

  const submitHandler = () => {
    showErrorModal(false);
    history.push('/signin');
  };

  return (
    <ErrorHandlingDialog
      submitHandler={submitHandler}
      errorModal={errorModal}
    />
  );
};

ErrorHandlingDialogContainer.propTypes = {
  errorModal: PropTypes.bool,
  showErrorModal: PropTypes.func,
};

ErrorHandlingDialogContainer.defaultProps = {
  errorModal: false,
  showErrorModal: noop,
};

const mapStateToProps = (state) => ({
  errorModal: state.auth.errorModal,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorModal: (isOpen) => dispatch(actions.showErrorModal(isOpen)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ErrorHandlingDialogContainer);
