import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';

import DefaultNote from './DefaultNote/DefaultNoteContainer';
import TextEditor from './TextEditor/TextEditorContainer';
import NoteEditor from './NoteEditor/NoteEditorContainer';
import ActionsButton from './ActionsButton/ActionsButtonContainer';
import LiveStatusInfoContainer from '../LiveStatusInfo/LiveStatusInfoContainer';
import TicketDropZone from '../TicketDropZone/TicketDropZoneContainer';
import { SpinnerLoader } from '../../../../shared/components/Loader';
import { noop } from '../../../../utils';

const ActionFooter = ({
  setSelectedTransfer,
  footerActionLoading,
  selectedTransfer,
  setPostponeDate,
  setStatusTicket,
  setForwardError,
  scrollToBottom,
  setUploadFiles,
  footerActions,
  postponeDate,
  statusTicket,
  forwardError,
  setTransfer,
  uploadFiles,
  userList,
}) => (
  <>
    <BlockUi
      tag="div"
      blocking={footerActionLoading}
      renderChildren
      className="full_height full_width block-ui-background"
      loader={<SpinnerLoader />}
    >
      {!footerActions.inputType && (
        <DefaultNote />
      )}
      {footerActions.inputType === 'M' && (
        <TextEditor
          scrollToBottom={scrollToBottom}
          forwardError={forwardError}
        />
      )}
      {footerActions.inputType === 'N' && (
        <NoteEditor
          userList={userList}
          setSelectedTransfer={setSelectedTransfer}
          selectedTransfer={selectedTransfer}
          scrollToBottom={scrollToBottom}
          setPostponeDate={setPostponeDate}
          postponeDate={postponeDate}
          setStatusTicket={setStatusTicket}
          statusTicket={statusTicket}
        />
      )}
      {footerActions.isAttchment && (
        <TicketDropZone
          setUploadFiles={setUploadFiles}
          uploadFiles={uploadFiles}
          id="chatsection-dropzone"
        />
      )}
      {footerActions.inputType && (
        <ActionsButton
          setTransfer={setTransfer}
          scrollToBottom={scrollToBottom}
          selectedTransfer={selectedTransfer}
          postponeDate={postponeDate}
          setPostponeDate={setPostponeDate}
          statusTicket={statusTicket}
          setForwardError={setForwardError}
          uploadFiles={uploadFiles}
          setUploadFiles={setUploadFiles}
        />
      )}
      <LiveStatusInfoContainer />
    </BlockUi>
  </>
);

ActionFooter.propTypes = {
  postponeDate: PropTypes.instanceOf(Date),
  statusTicket: PropTypes.string,
  forwardError: PropTypes.bool,
  footerActionLoading: PropTypes.bool,
  setSelectedTransfer: PropTypes.func,
  setTransfer: PropTypes.func,
  scrollToBottom: PropTypes.func,
  setPostponeDate: PropTypes.func,
  setStatusTicket: PropTypes.func,
  setForwardError: PropTypes.func,
  setUploadFiles: PropTypes.func,
  userList: PropTypes.instanceOf(Array),
  uploadFiles: PropTypes.instanceOf(Array),
  footerActions: PropTypes.instanceOf(Object),
  selectedTransfer: PropTypes.instanceOf(Object),
};

ActionFooter.defaultProps = {
  postponeDate: new Date(),
  statusTicket: 'C',
  forwardError: false,
  footerActionLoading: false,
  setSelectedTransfer: noop,
  setTransfer: noop,
  scrollToBottom: noop,
  setPostponeDate: noop,
  setStatusTicket: noop,
  setForwardError: noop,
  setUploadFiles: noop,
  userList: [],
  uploadFiles: [],
  footerActions: {},
  selectedTransfer: {},
};

export default ActionFooter;
