import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  FiMail, FiLogOut, FiX, FiKey, FiSearch,
} from 'react-icons/fi';

import CrushftpLogo from '../../../../../../assets/images/crush_icon.png';
import Portal from '../../../../../../utils/portal';
import { noop } from '../../../../../../utils';

import './mobile_navigation.scss';

const MobileNavigation = ({
  setChangePasswordDialog,
  setCustomSearchModal,
  handleExpander,
  isExpanded,
  selectedUrl,
}) => (
  <>
    {isExpanded && (
    <Portal>
      <div className="mobile_navigation_section">
        <div className="mobile_navigation_container">
          <div className="navigation_header">
            <img
              alt="logo"
              src={CrushftpLogo}
            />
            <FiX onClick={handleExpander} />
          </div>
          <ul className="navigation_item_list">
            <li className={`${selectedUrl === '/ticket' ? 'activeNav' : ''}`}>
              <Link
                to="/ticket"
                onClick={handleExpander}
                className="navigation_item"
              >
                <FiMail />
                <span className="navbar_list_title">
                  Tickets
                </span>
              </Link>
            </li>
            <li>
              <div
                className="navigation_item"
                onClick={() => {
                  handleExpander();
                  setCustomSearchModal();
                }}
                role="presentation"
              >
                <FiSearch />
                <span className="navbar_list_title">
                  Custom Search
                </span>
              </div>
            </li>
            <li>
              <div
                className="navigation_item"
                onClick={() => {
                  handleExpander();
                  setChangePasswordDialog(true);
                }}
                role="presentation"
              >
                <FiKey />
                <span className="navbar_list_title">
                  Change Password
                </span>
              </div>
            </li>
            <li>
              <Link
                to="/logout"
                onClick={handleExpander}
                className="navigation_item"
              >
                <FiLogOut />
                <span className="navbar_list_title">
                  Logout
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div
          className="navigation_overlay_bar"
          onClick={handleExpander}
          role="presentation"
        />
      </div>
    </Portal>
    )}
  </>
);

MobileNavigation.propTypes = {
  selectedUrl: PropTypes.string,
  isExpanded: PropTypes.bool,
  handleExpander: PropTypes.func,
  setChangePasswordDialog: PropTypes.func,
  setCustomSearchModal: PropTypes.func,
};

MobileNavigation.defaultProps = {
  selectedUrl: '',
  isExpanded: false,
  handleExpander: noop,
  setChangePasswordDialog: noop,
  setCustomSearchModal: noop,
};

export default MobileNavigation;
