import React from 'react';
import PropTypes from 'prop-types';
import {
  FaRegEdit, FaReply,
} from 'react-icons/fa';
import Rstatus from '../../../../../shared/data/Rstatus.json';
import RightButtons from '../ActionsButton/RightButtons/RightButtonsContainer';
import { noop } from '../../../../../utils';

const DefaultNote = ({
  previewInfo,
  handleReply,
  handleNote,
  deviceType,
}) => (
  <div className="default_note_section">
    <div className="default_note_box">
      <div className="default_note_left">
        {previewInfo.emails && (
          <>
            <span
              className="cursor_pointer text_bolder"
              role="presentation"
              onClick={() => handleReply()}
            >
              <span className="text_underline"><FaReply className="rel_icon_text" /> Reply</span>
            </span>
            &nbsp; or &nbsp;
          </>
        )}
        <span
          className="cursor_pointer text_bolder"
          role="presentation"
          onClick={() => handleNote()}
        >
          <span className="text_underline"><FaRegEdit className="rel_icon_text" /> Add a note</span>
        </span>
      </div>
      <span className="ticket_status">
        {deviceType === 'desktop' && <RightButtons />}
        {Rstatus && Rstatus[previewInfo.rstatus] && Rstatus[previewInfo.rstatus].title}
        {deviceType === 'mobile' && <RightButtons />}
      </span>
    </div>
  </div>
);

DefaultNote.propTypes = {
  deviceType: PropTypes.string,
  handleNote: PropTypes.func,
  handleReply: PropTypes.func,
  previewInfo: PropTypes.instanceOf(Object),
};

DefaultNote.defaultProps = {
  deviceType: '',
  handleNote: noop,
  handleReply: noop,
  previewInfo: {},
};

export default DefaultNote;
