import React from 'react';
import PropTypes from 'prop-types';
import { MdAssignment } from 'react-icons/md';
import {
  FaSave, FaPaperclip, FaFileUpload, FaKey,
} from 'react-icons/fa';

import Tooltip from '../../../../../shared/Tooltip';
import MessageSnippet from '../../../../../shared/data/MessageSnippet.json';
import { RenderButton, RenderDropDown } from '../../../../../shared/components/FormControl';
import { noop } from '../../../../../utils';

const ActionsButton = ({
  fetchGetUploadLinkInfo,
  handleChangeSnippet,
  handleTempCodeInfo,
  onHandleAttchment,
  onSubmitHandler,
  footerActios,
  typingInfo,
  deviceType,
  onCancel,
}) => (
  <div className="actions_button_section">
    {footerActios.inputType && (
    <div className="left_section">
      <RenderButton onClick={(e) => { onSubmitHandler(e, ''); }}>
        <FaSave className="rel_icon_text" />
        submit
      </RenderButton>
      <RenderButton
        onClick={(e) => { onSubmitHandler(e, 'closeTab'); }}
      >
        <FaSave className="rel_icon_text" />
        send & close
      </RenderButton>
      <div role="presentation" onClick={() => { onCancel(); }} className="cancel_button">
        cancel
      </div>
      {deviceType === 'desktop' && typingInfo && typingInfo.length > 0 && (
        <p className="red_alert_typing">
          Another agent is typing…
        </p>
      )}
    </div>
    )}
    <div className="right_section">
      {footerActios.inputType === 'M' && (
        <Tooltip placement="top" trigger="hover" tooltip="Insert temp upload link">
          <FaFileUpload className="footer_action_icons" onClick={() => { fetchGetUploadLinkInfo(); }} />
        </Tooltip>
      )}
      {footerActios.inputType === 'M' && (
        <Tooltip placement="top" trigger="hover" tooltip="Insert temp reg code">
          <FaKey className="footer_action_icons" onClick={() => { handleTempCodeInfo(); }} />
        </Tooltip>
      )}
      {footerActios.inputType && (
        <Tooltip placement="top" trigger="hover" tooltip="Add files">
          <FaPaperclip className="footer_action_icons" onClick={() => { onHandleAttchment(); }} />
        </Tooltip>
      )}
      {footerActios.inputType === 'M' && (
        <Tooltip placement="top" trigger="hover" tooltip="Insert snippet">
          <RenderDropDown
            icon={<MdAssignment />}
            id="predefined messages"
            alt="Predefined messages"
          >
            {MessageSnippet.map((snippetItem) => (
              <li
                key={snippetItem.id}
                onClick={() => { handleChangeSnippet(snippetItem.id); }}
                role="presentation"
              >
                {snippetItem.title}{' '}{snippetItem.message}
              </li>
            ))}
          </RenderDropDown>
        </Tooltip>
      )}
    </div>
  </div>
);

ActionsButton.propTypes = {
  deviceType: PropTypes.string,
  onSubmitHandler: PropTypes.func,
  onCancel: PropTypes.func,
  onHandleAttchment: PropTypes.func,
  handleChangeSnippet: PropTypes.func,
  handleTempCodeInfo: PropTypes.func,
  fetchGetUploadLinkInfo: PropTypes.func,
  footerActios: PropTypes.instanceOf(Object),
  typingInfo: PropTypes.instanceOf(Array),
};

ActionsButton.defaultProps = {
  deviceType: '',
  onSubmitHandler: noop,
  onCancel: noop,
  onHandleAttchment: noop,
  handleChangeSnippet: noop,
  handleTempCodeInfo: noop,
  fetchGetUploadLinkInfo: noop,
  footerActios: {},
  typingInfo: [],
};

export default ActionsButton;
