import React from 'react';
import PropTypes from 'prop-types';

import './note.scss';

const Note = ({ children }) => (
  <div className="note_section shadow_effect">
    {children}
  </div>
);

Note.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

export default Note;
