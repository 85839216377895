import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FaUserFriends } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { RenderDropDown } from '../../../../FormControl';
import { ServerDateTimeWithoutSecondFormat } from '../../../../../../utils';

const AgentList = ({
  isShowUserStatus,
  users,
}) => {
  const history = useHistory();

  return (
    <RenderDropDown
      icon={<FaUserFriends />}
      id="agents tickets with count"
      alt="agents tickets with count"
      popoverClass="agents_ticket_popover"
      className="agents_ticket_icon"
    >
      {users && users.map((userInfo) => (
        <li
          key={userInfo.accountuserid}
          className="specific_agents_tickets"
          role="presentation"
          onClick={() => history.push(`/ticket?q=&status=C&agentid=${userInfo.accountuserid}&unassigned=false&invert=false&meta=nd&tab_name=${userInfo.firstname}`)}
        >
          {isShowUserStatus && (userInfo.isActive ? (
            <div className="active_status" />
          ) : (
            <div className="idle_status" />
          ))}
          <div className="specific_agents_label">
            <div>
              <p>{userInfo.firstname}</p>
              {userInfo && userInfo.last_activity && (
                <div className="last_activity_time">
                  <b>Last active:</b>&nbsp;&nbsp;
                  <div>
                    <p>{userInfo.last_activity}</p>
                    <p>
                      ({
                        moment(userInfo.last_activity, ServerDateTimeWithoutSecondFormat).fromNow()
                      })
                    </p>
                  </div>
                </div>
              )}
            </div>
            <p className="agents_counts">{userInfo.totalTicket || 0}</p>
          </div>
        </li>
      ))}
    </RenderDropDown>
  );
};

AgentList.propTypes = {
  isShowUserStatus: PropTypes.bool,
  users: PropTypes.instanceOf(Array),
};

AgentList.defaultProps = {
  isShowUserStatus: false,
  users: [],
};

export default AgentList;
