import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FilterdTickets from './FilterdTickets';
import { noop } from '../../../../../utils';

import './filterd_tickets.scss';

const FilterdTicketsContainer = ({
  setFilterType,
  removeFilter,
  filterType,
  isSorting,
}) => (
  <FilterdTickets
    removeFilter={removeFilter}
    setFilterType={setFilterType}
    filterType={filterType}
    isSorting={isSorting}
  />
);

FilterdTicketsContainer.propTypes = {
  isSorting: PropTypes.bool,
  setFilterType: PropTypes.func,
  removeFilter: PropTypes.func,
  filterType: PropTypes.instanceOf(Object),
};

FilterdTicketsContainer.defaultProps = {
  isSorting: false,
  setFilterType: noop,
  removeFilter: noop,
  filterType: {},
};

const mapStateToProps = (state) => ({
  isSorting: state.localInfo.isSorting,
});

export default connect(
  mapStateToProps, null,
)(FilterdTicketsContainer);
