import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
  selectedUrl: '',
  isMobileExpanded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_MOBILE_SIDE_NAVBAR:
      return {
        ...state,
        isMobileExpanded: action.payload,
      };
    case actionTypes.SET_SIDE_NAVBAR_ITEM:
      return {
        ...state,
        selectedUrl: action.payload,
      };
    default:
      return state;
  }
};
