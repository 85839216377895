import React from 'react';
import PropTypes from 'prop-types';

import MessageBoxContainer from '../../../../../shared/components/MessageBox/MessageBoxContainer';
import NoteSectionContainer from '../NoteSection/NoteSectionContainer';
import { noop } from '../../../../../utils';

const MessageWrapper = ({
  findOurReply,
  replyToInfo,
  chatInfo,
}) => (
  <>
    {chatInfo && chatInfo.messageInfo
      && chatInfo.messageInfo.map((messageItem) => (
        messageItem && (messageItem.rtype === 'M'
        || messageItem.rtype === 'Q' || messageItem.rtype === 'O') ? (
          <MessageBoxContainer
            key={`${messageItem.message_groupid}-message`}
            messageInfo={messageItem}
            isOurReply={findOurReply(messageItem.messages)}
            additionalInfo={replyToInfo(messageItem.messages)}
            searchValue={chatInfo.searchValue}
          />
          ) : (
            <NoteSectionContainer
              key={`${messageItem.message_groupid}-noteSection`}
              uniqueId={`${messageItem.message_groupid}-note`}
              msgInfo={messageItem}
              messageGroup={messageItem.messages}
              searchValue={chatInfo.searchValue}
            />
          )))}
  </>
);

MessageWrapper.propTypes = {
  replyToInfo: PropTypes.func,
  findOurReply: PropTypes.func,
  chatInfo: PropTypes.instanceOf(Object),
};

MessageWrapper.defaultProps = {
  replyToInfo: noop,
  findOurReply: noop,
  chatInfo: {},
};

export default MessageWrapper;
