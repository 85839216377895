import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';

import { RenderInput, RenderButton } from '../../../FormControl';
import { noop } from '../../../../../utils';

const InternalTicketForm = ({
  setToEmailRef,
  handleSubmit,
  onSubmit,
}) => (
  <Form className="internal_ticket_form" onSubmit={handleSubmit(onSubmit)}>
    <div className="ticket_input_wrapper">
      <p className="input_label">Subject: </p>
      <Field
        name="Subject"
        id="Subject"
        placeholder="Subject"
        type="text"
        component={RenderInput}
        className="create_ticket_input"
        innerRef={setToEmailRef}
      />
    </div>
    <p className="input_label">Message: </p>
    <Field
      name="Message"
      id="Message"
      placeholder="Message"
      type="textarea"
      component={RenderInput}
      className="create_ticket_textarea"
    />
    <RenderButton className="create_ticket_submit">
      Create
    </RenderButton>
  </Form>
);

InternalTicketForm.propTypes = {
  onSubmit: PropTypes.func,
  setToEmailRef: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

InternalTicketForm.defaultProps = {
  onSubmit: noop,
  setToEmailRef: noop,
};

export default reduxForm({
  form: 'newInternalTicketForm',
})(InternalTicketForm);
