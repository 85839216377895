import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RenderDropDown from './RenderDropDown';

import './render_drop_down.scss';

const RenderDropDownContainer = ({
  popoverClass,
  clickable,
  className,
  innerRef,
  children,
  title,
  icon,
  id,
}) => {
  const [show, setShow] = useState(false);

  const handleListener = (e) => {
    const flyoutElement = document.getElementById(`${id}`);
    let targetElement = e.target;
    do {
      if (!clickable && targetElement.nodeName.toLowerCase() === 'p') {
        return;
      }
      if (targetElement === flyoutElement) return;
      targetElement = targetElement.parentNode;
    } while (targetElement);
    setShow(false);
  };

  const dropdownHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleListener);
    return () => {
      document.removeEventListener('click', handleListener);
    };
  }, []);

  return (
    <RenderDropDown
      id={id}
      show={show}
      icon={icon}
      title={title}
      innerRef={innerRef}
      className={className}
      popoverClass={popoverClass}
      dropdownHandler={dropdownHandler}
    >
      {children}
    </RenderDropDown>
  );
};

RenderDropDownContainer.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  popoverClass: PropTypes.string,
  clickable: PropTypes.bool,
  innerRef: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

RenderDropDownContainer.defaultProps = {
  title: '',
  id: '',
  className: '',
  popoverClass: undefined,
  clickable: true,
  innerRef: React.createRef(),
  icon: <></>,
};

export default RenderDropDownContainer;
