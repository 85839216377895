import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Popper, Reference, Manager,
} from 'react-popper';

import Portal from '../../../../utils/portal';
import { noop } from '../../../../utils';

const RenderDropDown = ({
  dropdownHandler,
  popoverClass,
  className,
  innerRef,
  position,
  children,
  title,
  show,
  icon,
  id,
}) => (
  <div
    className={classnames(`dropdown_section ${className}`, { show })}
    id={id}
    key={title}
    ref={innerRef}
  >
    <Manager>
      <Reference>
        {({ ref }) => (
          <button
            type="submit"
            className="dropdown_btn"
            aria-controls="dropdown"
            onClick={dropdownHandler}
            ref={ref}
          >
            {icon}
            {title}
          </button>
        )}
      </Reference>
      {show && (
        <Portal>
          <Popper placement={position}>
            {({ ref, style, placement }) => (
              <ul
                className={classnames(`dropdown_list ${popoverClass}`)}
                ref={ref}
                style={style}
                data-placement={placement}
              >
                {children}
              </ul>
            )}
          </Popper>
        </Portal>
      )}
    </Manager>
  </div>
);

RenderDropDown.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.string,
  popoverClass: PropTypes.string,
  show: PropTypes.bool,
  dropdownHandler: PropTypes.func,
  innerRef: PropTypes.instanceOf(Object),
  icon: PropTypes.element,
  children: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

RenderDropDown.defaultProps = {
  position: 'bottom',
  title: '',
  id: '',
  className: '',
  popoverClass: undefined,
  show: false,
  innerRef: React.createRef(),
  icon: <></>,
  dropdownHandler: noop,
};

export default RenderDropDown;
