import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SideNavigation from './SideNavigation';
import * as actions from '../../redux/actions';
import { actions as authFormsActions } from '../../../../../modules/authForms';
import { actions as ticketActions } from '../../../../../modules/tickets';
import { withDeviceType } from '../../../../../hoc';
import { noop } from '../../../../../utils';

const SideNavigationContainer = ({
  setChangePasswordDialog,
  setCustomSearchModal,
  setMobileSideNavbar,
  isMobileExpanded,
  selectedUrl,
  deviceType,
}) => {
  const handleMobileExpander = () => (
    setMobileSideNavbar(!isMobileExpanded)
  );

  return (
    <SideNavigation
      deviceType={deviceType}
      selectedUrl={selectedUrl}
      isMobileExpanded={isMobileExpanded}
      setCustomSearchModal={setCustomSearchModal}
      handleMobileExpander={handleMobileExpander}
      setChangePasswordDialog={setChangePasswordDialog}
    />
  );
};

SideNavigationContainer.propTypes = {
  selectedUrl: PropTypes.string,
  deviceType: PropTypes.string,
  isMobileExpanded: PropTypes.bool,
  setMobileSideNavbar: PropTypes.func,
  setChangePasswordDialog: PropTypes.func,
  setCustomSearchModal: PropTypes.func,
};

SideNavigationContainer.defaultProps = {
  selectedUrl: '',
  deviceType: '',
  isMobileExpanded: false,
  setMobileSideNavbar: noop,
  setChangePasswordDialog: noop,
  setCustomSearchModal: noop,
};

const mapStateToProps = (state) => ({
  isMobileExpanded: state.sideNavbar.isMobileExpanded,
  selectedUrl: state.sideNavbar.selectedUrl,
});

const mapDispatchToProps = (dispatch) => ({
  setMobileSideNavbar:
    (isMobileExpanded) => dispatch(actions.setMobileSideNavbar(isMobileExpanded)),
  setChangePasswordDialog: (isVisible) => dispatch(
    authFormsActions.setChangePasswordDialog(isVisible),
  ),
  setCustomSearchModal: () => dispatch(ticketActions.setCustomSearchModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDeviceType(SideNavigationContainer));
