import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { noop } from '../../../utils';

const AddRemoveTodo = ({
  handleRemoveTodo,
  handleAddTodo,
  isTodoItem,
  id,
}) => (
  isTodoItem.length === 0 ? (
    <FaPlus onClick={handleAddTodo} />
  ) : (
    <FaTrashAlt onClick={(e) => handleRemoveTodo(e, id)} />
  )
);

AddRemoveTodo.propTypes = {
  id: PropTypes.string,
  handleRemoveTodo: PropTypes.func,
  handleAddTodo: PropTypes.func,
  isTodoItem: PropTypes.instanceOf(Array),
};

AddRemoveTodo.defaultProps = {
  id: '',
  handleRemoveTodo: noop,
  handleAddTodo: noop,
  isTodoItem: [],
};

export default AddRemoveTodo;
