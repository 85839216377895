import React from 'react';
import PropTypes from 'prop-types';

import CardBox from '../CardBox/CardBoxContiner';
import { noop } from '../../../../../utils';

const ChatContentSection = ({
  fetchDateChanged,
  messageList,
  userInfo,
}) => (
  messageList.length > 0 && (
    <>
      {messageList.map((msgItem) => (
        <CardBox
          messageList={msgItem.messages}
          otherAgentReply={msgItem.userid === userInfo.agentid}
          msgId={msgItem.message_groupid}
          key={msgItem.message_groupid}
          newMessage={msgItem.newMessage}
          chatPerosnalInfo={fetchDateChanged(messageList, msgItem.isPushedRecord,
            msgItem.datecreated, msgItem.firstname)}
        />
      ))}
    </>
  )
);

ChatContentSection.propTypes = {
  fetchDateChanged: PropTypes.func,
  messageList: PropTypes.instanceOf(Array),
  userInfo: PropTypes.instanceOf(Object),
};

ChatContentSection.defaultProps = {
  fetchDateChanged: noop,
  messageList: [],
  userInfo: {},
};

export default ChatContentSection;
