import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import MessageWrapper from './MessageWrapper';
import { splitString } from '../../../../../utils';

const MessageWrapperContainer = ({
  chatInfo,
}) => {
  const matchParams = useParams();

  const findOurReply = (messages) => {
    if (messages) {
      if (messages[0] && messages[0].rtype && messages[0].rtype === 'H') {
        const msgSplitData = messages[0].message.split(' ');
        if (msgSplitData[0] === '##From:##' && msgSplitData[2] === '<support@crushftp.com>') {
          return true;
        } return false;
      } return false;
    } return false;
  };

  const replyToInfo = (messagesInfo) => {
    let emailInfo = {};
    const messages = messagesInfo && messagesInfo.filter((item) => item.rtype === 'H');
    if (messages) {
      messages.map((msgInfo) => {
        const msgSplitData = msgInfo.message.split(' ');
        if (msgSplitData[0] === '##From:##') {
          emailInfo = {
            ...emailInfo,
            From: splitString(msgInfo.message, '##From:## '),
          };
        } else if (msgSplitData[0] === '##To:##') {
          emailInfo = {
            ...emailInfo,
            To: splitString(msgInfo.message, '##To:## '),
          };
        } else if (msgSplitData[0] === '##Cc:##') {
          emailInfo = {
            ...emailInfo,
            CC: splitString(msgInfo.message, '##Cc:## '),
          };
        } else if (msgSplitData[0] === '##CC:##') {
          emailInfo = {
            ...emailInfo,
            CC: splitString(msgInfo.message, '##CC:## '),
          };
        }
        return emailInfo;
      });
    }
    return emailInfo;
  };

  return (
    <MessageWrapper
      replyToInfo={replyToInfo}
      findOurReply={findOurReply}
      chatInfo={chatInfo[matchParams.conversationid]}
      key="message-wrapper-container"
    />
  );
};

MessageWrapperContainer.propTypes = {
  chatInfo: PropTypes.instanceOf(Object),
};

MessageWrapperContainer.defaultProps = {
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
});

export default connect(
  mapStateToProps,
)(MessageWrapperContainer);
