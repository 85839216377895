import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import { MdClear } from 'react-icons/md';
import { noop } from '../../../utils';

import 'react-responsive-modal/styles.css';
import './custom_dialog.scss';

const CustomDialog = ({
  classNames,
  children,
  closeIcon,
  isOpen,
  toggle,
  title,
}) => (
  <Modal
    open={isOpen}
    onClose={toggle}
    center
    showCloseIcon={closeIcon}
    closeIcon={<MdClear className="card_close" />}
    classNames={{
      modal: `dialog_section ${classNames}`,
    }}
  >
    <span className="card_title">
      {title}
    </span>
    {children}
  </Modal>
);

CustomDialog.propTypes = {
  title: PropTypes.string,
  classNames: PropTypes.string,
  isOpen: PropTypes.bool,
  closeIcon: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

CustomDialog.defaultProps = {
  title: '',
  classNames: '',
  isOpen: false,
  closeIcon: true,
  toggle: noop,
  children: <></>,
};

export default CustomDialog;
