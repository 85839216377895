import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaSearch, FaLongArrowAltDown } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import SearchMoveContainer from '../../SearchMove/SearchMoveContainer';
import { noop } from '../../../../../utils';

const Header = ({
  setTicketSearchValue,
  fetchHighLightMsg,
  setSearchInputRef,
  scrollToBottom,
  setSearchValue,
  groupChatInfo,
  handleKeyUp,
  handleSearch,
  removeNewMsg,
  searchValue,
  setIsSearch,
  handleClose,
  viewStatus,
  isSearch,
  newMsg,
}) => (
  <div className="group_chat_header">
    {isSearch ? (
      <>
        <input
          type="text"
          className="group_chat_search_input"
          ref={setSearchInputRef}
          value={searchValue}
          onChange={handleSearch}
          onKeyUp={handleKeyUp}
        />
        <FaTimes
          className="header_icon"
          onClick={() => {
            setIsSearch(false);
            setSearchValue('');
            setTicketSearchValue(groupChatInfo.groupChatId, '');
          }}
        />
      </>
    ) : (
      <>
        <div className="right_section">
          <span className="ticket_id">Internal Chat</span>
          <span className="ticket_viewer">{viewStatus}</span>
        </div>
        <FaSearch className="header_icon" onClick={() => setIsSearch(true)} />
        <FaTimes className="header_icon" onClick={handleClose} />
      </>
    )}
    {newMsg ? (
      <div id="groupchat_newmsg" className="group_chat_newmsg">
        <FaLongArrowAltDown onClick={scrollToBottom} className="down_svg" />
        <span>{newMsg} New Message</span>
        <MdClose className="close_svg" onClick={removeNewMsg} />
      </div>
    ) : ''}
    {searchValue && <SearchMoveContainer eleId="group-chat-content" count={fetchHighLightMsg()} />}
  </div>
);

Header.propTypes = {
  viewStatus: PropTypes.string,
  searchValue: PropTypes.string,
  newMsg: PropTypes.number,
  isSearch: PropTypes.bool,
  handleClose: PropTypes.func,
  setIsSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  removeNewMsg: PropTypes.func,
  scrollToBottom: PropTypes.func,
  setSearchValue: PropTypes.func,
  handleKeyUp: PropTypes.func,
  setTicketSearchValue: PropTypes.func,
  fetchHighLightMsg: PropTypes.func,
  groupChatInfo: PropTypes.instanceOf(Object),
  setSearchInputRef: PropTypes.instanceOf(Object),
};

Header.defaultProps = {
  newMsg: undefined,
  viewStatus: '',
  searchValue: '',
  isSearch: false,
  handleClose: noop,
  handleSearch: noop,
  removeNewMsg: noop,
  setIsSearch: noop,
  scrollToBottom: noop,
  setSearchValue: noop,
  handleKeyUp: noop,
  setTicketSearchValue: noop,
  fetchHighLightMsg: noop,
  groupChatInfo: {},
  setSearchInputRef: {},
};

export default Header;
