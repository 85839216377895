import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Resizable } from 're-resizable';

import { SimpleInput, RenderButton } from '../../../../../shared/components/FormControl';
import PostponeTime from '../../../../../shared/data/PostponeTime.json';
import { noop } from '../../../../../utils';

const NoteEditor = ({
  setSelectedTransfer,
  onChangePostpone,
  selectedTransfer,
  setPostponeDate,
  setStatusTicket,
  footerActios,
  setInputText,
  postponeDate,
  statusTicket,
  deviceType,
  setNoteRef,
  userList,
}) => (
  <>
    <div className="note_header_info">
      {footerActios.submitType === 'transfer' && (
        <div className="transfer_selection">
          <span>Assign to:</span>
          <Select
            options={userList}
            value={selectedTransfer}
            className="user_list"
            onChange={setSelectedTransfer}
            maxMenuHeight={200}
          />
        </div>
      )}
      {footerActios.submitType === 'postpone' && (
        <div className="postpone_note_section">
          <div className="datepicker_section">
            <span>Postpone until:</span>
            <DatePicker
              selected={postponeDate}
              minDate={new Date()}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              onChange={(e) => setPostponeDate(e)}
              className="postpone_datepicker"
              timeCaption="Time"
              dateFormat="MM/dd/yyyy h:mm aa"
              popperPlacement="top-start"
            />
          </div>
          <div className="pospone_btn_list">
            {PostponeTime.map((btnItem) => (
              <RenderButton
                key={btnItem.title}
                className="quick_postpone_btn"
                onClick={() => onChangePostpone(btnItem.title)}
              >
                {btnItem.title}
              </RenderButton>
            ))}
          </div>
        </div>
      )}
      {footerActios.submitType !== 'reopen' && (
        <div className="reopen_radio_items">
          <strong>Mark as: &nbsp;</strong>
          <label className="radio_item">
            <input
              type="radio"
              id="open"
              name="status"
              value="C"
              onChange={() => setStatusTicket('C')}
              checked={statusTicket === 'C'}
            />
            <span>Open</span>
          </label>
          <label className="radio_item">
            <input
              type="radio"
              id="answer"
              name="status"
              value="A"
              onChange={() => setStatusTicket('A')}
              checked={statusTicket === 'A'}
            />
            <span>Answered</span>
          </label>
        </div>
      )}
    </div>
    <Resizable
      minWidth="calc(100% - 3px)"
      maxHeight={deviceType === 'desktop' ? '300' : '150'}
      minHeight={deviceType === 'desktop' ? '140' : '100'}
      defaultSize={{
        width: '99.5%',
        height: deviceType === 'desktop' ? '190' : '100',
      }}
    >
      <SimpleInput
        type="textarea"
        value={footerActios.noteMessage}
        onChange={setInputText}
        className="note_editor"
        innerRef={setNoteRef}
        id="note-editor"
      />
    </Resizable>
  </>
);

NoteEditor.propTypes = {
  deviceType: PropTypes.string,
  postponeDate: PropTypes.string,
  statusTicket: PropTypes.string,
  setInputText: PropTypes.func,
  onChangePostpone: PropTypes.func,
  setSelectedTransfer: PropTypes.func,
  setPostponeDate: PropTypes.func,
  setNoteRef: PropTypes.func,
  setStatusTicket: PropTypes.func,
  userList: PropTypes.instanceOf(Array),
  selectedTransfer: PropTypes.instanceOf(Array),
  footerActios: PropTypes.instanceOf(Object),
};

NoteEditor.defaultProps = {
  deviceType: '',
  postponeDate: '',
  statusTicket: 'opened',
  setInputText: noop,
  onChangePostpone: noop,
  setSelectedTransfer: noop,
  setPostponeDate: noop,
  setNoteRef: noop,
  setStatusTicket: noop,
  userList: [],
  selectedTransfer: [],
  footerActios: {},
};

export default NoteEditor;
