import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Rstatus from '../../../../../../shared/data/Rstatus.json';
import HiddenFeature from '../../../../../../shared/components/HiddenFeature';
import FlagStar from '../../../../../../shared/components/FlagStar/FlagStar';
import { splitString, noop, getTimeValue } from '../../../../../../utils';

const TicketTableMobile = ({
  gridData,
  agentName,
  offsetTime,
  isFlag,
}) => (
  <div className="ticket_table_mobile_section">
    {gridData.map((gridItem) => (
      <div className="ticket_item" key={gridItem.code}>
        <Link to={`/ticket/${gridItem.code}/${gridItem.conversationid}`}>
          <div className="ticket_profile_info">
            <div
              className="status_color_line"
              style={{
                backgroundColor: `${Rstatus[gridItem.rstatus].color
                || '#ffffff'}`,
              }}
            />
            <div className="ticket_header">
              <HiddenFeature hiddenChildValue={isFlag}>
                <FlagStar conversationid={gridItem.conversationid} />
              </HiddenFeature>
              {gridItem.emails && <p className="ticket_title">{splitString(gridItem.emails, '@')}</p>}
              <div className="ticket_date">
                {getTimeValue(gridItem.datechanged, 'both', offsetTime)}
                {gridItem.agentid && <div className="agent_badge">{agentName(gridItem.agentid)}</div>}
              </div>
            </div>
            {gridItem.subject && (
              <p className="text_ellipsis">{gridItem.subject}</p>
            )}
            {gridItem.preview && (
              <p className="text_ellipsis ticket_preview">{gridItem.preview}</p>
            )}
          </div>
        </Link>
      </div>
    ))}
  </div>
);

TicketTableMobile.propTypes = {
  offsetTime: PropTypes.string,
  isFlag: PropTypes.bool,
  agentName: PropTypes.func,
  gridData: PropTypes.instanceOf(Array),
};

TicketTableMobile.defaultProps = {
  offsetTime: '',
  isFlag: false,
  agentName: noop,
  gridData: [],
};

export default TicketTableMobile;
