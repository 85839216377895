import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import TabTitleInput from './TabTitleInput';
import { actions } from '../../../../modules/tickets';
import { noop, deepCopy } from '../../../../utils';

import './tab_title_input.scss';

const TabTitleInputContainer = ({
  inputModal,
  toggleModal,
  activeTabsInfo,
  setActiveTabs,
  setTabTitle,
  keyText,
  title,
}) => {
  const location = useLocation();
  const history = useHistory();

  const [titleInputRef, setTitleInputRef] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const { search } = location;
  const params = new URLSearchParams(search);
  const q = params.get('q');
  const status = params.get('status');
  const agentid = params.get('agentid');
  const unassigned = params.get('unassigned');
  const invert = params.get('invert');
  const dateStart = params.get('date_start');
  const dateEnd = params.get('date_end');
  const meta = params.get('meta');

  const setActiveBadge = (uniqueKey, newKey) => {
    const copyOfTabsInfo = deepCopy(activeTabsInfo);
    const prevTabInfo = copyOfTabsInfo.map(({
      messageCount, isLoading, length, ...rest
    }) => ({ ...rest }));
    const tabs = prevTabInfo.map((ticketInfo) => (ticketInfo.key === uniqueKey
      ? ({ ...ticketInfo, tabName: newKey }) : ticketInfo));
    const metaBase64 = base64.encode(
      encodeURIComponent(JSON.stringify(tabs)),
    );
    if (metaBase64.length < 50000) {
      const encodeUrl = (metaBase64);
      setActiveTabs(encodeUrl).then((res) => {
        if (res.value.data.response_msg === 'SAVED') {
          setTabTitle(uniqueKey, newKey);
          toggleModal();
        }
      });
    }
  };

  const handleActiveTab = (tabTitle) => {
    if (meta === 'd') {
      history.push(`/ticket?q=${q}&status=${status || ''}&agentid=${agentid || ''}&unassigned=${unassigned}&invert=${invert}&date_start=${dateStart}&date_end=${dateEnd}&meta=d&tab_name=${tabTitle || ''}`);
    } else if (meta === 'nd') {
      history.push(`/ticket?q=${q}&status=${status || ''}&agentid=${agentid || ''}&unassigned=${unassigned}&invert=${invert}&meta=nd&tab_name=${tabTitle || ''}`);
    } else {
      history.push(`/ticket?q=${q}&tab_name=${tabTitle || ''}`);
    }
  };

  const handleSubmitTitle = (e) => {
    e.preventDefault();
    setActiveBadge(keyText, newTitle);
    const keygen = (q || '') + (status || '') + (agentid ? (agentid.replace(/,/g, '') || '') : '') + (unassigned || '') + (invert || '') + (dateStart || '') + (dateEnd || '');
    if (keygen === keyText) {
      handleActiveTab(newTitle);
    }
  };

  useEffect(() => {
    if (titleInputRef) {
      titleInputRef.focus();
    } else {
      setNewTitle(title);
    }
  });

  useEffect(() => (
    setNewTitle('')
  ), []);

  return (
    <TabTitleInput
      inputModal={inputModal}
      toggleModal={toggleModal}
      setTitleInputRef={setTitleInputRef}
      setNewTitle={setNewTitle}
      newTitle={newTitle}
      handleSubmitTitle={handleSubmitTitle}
    />
  );
};

TabTitleInputContainer.propTypes = {
  title: PropTypes.string,
  keyText: PropTypes.string,
  inputModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  setActiveTabs: PropTypes.func,
  setTabTitle: PropTypes.func,
  activeTabsInfo: PropTypes.instanceOf(Array),
};

TabTitleInputContainer.defaultProps = {
  title: '',
  keyText: '',
  inputModal: false,
  toggleModal: noop,
  setActiveTabs: noop,
  setTabTitle: noop,
  activeTabsInfo: [],
};

const mapStateToProps = (state) => ({
  activeTabsInfo: state.tickets.activeTabsInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTabs: (code) => dispatch(actions.setActiveTabs(code)),
  setTabTitle: (key, title) => dispatch(actions.setTabTitle(key, title)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(TabTitleInputContainer);
