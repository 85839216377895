import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
  deviceType: '',
  orientation: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_DEVICE_DETECTOR:
      return {
        ...state,
        deviceType: action.payload.deviceType,
        orientation: action.payload.orientation,
      };
    default:
      return state;
  }
};
