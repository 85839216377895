import React from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../../utils';

const Expander = ({
  children, expand, toggleExpand, bodyRef, handleTransitionEnd,
}) => (
  <div className="expander">
    <div
      className="expander_header"
      role="presentation"
      onClick={toggleExpand}
      onKeyDown={noop}
    >
      <div className="expander_header_content">
        <p className="expander_title">
          {expand ? 'Hide' : 'Show'}
          {' '}
          quoted text
        </p>
      </div>
    </div>
    <div
      className="expander_children"
      onTransitionEnd={handleTransitionEnd}
      style={{
        height: expand && bodyRef.current ? bodyRef.current.clientHeight : 0,
      }}
    >
      <div className="expander_body" ref={bodyRef}>
        {children}
      </div>
    </div>
  </div>
);

Expander.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  expand: PropTypes.bool,
  toggleExpand: PropTypes.func,
  bodyRef: PropTypes.instanceOf(Object),
  handleTransitionEnd: PropTypes.func,
};

Expander.defaultProps = {
  children: <></>,
  expand: false,
  toggleExpand: noop,
  bodyRef: {},
  handleTransitionEnd: noop,
};

export default Expander;
