import React from 'react';
import PropTypes from 'prop-types';
import { MdCancel, MdInsertDriveFile } from 'react-icons/md';

import CustomDropZone from '../../../../shared/components/CustomDropZone/CustomDropZoneContainer';
import { noop, isFileImage } from '../../../../utils';

const TicketDropZone = ({
  handleClosePreview,
  fileUploadInfo,
  onDropFiles,
  id,
}) => (
  <div className="ticket_dropzone_section">
    <CustomDropZone
      title="Click here or Drag and drop files"
      handleDropZone={onDropFiles}
      className="ticket_dropzone"
      id={id}
    />
    {fileUploadInfo && fileUploadInfo.length > 0 && (
      <div className="dropdown_preview_list">
        {fileUploadInfo && fileUploadInfo.map((fileItem) => (
          <div className="preview_item">
            <MdCancel className="preview_close_item" onClick={() => handleClosePreview(fileItem.preview)} />
            <div className="perview_image">
              {isFileImage(fileItem.name) ? (
                <img
                  src={fileItem.preview}
                  alt="img"
                />
              ) : (
                <MdInsertDriveFile className="full_height full_width" />
              )}
            </div>
            <p className="file_info">
              <span className="filename">{fileItem.name.split('.')[0]}</span>
              <span className="extension">{fileItem.name.split('.').pop()}</span>
            </p>
          </div>
        ))}
      </div>
    )}
  </div>
);

TicketDropZone.propTypes = {
  id: PropTypes.string,
  onDropFiles: PropTypes.func,
  handleClosePreview: PropTypes.func,
  fileUploadInfo: PropTypes.instanceOf(Array),
};

TicketDropZone.defaultProps = {
  id: 'chatsection-dropzone',
  onDropFiles: noop,
  handleClosePreview: noop,
  fileUploadInfo: [],
};

export default TicketDropZone;
