import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MdAssignment, MdDeleteForever } from 'react-icons/md';

import { RenderDropDown } from '../../../../FormControl';
import { noop } from '../../../../../../utils';

const TodoList = ({
  handleRemoveTodo,
  handlePushTodo,
  setTodoDialog,
  todoList,
}) => (
  <div className="todo_icon">
    <RenderDropDown
      icon={<MdAssignment />}
      id="todo list"
      alt="todo list"
      popoverClass="todo_list_popover"
    >
      <>
        {todoList.length > 0 && todoList.map((todo) => (
          <li className="todo_item" id={todo.id}>
            <div role="presentation" className="todo_info" onClick={() => handlePushTodo(todo.link)}>
              <p className="todo_title">{todo.subject}</p>
              {todo.subTitle && <p className="todo_subtitle">{todo.subTitle}</p>}
              <p className="todo_date">
                <span>{moment(todo.date).format('DD-MM-YYYY HH:mm')}</span>
                <span>({moment(todo.date).fromNow()})</span>
              </p>
            </div>
            <MdDeleteForever className="remove_todo" onClick={() => handleRemoveTodo(todo.id)} />
          </li>
        ))}
        <li className="add_new_todo" onClick={() => setTodoDialog(true)} role="presentation">
          Add Todo
        </li>
      </>
    </RenderDropDown>
  </div>
);

TodoList.propTypes = {
  handleRemoveTodo: PropTypes.func,
  handlePushTodo: PropTypes.func,
  setTodoDialog: PropTypes.func,
  todoList: PropTypes.instanceOf(Array),
};

TodoList.defaultProps = {
  handlePushTodo: noop,
  handleRemoveTodo: noop,
  setTodoDialog: noop,
  todoList: [],
};

export default TodoList;
