import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import ExtendedBrowserRouter from './shared/ExtendedBrowserRouter';
import WebNotification from './shared/components/WebNotification/WebNotificationContainer';
import Routes from './Routes';
import { SpinnerLoader } from './shared/components/Loader';
import { DeviceDetector } from './shared/components/DeviceDetector';
import IdleTimerSection from './shared/components/IdleTimerSection/IdleTimerSectionContainer';

import '@material/mwc-linear-progress';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-block-ui/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/styles/app.scss';

const App = ({ deviceType, orientation, isRouteLoading }) => (
  <ExtendedBrowserRouter>
    <div className={classnames('App', deviceType, orientation)}>
      <ToastContainer />
      <DeviceDetector />
      <WebNotification />
      <IdleTimerSection />
      <BlockUi
        tag="div"
        blocking={isRouteLoading}
        className="full_height full_width block-ui-background"
        renderChildren={false}
        loader={<SpinnerLoader />}
      >
        <Routes />
      </BlockUi>
    </div>
  </ExtendedBrowserRouter>
);

App.propTypes = {
  deviceType: PropTypes.string,
  orientation: PropTypes.string,
  isRouteLoading: PropTypes.bool,
};

App.defaultProps = {
  deviceType: '',
  orientation: '',
  isRouteLoading: false,
};

const mapStateToProps = (state) => ({
  deviceType: state.deviceDetector.deviceType,
  orientation: state.deviceDetector.orientation,
  isRouteLoading: !state.deviceDetector.deviceType,
});

export default connect(mapStateToProps, null)(App);
