import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DeviceDetector from './DeviceDetector';
import * as actions from '../redux/actions';
import { getDeviceDetector, noop } from '../../../../utils';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const DeviceDetectorContainer = React.memo(({ setDeviceDetector, deviceType }) => {
  const _setDeviceDetector = () => debounce(() => setDeviceDetector(getDeviceDetector()), 200);

  useEffect(() => {
    _setDeviceDetector();
    window.addEventListener('resize', _setDeviceDetector);
    return () => {
      window.removeEventListener('resize', _setDeviceDetector);
    };
  }, [deviceType]);

  return (
    <DeviceDetector />
  );
});

DeviceDetectorContainer.propTypes = {
  setDeviceDetector: PropTypes.func,
  deviceType: PropTypes.string,
};

DeviceDetectorContainer.defaultProps = {
  setDeviceDetector: noop,
  deviceType: '',
};

const mapStateToProps = (state) => ({
  deviceType: state.deviceDetector.deviceType,
});

const mapDispatchToProps = (dispatch) => ({
  setDeviceDetector: (type) => dispatch(actions.setDeviceDetector(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetectorContainer);
