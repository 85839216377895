import React from 'react';
import PropTypes from 'prop-types';

import SantizingHtml from '../../../../../shared/components/SanitizingHtml/SanitizingHtml';
import { noop, getTimeValue } from '../../../../../utils';

const TypingInfo = ({
  offsetTime,
  typingInfo,
  fetchUser,
  userInfo,
}) => (
  <>
    {typingInfo
      && typingInfo.map(
        (msgInfo) => fetchUser(msgInfo.userid) !== userInfo.firstname && (
        <div className="realtime_visible_typing" key={msgInfo.userid}>
          <div className="typing_message_box">
            <div className="typing_message_box_header">
              {msgInfo.type === 'M' && (
                <span className="user_typing_info">
                  {fetchUser(msgInfo.userid)} is typing a message
                </span>
              )}
              {msgInfo.type === 'N' && (
                <span className="user_typing_info">
                  {fetchUser(msgInfo.userid)} is typing a note
                </span>
              )}
              <span className="timestamp">
                {getTimeValue(msgInfo.datechanged, 'both', offsetTime)}
              </span>
            </div>
            <SantizingHtml
              className="typing_content"
              htmlToRender={msgInfo.text.replace(/\n/g, '<br />')}
            />
          </div>
        </div>
        ),
      )}
  </>
);

TypingInfo.propTypes = {
  offsetTime: PropTypes.string,
  fetchUser: PropTypes.func,
  typingInfo: PropTypes.instanceOf(Array),
  userInfo: PropTypes.instanceOf(Object),
};

TypingInfo.defaultProps = {
  offsetTime: '',
  fetchUser: noop,
  typingInfo: [],
  userInfo: {},
};

export default TypingInfo;
