import React from 'react';
import PropTypes from 'prop-types';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { SimpleInput } from '../../FormControl';
import { noop } from '../../../../utils';

const TabTitleInput = ({
  inputModal,
  toggleModal,
  setTitleInputRef,
  newTitle,
  setNewTitle,
  handleSubmitTitle,
}) => (
  <CustomDialog
    isOpen={inputModal}
    toggle={toggleModal}
    title="Enter tab name:"
    classNames="tab_title_change_dialog"
  >
    <form
      onSubmit={(e) => handleSubmitTitle(e)}
      className="full_width"
    >
      <SimpleInput
        innerRef={setTitleInputRef}
        value={newTitle}
        onChange={(e) => setNewTitle(e.target.value)}
        maxlength={40}
      />
    </form>
  </CustomDialog>
);

TabTitleInput.propTypes = {
  newTitle: PropTypes.string,
  inputModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  setTitleInputRef: PropTypes.func,
  setNewTitle: PropTypes.func,
  handleSubmitTitle: PropTypes.func,
};

TabTitleInput.defaultProps = {
  newTitle: '',
  inputModal: false,
  toggleModal: noop,
  setTitleInputRef: noop,
  setNewTitle: noop,
  handleSubmitTitle: noop,
};

export default TabTitleInput;
