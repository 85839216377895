import React from 'react';
import PropTypes from 'prop-types';
import { FaRegFile } from 'react-icons/fa';

import './groupchat_attachment_info.scss';
import { isFileViewable } from '../../../utils';

const GroupChatAttachmentInfo = ({
  handleImageClick,
  handleViewClick,
  handleDownload,
  fileType,
  fileName,
  src,
}) => (
  <div className="gruoup_chat_attachment">
    {(fileType === 'image/png'
      || fileType === 'image/jpeg'
        || fileType === 'image/jpg'
        || fileType === 'image/gif') && (
        <div className="attachment_img">
          <img
            src={src}
            onClick={handleImageClick}
            alt="img"
            role="presentation"
          />
        </div>
    )}
    <div className="attachment_info">
      <div className="file_info">
        <FaRegFile />
        <p className="filename" onClick={handleDownload} role="presentation">
          {fileName}
        </p>
      </div>
      <div className="actions_info">
        <p onClick={handleDownload} role="presentation">
          Download
        </p>
        {(fileType === 'image/png'
          || fileType === 'image/jpeg'
            || fileType === 'image/jpg'
            || fileType === 'image/gif'
          || isFileViewable(fileName)) && (
          <p role="presentation" onClick={handleViewClick}>
            View
          </p>
        )}
      </div>
    </div>
  </div>
);

GroupChatAttachmentInfo.propTypes = {
  src: PropTypes.string,
  fileType: PropTypes.string,
  fileName: PropTypes.string,
  handleImageClick: PropTypes.func,
  handleDownload: PropTypes.func,
  handleViewClick: PropTypes.func,
};

GroupChatAttachmentInfo.defaultProps = {
  src: '',
  fileType: '',
  fileName: '',
  handleImageClick: () => {},
  handleDownload: () => {},
  handleViewClick: () => {},
};

export default GroupChatAttachmentInfo;
