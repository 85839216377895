import React from 'react';

import './playground.scss';

const Playground = () => (
  <div className="playground_section">
    helooooo
  </div>
);

export default Playground;
