import React from 'react';
import PropTypes from 'prop-types';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { RenderButton } from '../../FormControl';
import { noop } from '../../../../utils';

const IdleActivityDialog = ({
  handleReconnect,
  timeTrackingStopped,
}) => (
  <CustomDialog
    isOpen={timeTrackingStopped}
    toggle={noop}
    title="Due to inactivity your time tracking is stopped"
    classNames="idle_activity_dialog"
    closeIcon={false}
  >
    <RenderButton
      type="submit"
      onClick={handleReconnect}
    >
      Start Tracking
    </RenderButton>
  </CustomDialog>
);

IdleActivityDialog.propTypes = {
  timeTrackingStopped: PropTypes.bool,
  handleReconnect: PropTypes.func,
};

IdleActivityDialog.defaultProps = {
  timeTrackingStopped: false,
  handleReconnect: noop,
};

export default IdleActivityDialog;
