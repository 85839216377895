import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Expander from './Expander';

import './expander.scss';

const ExpanderContainer = ({
  title, children, showExpandButton,
}) => {
  const [expand, setExpand] = useState(false);
  const [, forceUpdate] = useState(false);
  const bodyRef = useRef(null);

  const toggleExpand = () => {
    if (showExpandButton) setExpand((prevState) => !prevState);
  };

  const handleTransitionEnd = () => {
    forceUpdate((prevState) => !prevState);
  };

  return (
    <Expander
      title={title}
      expand={expand}
      toggleExpand={toggleExpand}
      bodyRef={bodyRef}
      handleTransitionEnd={handleTransitionEnd}
      showExpandButton={showExpandButton}
    >
      {children}
    </Expander>
  );
};

ExpanderContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  showExpandButton: PropTypes.bool,
};

ExpanderContainer.defaultProps = {
  title: '',
  children: <></>,
  showExpandButton: true,
};

export default ExpanderContainer;
