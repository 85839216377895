import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchMove from './SearchMove';

import './search_move.scss';

const SearchMoveContainer = React.memo(({ count, eleId }) => {
  const [isCount, setIsCount] = useState(true);
  const [active, setActive] = useState(undefined);

  const fetchHighLightMsg = (id) => {
    const chat = document.getElementById(eleId);
    if (chat) {
      const hightLightedMsg = chat.querySelectorAll('.highlight_msg');
      hightLightedMsg[id].style.setProperty('background-color', '#ffdf00', 'important');
      if (hightLightedMsg[active - 1]) {
        hightLightedMsg[active - 1].style.setProperty('background-color', '#ffff00c4', 'important');
      }
      hightLightedMsg[id].scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    return null;
  };

  const handleMoveUpDown = (e, type) => {
    e.stopPropagation();
    let id;
    if (type === 'up') {
      id = parseInt(active, 10) - 1;
    } else if (type === 'down') {
      id = parseInt(active, 10) + 1;
    }
    if (id >= 1 && id <= parseInt(count, 10)) {
      fetchHighLightMsg(id - 1);
      setActive(id);
    }
  };

  useEffect(() => {
    if (isCount && count) {
      setActive(count);
      setIsCount(false);
      fetchHighLightMsg(count - 1);
    }
  });

  return (
    <SearchMove
      count={count}
      active={active}
      handleMoveUpDown={handleMoveUpDown}
    />
  );
});

SearchMoveContainer.propTypes = {
  eleId: PropTypes.string,
  count: PropTypes.number,
};

SearchMoveContainer.defaultProps = {
  eleId: '',
  count: undefined,
};

export default SearchMoveContainer;
