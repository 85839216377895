import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import RecipientInfo from './RecipientInfo';
import * as actions from '../../../../redux/actions';
import { noop } from '../../../../../../utils';

import './recipient_info.scss';

const RecipientInfoContainer = React.memo(({
  setParticipantsModal,
  chatInfo,
}) => {
  const matchParams = useParams();
  const [participants, setParticipants] = useState([]);

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (!chatInfo) return;
    const chatDetails = chatInfo[matchParams.conversationid];

    if (chatDetails && chatDetails.participants && chatDetails.participants.length > 0) {
      setParticipants(chatDetails.participants);
    }
  }, [chatInfo]);

  return (
    <>
      <RecipientInfo
        setParticipantsModal={setParticipantsModal}
        handleStopPropagation={handleStopPropagation}
        ToRecipient={participants.filter((recipient) => recipient.role.toLowerCase() === 'to')}
        CCRecipient={participants.filter((recipient) => recipient.role.toLowerCase() === 'cc')}
        BCCRecipient={participants.filter((recipient) => recipient.role.toLowerCase() === 'bcc')}
      />
    </>
  );
});

RecipientInfoContainer.propTypes = {
  setParticipantsModal: PropTypes.func,
  chatInfo: PropTypes.instanceOf(Object),
};

RecipientInfoContainer.defaultProps = {
  setParticipantsModal: noop,
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
});

const mapDispatchToProps = (dispatch) => ({
  setParticipantsModal:
    (isVisible) => dispatch(actions.setParticipantsModal(isVisible)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(RecipientInfoContainer);
