import React from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../../../utils';

import './render_button.scss';

const RenderButton = ({
  children, className, onClick, name, disabled,
}) => (
  <button
    type="submit"
    className={`primary_btn ripple ${className}`}
    onClick={onClick}
    name={name}
    disabled={disabled}
  >
    {children}
  </button>
);

RenderButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.element,
      PropTypes.instanceOf(Array), PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

RenderButton.defaultProps = {
  className: '',
  onClick: noop,
  name: 'button',
  disabled: false,
};

export default RenderButton;
