import React from 'react';
import PropTypes from 'prop-types';

import './user_profile.scss';

const UserProfile = ({
  imgsrc, title, subtitle, subtitleTwo, classname,
}) => (
  <div className={`profile_item ${classname}`}>
    <div className="profile_content">
      {imgsrc && (
      <div className="profile_pic roundedd">
        <img src={imgsrc} alt="img" />
      </div>
      )}
      <div className="profile_info">
        {title && <p className="profile_username">{title}</p>}
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {subtitleTwo && <p className="subtitle">{subtitleTwo}</p>}
      </div>
    </div>
  </div>
);

UserProfile.propTypes = {
  imgsrc: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleTwo: PropTypes.string,
  classname: PropTypes.string,
};

UserProfile.defaultProps = {
  imgsrc: '',
  title: '',
  subtitle: '',
  subtitleTwo: '',
  classname: '',
};

export default UserProfile;
