import React from 'react';
import PropTypes from 'prop-types';

import LightThemePoster from '../../../../assets/images/light-theme.png';
import DarkThemePoster from '../../../../assets/images/dark-theme.png';
import CustomDialog from '../../CustomDialog/CustomDialog';
import { noop } from '../../../../utils';

const MultiThemeDialog = ({
  setMultiThemeModal,
  handleAccentStyle,
  handleThemeStyle,
  multiThemeModal,
  accentStyle,
  themeStyle,
}) => (
  <CustomDialog
    isOpen={multiThemeModal}
    toggle={() => setMultiThemeModal(false)}
    title="Themes"
    classNames="multi_theme_dialog"
  >
    <div className="multi_theme_section">
      <div className="theme_item">
        <div className="theme_label">
          <input
            type="radio"
            id="light"
            name="light"
            checked={themeStyle === 'light'}
            onChange={() => handleThemeStyle('light')}
          />
          <label htmlFor="light">light</label>
        </div>
        <div className="theme_preview">
          <img src={LightThemePoster} alt="light" onClick={() => handleThemeStyle('light')} role="presentation" />
        </div>
      </div>
      <div className="theme_item">
        <div className="theme_label">
          <input
            type="radio"
            id="dark"
            name="dark"
            checked={themeStyle === 'dark'}
            onChange={() => handleThemeStyle('dark')}
          />
          <label htmlFor="dark">dark</label>
        </div>
        <div className="theme_preview">
          <img src={DarkThemePoster} alt="dark" onClick={() => handleThemeStyle('dark')} role="presentation" />
        </div>
      </div>
    </div>
    <div className="theme_accent">
      <div className="accent_label">
        <input
          type="radio"
          id="blue"
          name="blue"
          checked={accentStyle === 'blue'}
          onChange={() => handleAccentStyle('blue')}
        />
        <label htmlFor="blue">blue</label>
      </div>
      <div className="accent_label">
        <input
          type="radio"
          id="orange"
          name="orange"
          checked={accentStyle === 'orange'}
          onChange={() => handleAccentStyle('orange')}
        />
        <label htmlFor="orange">orange</label>
      </div>
      <div className="accent_label">
        <input
          type="radio"
          id="green"
          name="green"
          checked={accentStyle === 'green'}
          onChange={() => handleAccentStyle('green')}
        />
        <label htmlFor="green">green</label>
      </div>
    </div>
  </CustomDialog>
);

MultiThemeDialog.propTypes = {
  themeStyle: PropTypes.string,
  accentStyle: PropTypes.string,
  multiThemeModal: PropTypes.bool,
  setMultiThemeModal: PropTypes.func,
  handleThemeStyle: PropTypes.func,
  handleAccentStyle: PropTypes.func,
};

MultiThemeDialog.defaultProps = {
  themeStyle: 'light',
  accentStyle: 'orange',
  multiThemeModal: PropTypes.bool,
  setMultiThemeModal: noop,
  handleThemeStyle: noop,
  handleAccentStyle: noop,
};

export default MultiThemeDialog;
