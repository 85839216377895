import React from 'react';
import PropTypes from 'prop-types';
import { FaRegFile } from 'react-icons/fa';

import { noop, isFileViewable, formatBytes } from '../../../utils';

import './attachment_info.scss';

const AttachmentInfo = ({
  handleImageClick,
  handleViewClick,
  className,
  btntitle,
  fileName,
  fileType,
  fileSize,
  onClick,
  src,
}) => (
  <>
    <div className={`attachment_section ${className}`}>
      {(fileType === 'image/png'
        || fileType === 'image/jpeg'
          || fileType === 'image/jpg'
          || fileType === 'image/gif') && (
          <div className="attachment_img">
            <img
              src={src}
              alt="img"
              onClick={handleImageClick}
              role="presentation"
            />
          </div>
      )}
      <div className="attachment_content">
        <div className="attachment_file_info">
          <FaRegFile />
          <p role="presentation" onClick={onClick}>
            {fileName}
          </p>
          {fileSize ? <span className="file_size">{`${formatBytes(fileSize)}`}</span> : ''}
        </div>
        <div className="attachment_btn">
          <p role="presentation" onClick={onClick}>
            {btntitle}
          </p>
          {(fileType === 'image/png'
            || fileType === 'image/jpeg'
              || fileType === 'image/jpg'
              || fileType === 'image/gif'
            || isFileViewable(fileName)) && (
            <p role="presentation" onClick={handleViewClick}>
              View
            </p>
          )}
        </div>
      </div>
    </div>
  </>
);

AttachmentInfo.propTypes = {
  src: PropTypes.string,
  fileName: PropTypes.string,
  btntitle: PropTypes.string,
  fileType: PropTypes.string,
  fileSize: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  handleImageClick: PropTypes.func,
  handleViewClick: PropTypes.func,
};

AttachmentInfo.defaultProps = {
  src: '',
  fileName: '',
  btntitle: '',
  fileType: '',
  className: '',
  fileSize: undefined,
  onClick: noop,
  handleImageClick: noop,
  handleViewClick: noop,
};

export default AttachmentInfo;
