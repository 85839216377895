import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaRegEnvelopeOpen } from 'react-icons/fa';

import './envelope_open.scss';

const EnvelopeOpen = React.memo(({
  conversationid, code, activeTabs,
}) => {
  const findActiveTabIndex = activeTabs.findIndex(
    (tab) => tab.code
      && tab.conversationid
      && tab.conversationid === conversationid
      && tab.code === code,
  );
  if (findActiveTabIndex === -1) {
    return <></>;
  }
  return <FaRegEnvelopeOpen className="fa-envelope-svg" />;
});

EnvelopeOpen.propTypes = {
  conversationid: PropTypes.string,
  code: PropTypes.string,
  activeTabs: PropTypes.instanceOf(Array),
};

EnvelopeOpen.defaultProps = {
  conversationid: '',
  code: '',
  activeTabs: [],
};

const mapStateToProps = (state) => ({
  activeTabs: state.tickets.activeTabsInfo,
});

export default connect(mapStateToProps)(EnvelopeOpen);
