import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash.difference';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import LiveStatusInfo from './LiveStatusInfo';
import { usePrevious } from '../../../../utils/hooks';

import './live_status_info.scss';

let previousStatus;

const LiveStatusInfoContainer = React.memo(({
  viewStatus,
  userInfo,
  users,
}) => {
  const matchParams = useParams();
  const [newJoining, setNewJoining] = useState(undefined);
  const [newLeaves, setNewLeaves] = useState(undefined);
  const [expand, setExpand] = useState(false);
  previousStatus = usePrevious(viewStatus[matchParams.code]);

  const toggleExpand = () => {
    setExpand((prevState) => !prevState);
  };

  const getUserName = (name) => {
    const userItems = users.find(
      (userItem) => userItem.username.toLowerCase() === name.toLowerCase(),
    );
    return userItems ? userItems.firstname : '';
  };

  const viewStatusWithoutMe = () => {
    if (viewStatus && viewStatus[matchParams.code]) {
      return viewStatus[matchParams.code].filter((statusItem) => statusItem !== userInfo.email);
    }
    return [];
  };

  useEffect(() => {
    if (viewStatus && viewStatus[matchParams.code]
      && previousStatus && previousStatus.length > 0) {
      if (previousStatus.length < viewStatus[matchParams.code].length) {
        setNewJoining(difference(viewStatusWithoutMe(), previousStatus));
        setNewLeaves(undefined);
        setTimeout(() => {
          setNewJoining(undefined);
        }, 5000);
      } else if (previousStatus.length > viewStatus[matchParams.code].length) {
        setNewLeaves(difference(previousStatus, viewStatusWithoutMe()));
        setNewJoining(undefined);
        setTimeout(() => {
          setNewLeaves(undefined);
        }, 5000);
      }
    }
  }, [viewStatus[matchParams.code]]);

  return (
    <LiveStatusInfo
      expand={expand}
      getUserName={getUserName}
      toggleExpand={toggleExpand}
      viewStatus={viewStatusWithoutMe()}
      newJoining={newJoining && newJoining.filter((statusItem) => statusItem !== userInfo.email)}
      newLeaves={newLeaves && newLeaves.filter((statusItem) => statusItem !== userInfo.email)}
    />
  );
});

LiveStatusInfoContainer.propTypes = {
  users: PropTypes.instanceOf(Array),
  viewStatus: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
};

LiveStatusInfoContainer.defaultProps = {
  users: [],
  viewStatus: {},
  userInfo: {},
};

const mapStateToProps = (state) => ({
  viewStatus: state.tickets.viewStatus,
  users: state.auth.users,
  userInfo: state.auth.userInfo,
});

export default connect(
  mapStateToProps,
)(LiveStatusInfoContainer);
