import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Auth from '../../hoc/auth';
import MainTemplate from '../../shared/template/MainTemplate/MainTemplateContainer';
import { actions } from '../../shared/components/AppNavigation';
import { MessageListContainer } from '../../modules/tickets';
import { noop } from '../../utils';

const TicketDetails = ({ setSideNavbarItem }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setSideNavbarItem('/ticket');
    return () => setSideNavbarItem('');
  }, []);

  return (
    <MainTemplate>
      <MessageListContainer />
    </MainTemplate>
  );
};

TicketDetails.propTypes = {
  setSideNavbarItem: PropTypes.func,
};

TicketDetails.defaultProps = {
  setSideNavbarItem: noop,
};

const mapDispatchToProps = (dispatch) => ({
  setSideNavbarExpand:
    (isExpanded) => dispatch(actions.setSideNavbarExpand(isExpanded)),
  setSideNavbarItem:
    (selectedSideNavbarItem) => dispatch(actions.setSideNavbarItem(selectedSideNavbarItem)),
});

export default connect(null, mapDispatchToProps)(Auth(TicketDetails));
