import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ExternalTicketForm from './ExternalTicketForm';
import { actions } from '../../../../../modules/tickets';
import { required, matchRegEx, email as emailRegEx } from '../../../../../utils/validators';
import { noop } from '../../../../../utils';

import './external_ticket_form.scss';

const ExternalTicketFormContainer = ({
  createNewTicketLoading,
  setNewExternalTicket,
  setButtonInputClick,
  setNewTicketModal,
  setInputType,
  resetForm,
  userInfo,
}) => {
  const history = useHistory();
  const [ccemail, setccemail] = useState(false);
  const [bccemail, setBccemail] = useState(false);
  const [toEmailRef, setToEmailRef] = useState(null);
  const [focus, setFocus] = useState(true);

  const handleEmail = (etype) => {
    if (etype === 'CC') {
      setccemail(!ccemail);
    } else if (etype === 'BCC') {
      setBccemail(!bccemail);
    }
  };

  const onSubmit = (values) => {
    createNewTicketLoading(true);
    setNewExternalTicket(userInfo.firstname, values.To, values.Subject, values.BCC, values.CC)
      .then((newTicketRes) => {
        const res = newTicketRes.value.data[0];
        history.push(`/ticket/${res.code}/${res.conversationid}`);
        resetForm('newExternalTicketForm');
        createNewTicketLoading(false);
        setInputType('M');
        setButtonInputClick('OK');
        setNewTicketModal(false, undefined);
      });
  };

  const validateForm = (values) => {
    const errors = {};
    errors.To = required(values.To) && 'Email is required';
    errors.Subject = required(values.Subject) && 'Subject is required';
    if (!errors.To) {
      errors.To = !matchRegEx(values.To, emailRegEx) && 'Invalid Email';
    }
    if (values.CC) {
      errors.CC = !matchRegEx(values.CC, emailRegEx) && 'Invalid Email';
    }
    if (values.BCC) {
      errors.BCC = !matchRegEx(values.BCC, emailRegEx) && 'Invalid Email';
    }
    return errors;
  };

  useEffect(() => {
    if (toEmailRef && focus) {
      toEmailRef.focus();
      setFocus(false);
    }
  });

  return (
    <ExternalTicketForm
      ccemail={ccemail}
      onSubmit={onSubmit}
      bccemail={bccemail}
      validate={validateForm}
      handleEmail={handleEmail}
      setToEmailRef={setToEmailRef}
    />
  );
};

ExternalTicketFormContainer.propTypes = {
  setNewTicketModal: PropTypes.func,
  setNewExternalTicket: PropTypes.func,
  resetForm: PropTypes.func,
  createNewTicketLoading: PropTypes.func,
  setButtonInputClick: PropTypes.func,
  setInputType: PropTypes.func,
  userInfo: PropTypes.instanceOf(Object),
};

ExternalTicketFormContainer.defaultProps = {
  setNewTicketModal: noop,
  setNewExternalTicket: noop,
  resetForm: noop,
  createNewTicketLoading: noop,
  setButtonInputClick: noop,
  setInputType: noop,
  userInfo: {},
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  resetForm: (formName) => dispatch(reset(formName)),
  setInputType: (type) => dispatch(actions.setInputType(type)),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
  createNewTicketLoading: (isLoading) => dispatch(actions.createNewTicketLoading(isLoading)),
  setNewTicketModal: (isOpen, type) => dispatch(actions.setNewTicketModal(isOpen, type)),
  setNewExternalTicket: (name, to, subject, bcc, cc) => dispatch(
    actions.setNewExternalTicket(name, to, subject, bcc, cc),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ExternalTicketFormContainer);
