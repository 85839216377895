import React from 'react';
import PropTypes from 'prop-types';

import ActivityTimer from '../ActivityTimer/ActivityTimerContainer';
import { IdleActivityDialog } from '../Dialogs';
import { noop } from '../../../utils';

const ActivityTracker = ({
  timeTrackingStopped,
  handleTimeTracking,
}) => (
  <>
    <ActivityTimer
      handleTimeTracking={handleTimeTracking}
    />
    {timeTrackingStopped && <IdleActivityDialog />}
  </>
);

ActivityTracker.propTypes = {
  timeTrackingStopped: PropTypes.bool,
  handleTimeTracking: PropTypes.func,
};

ActivityTracker.defaultProps = {
  timeTrackingStopped: false,
  handleTimeTracking: noop,
};

export default ActivityTracker;
