import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';

import ActionFooter from '../ActionFooter/ActionFooterContainer';
import MessageHeader from './MessageHeader/MessageHeaderContainer';
import TypingInfo from './TypingInfo/TypingInfoContainer';
import MessageWrapper from './MessageWrapper/MessageWrapperContainer';
import { SpinnerLoader } from '../../../../shared/components/Loader';
import { noop } from '../../../../utils';

const MessageList = ({
  onScrollMessageList,
  setMessageReadable,
  scrollToBottom,
  typingLoading,
  chatLoading,
  msgListRef,
  match,
}) => (
  <div className="ticket_message_section">
    <div className="ticket_messages_container" id="ticket-messages-container">
      {!chatLoading && <MessageHeader />}
      <BlockUi
        tag="div"
        blocking={chatLoading}
        className="chat_content_wrapper block-ui-background"
        renderChildren={false}
        loader={<SpinnerLoader />}
      >
        <div
          className="ticket_list"
          id="ticket-messages"
          onClick={() => setMessageReadable()}
          role="presentation"
          ref={msgListRef}
          onScroll={onScrollMessageList}
        >
          <MessageWrapper key="message-wrapper-list" />
        </div>
        {!chatLoading && !typingLoading && (
          <TypingInfo conversationid={match.params.conversationid} />
        )}
        <div className="text_editor" id="text_editor_container">
          <BlockUi
            tag="div"
            blocking={typingLoading}
            className="full_height full_width block-ui-background"
            renderChildren={false}
            loader={<SpinnerLoader width={60} height={60} />}
          >
            <ActionFooter scrollToBottom={scrollToBottom} />
          </BlockUi>
        </div>
      </BlockUi>
    </div>
  </div>
);

MessageList.propTypes = {
  chatLoading: PropTypes.bool,
  typingLoading: PropTypes.bool,
  scrollToBottom: PropTypes.func,
  setMessageReadable: PropTypes.func,
  onScrollMessageList: PropTypes.func,
  msgListRef: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object).isRequired,
};

MessageList.defaultProps = {
  chatLoading: true,
  typingLoading: true,
  scrollToBottom: noop,
  setMessageReadable: noop,
  onScrollMessageList: noop,
  msgListRef: {},
};

export default MessageList;
