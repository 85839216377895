import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TypingInfo from './TypingInfo';

import './typing_info.scss';

const preventRendering = (prevProps, nextProps) => {
  const conversationid = prevProps.conversationid;
  if (JSON.stringify(prevProps.typingInfo[conversationid])
  !== JSON.stringify(nextProps.typingInfo[conversationid])) {
    return false;
  }
  return true;
};

const TypingInfoContainer = React.memo(({
  conversationid,
  offsetTime,
  typingInfo,
  userInfo,
  users,
}) => {
  const fetchUser = (id) => {
    if (id) {
      const name = users
        .find((agent) => agent.accountuserid === id);
      return name && name.firstname;
    } return null;
  };

  return (
    <TypingInfo
      userInfo={userInfo}
      fetchUser={fetchUser}
      offsetTime={offsetTime}
      typingInfo={typingInfo[conversationid]}
    />
  );
},
(prevProps, nextProps) => preventRendering(prevProps, nextProps));

TypingInfoContainer.propTypes = {
  offsetTime: PropTypes.string,
  conversationid: PropTypes.string,
  users: PropTypes.instanceOf(Array),
  typingInfo: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
};

TypingInfoContainer.defaultProps = {
  offsetTime: '',
  conversationid: '',
  users: [],
  typingInfo: {},
  userInfo: {},
};

const mapStateToProps = (state) => ({
  typingInfo: state.tickets.typingInfo,
  users: state.auth.users,
  offsetTime: state.auth.offsetTime,
  userInfo: state.auth.userInfo,
});

export default connect(
  mapStateToProps,
)(TypingInfoContainer);
