/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  FiMenu, FiSearch, FiX, FiLogOut, FiUser, FiKey,
} from 'react-icons/fi';
import { FaRegObjectGroup } from 'react-icons/fa';
import { IoIosChatboxes } from 'react-icons/io';

import ActivityTracker from '../../../ActivityTracker/ActivityTrackerContainer';
import WorldClock from '../../../WorldClock/WorldClock';
import CrushftpLogo from '../../../../../assets/images/crush_icon.png';
import ZoomImage from '../../../../../assets/images/zoom.png';
import HiddenFeature from '../../../HiddenFeature';
import TodoList from './TodoList/TodoListContainer';
import AgentListContainer from './AgentList/AgentListContainer';
import { SearchInput, RenderDropDown } from '../../../FormControl';
import { noop } from '../../../../../utils';

const TopNavigation = ({
  setChangePasswordDialog,
  handleHeaderExpander,
  setCustomSearchModal,
  handleGroupChatDialog,
  setMultiThemeModal,
  groupChatCount,
  profilInfoRef,
  isTodo,
  handleSearch,
  deviceType,
  setSearch,
  isSearch,
  userInfo,
  isTheme,
  fetchedState,
}) => {
  const history = useHistory();

  return (
    <>
      <div className="top_navigation_section">
        <div className="top_navigation_container">
          {!isSearch && (
          <div className="main_logo">
            {deviceType === 'mobile' && (
              <FiMenu onClick={handleHeaderExpander} className="menu_icon" />
            )}
            <div className="top_navigation_left">
              <Link to="/ticket">
                <img
                  alt="logo"
                  src={CrushftpLogo}
                  className="crushftp_logo"
                />
              </Link>
              <ActivityTracker />
              <HiddenFeature hiddenChildValue={isTodo}>
                <TodoList />
              </HiddenFeature>
              <AgentListContainer />
              {deviceType === 'desktop' && <WorldClock />}
            </div>
            {deviceType === 'mobile' && (
              <>
                <IoIosChatboxes className="group_chat_icon" onClick={() => history.push('/group-chat')} />
                <FiSearch className="menu_icon" onClick={handleSearch} />
              </>
            )}
            {deviceType === 'desktop' && (
            <div className="header_content">
              <div className="search_info_wrapper">
                <SearchInput
                  onCustomSearch={(e) => {
                    e.stopPropagation();
                    setCustomSearchModal();
                  }}
                />
              </div>
              {
                groupChatCount
                && groupChatCount !== '0'
                && <div className="glow-line"></div>
              }
              <div
                className={`group_chat_icon ${groupChatCount
                        && groupChatCount !== '0' && 'pulse'}`}
                onClick={handleGroupChatDialog}
                role="presentation"
              >
                <IoIosChatboxes />
                {groupChatCount
                  && groupChatCount!== '0' && <span className="count_badge">{groupChatCount}</span>}
              </div>
              <a
                href="zoommtg://"
                target="_blank"
                rel="noopener noreferrer"
                className="goto_meeting"
              >
                <img src={ZoomImage} alt="teams" className="teams_meeting_img" />
              </a>
              <RenderDropDown
                icon={<FiUser />}
                title={userInfo.firstname}
                id="user_profile_info"
                popoverClass="user_profile_info"
                innerRef={profilInfoRef}
              >
                <li
                  onClick={() => setChangePasswordDialog(true)}
                  role="presentation"
                >
                  <FiKey /> Change Password
                </li>
                <HiddenFeature hiddenChildValue={isTheme}>
                  <li
                    onClick={() => setMultiThemeModal(true)}
                    role="presentation"
                  >
                    <FaRegObjectGroup /> Theme
                  </li>
                </HiddenFeature>
                <li
                  onClick={() => history.push('/logout')}
                  role="presentation"
                >
                  <FiLogOut /> Logout
                </li>
              </RenderDropDown>
            </div>
            )}
          </div>
          )}
          {isSearch && (
          <div className="mobile_search_section">
            <SearchInput
              isfocus
            />
            <FiX onClick={() => setSearch(false)} />
          </div>
            )}
            {!fetchedState && (
              <div className="state-warning">Caution: State is not being saved, please reload to get back in sync.</div>
            )}
        </div>
      </div>
    </>
  );
};

TopNavigation.propTypes = {
  deviceType: PropTypes.string,
  groupChatCount: PropTypes.string,
  isSearch: PropTypes.bool,
  isTodo: PropTypes.bool,
  isTheme: PropTypes.bool,
  handleHeaderExpander: PropTypes.func,
  handleSearch: PropTypes.func,
  setSearch: PropTypes.func,
  setCustomSearchModal: PropTypes.func,
  setChangePasswordDialog: PropTypes.func,
  handleGroupChatDialog: PropTypes.func,
  setMultiThemeModal: PropTypes.func,
  userInfo: PropTypes.instanceOf(Object),
  profilInfoRef: PropTypes.instanceOf(Object),
  fetchedState: PropTypes.bool,
};

TopNavigation.defaultProps = {
  deviceType: '',
  groupChatCount: '',
  isSearch: false,
  isTodo: false,
  isTheme: false,
  handleHeaderExpander: noop,
  handleSearch: noop,
  setSearch: noop,
  setCustomSearchModal: noop,
  setChangePasswordDialog: noop,
  handleGroupChatDialog: noop,
  setMultiThemeModal: noop,
  setMultiThemeModal: noop,
  userInfo: {},
  profilInfoRef: {},
  fetchedState: false,
};

export default TopNavigation;
