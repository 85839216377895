import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RecipientsDialog from './RecipientsDialog';
import RequestStates from '../../../../utils/request-states';
import { actions as ticketsActions } from '../../../../modules/tickets';
import { noop } from '../../../../utils';

const ParticipantsDialogContainer = ({
  participantsModal,
  setParticipantsModal,
  isLoading,
}) => (
  <RecipientsDialog
    isModalVisible={participantsModal}
    setParticipantsModal={setParticipantsModal}
    isLoading={isLoading}
  />
);

ParticipantsDialogContainer.propTypes = {
  isLoading: PropTypes.bool,
  participantsModal: PropTypes.bool,
  setParticipantsModal: PropTypes.func,
};

ParticipantsDialogContainer.defaultProps = {
  isLoading: false,
  participantsModal: false,
  setParticipantsModal: noop,
};

const mapStateToProps = (state) => ({
  participantsModal: state.tickets.participantsModal,
  isLoading: state.tickets.participantsState === RequestStates.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setParticipantsModal: (isVisible) => dispatch(
    ticketsActions.setParticipantsModal(isVisible),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ParticipantsDialogContainer);
