import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './Header';
import * as ticketActions from '../../../redux/actions';
import { noop } from '../../../../../utils';

import './header.scss';

const HeaderContainer = React.memo(({
  setTicketSearchValue,
  getViewStatusOfAgent,
  setViewStatusAgent,
  setGroupChatDialog,
  setTypingMessage,
  scrollToBottom,
  groupChatInfo,
  removeNewMsg,
  viewStatus,
  userInfo,
  newMsg,
  users,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [searchInputRef, setSearchInputRef] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const removeLiveTicketStatus = () => {
    const email = userInfo.email;
    getViewStatusOfAgent().then((res) => {
      const viewStatusList = res.value.data[0][email];
      if (viewStatusList) {
        const viewAgentInfo = viewStatusList.split(',');
        const viewIndex = viewAgentInfo
          .filter((viewItem) => viewItem !== groupChatInfo.groupChatCode);
        setViewStatusAgent(viewIndex.join(','));
      }
    });
  };

  const handleClose = () => {
    localStorage.setItem('group-chat', JSON.stringify(false));
    setGroupChatDialog(false);
    setTypingMessage(groupChatInfo.groupChatId, 'N', '');
    removeLiveTicketStatus();
    if (location.pathname === '/group-chat') {
      history.push('/ticket');
    }
  };

  const fetchUser = (id) => {
    const name = users
      .find((agent) => agent.username === id);
    return name && name.firstname;
  };

  const moveHighLightedText = () => {
    const GroupChat = document.getElementById('group-chat-content');
    if (GroupChat) {
      const highlightedTag = GroupChat.querySelectorAll('.highlight_msg');
      if (highlightedTag && highlightedTag.length > 0) {
        document.getElementById('group-chat-content').scrollTop = highlightedTag[highlightedTag.length - 1].offsetTop - 30;
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      setTicketSearchValue(groupChatInfo.groupChatId, searchValue);
      setTimeout(() => {
        moveHighLightedText();
      }, 0);
    }
    if (e.keyCode === 27) {
      setIsSearch(false);
      setSearchValue('');
      setTicketSearchValue(groupChatInfo.groupChatId, '');
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const fetchTicketViewAgent = () => {
    let viewAgent;
    const viewList = viewStatus[groupChatInfo.groupChatCode]
      && viewStatus[groupChatInfo.groupChatCode];
    if (viewList && viewList.length > 0) {
      const viewInfo = viewList.filter((view) => view !== userInfo.email);
      viewAgent = viewInfo.length > 0 ? viewInfo.map((agent) => fetchUser(agent)) : '';
    }
    return viewAgent ? viewAgent.join(',') : '';
  };

  const fetchHighLightMsg = () => {
    const chat = document.getElementById('group-chat-content');
    if (chat) {
      const hightLightedMsg = chat.querySelectorAll('.highlight_msg');
      if (hightLightedMsg && hightLightedMsg.length > 0) {
        return hightLightedMsg.length.toString();
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (isSearch && searchInputRef) {
      searchInputRef.focus();
    }
  });

  return (
    <Header
      newMsg={newMsg}
      isSearch={isSearch}
      searchValue={searchValue}
      setIsSearch={setIsSearch}
      handleClose={handleClose}
      handleSearch={handleSearch}
      removeNewMsg={removeNewMsg}
      setSearchValue={setSearchValue}
      scrollToBottom={scrollToBottom}
      handleKeyUp={handleKeyUp}
      setTicketSearchValue={setTicketSearchValue}
      groupChatInfo={groupChatInfo}
      viewStatus={fetchTicketViewAgent()}
      fetchHighLightMsg={fetchHighLightMsg}
      setSearchInputRef={setSearchInputRef}
    />
  );
});

HeaderContainer.propTypes = {
  newMsg: PropTypes.number,
  setGroupChatDialog: PropTypes.func,
  removeNewMsg: PropTypes.func,
  scrollToBottom: PropTypes.func,
  setTicketSearchValue: PropTypes.func,
  setTypingMessage: PropTypes.func,
  getViewStatusOfAgent: PropTypes.func,
  setViewStatusAgent: PropTypes.func,
  viewStatus: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  groupChatInfo: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Array),
};

HeaderContainer.defaultProps = {
  newMsg: undefined,
  setGroupChatDialog: noop,
  removeNewMsg: noop,
  scrollToBottom: noop,
  setTicketSearchValue: noop,
  setTypingMessage: noop,
  getViewStatusOfAgent: noop,
  setViewStatusAgent: noop,
  viewStatus: {},
  userInfo: {},
  groupChatInfo: {},
  users: [],
};

const mapStateToProps = (state) => ({
  users: state.auth.users,
  userInfo: state.auth.userInfo,
  viewStatus: state.tickets.viewStatus,
  groupChatInfo: state.tickets.groupChatInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setGroupChatDialog: (isOpen) => dispatch(ticketActions.setGroupChatDialog(isOpen)),
  setTicketSearchValue: (id, value) => dispatch(ticketActions.setTicketSearchValue(id, value)),
  setViewStatusAgent: (idList) => dispatch(ticketActions.setViewStatusAgent(idList)),
  getViewStatusOfAgent: () => dispatch(ticketActions.getViewStatusOfAgent()),
  setTypingMessage: (conversationid, type, message) => dispatch(
    ticketActions.setTypingMessage(conversationid, type, message),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(HeaderContainer);
