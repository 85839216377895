import React from 'react';
import PropTypes from 'prop-types';
import { MdRefresh } from 'react-icons/md';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { noop } from '../../../../../utils';

const FilterdTickets = ({
  setFilterType,
  removeFilter,
  filterType,
  isSorting,
}) => (
  (isSorting) && (
    <div className="custom_ticket_filter">
      <div className="filters_info">
        {filterType.column === 'datechanged' && filterType.type === 'asc' ? (
          <p role="presentation" className="title" onClick={() => setFilterType({ type: 'desc', column: 'datechanged' })}>
            Date: <FaCaretDown title="Descending order" />
          </p>
        ) : (
          <p role="presentation" className="title" onClick={() => setFilterType({ type: 'asc', column: 'datechanged' })}>
            Date: <FaCaretUp title="Ascending order" className={filterType.column === 'datechanged' ? '' : 'low_opacity'} />
          </p>
        )}
        {filterType.column === 'rstatus' && filterType.type === 'desc' ? (
          <p role="presentation" className="title" onClick={() => setFilterType({ type: 'asc', column: 'rstatus' })}>
            Status: <FaCaretDown title="Descending order" />
          </p>
        ) : (
          <p role="presentation" className="title" onClick={() => setFilterType({ type: 'desc', column: 'rstatus' })}>
            Status: <FaCaretUp title="Ascending order" className={filterType.column === 'rstatus' ? '' : 'low_opacity'} />
          </p>
        )}
      </div>
      <MdRefresh className="reset_btn" onClick={removeFilter} />
    </div>
  )
);

FilterdTickets.propTypes = {
  isSorting: PropTypes.bool,
  setFilterType: PropTypes.func,
  removeFilter: PropTypes.func,
  filterType: PropTypes.instanceOf(Object),
};

FilterdTickets.defaultProps = {
  isSorting: false,
  setFilterType: noop,
  removeFilter: noop,
  filterType: {},
};

export default FilterdTickets;
