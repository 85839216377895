import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import MessageHeader from './MessageHeader';
import * as ticketActions from '../../../redux/actions';
import { RecipientsDialog } from '../../../../../shared/components/Dialogs';
import { noop } from '../../../../../utils';

import './message_header.scss';

const MessageHeaderContainer = React.memo(({
  setTicketSearchValue,
  participantsModal,
  chatInfo,
  isTodo,
  isFlag,
  users,
}) => {
  const matchParams = useParams();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [tooltipShow, setTooltip] = useState(false);
  const [searchRef, setSearchRef] = useState(null);
  const [isSearchRef, setIsSearchRef] = useState(true);

  const chatDetails = chatInfo && chatInfo[matchParams.conversationid];

  const handleSetTooltip = () => {
    setTooltip(true);
    setTimeout(() => {
      setTooltip(false);
    }, 1000);
  };

  const fetchAgentName = (agentid) => {
    if (agentid) {
      const name = users
        .find((agent) => agent.accountuserid === agentid);
      return name && name.firstname;
    } return null;
  };

  const handleSearchValue = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleIsSearch = () => {
    if (isSearch) {
      setIsSearch(false);
      setSearchValue('');
      setTicketSearchValue(matchParams.conversationid, '');
    } else {
      setIsSearch(true);
      setIsSearchRef(true);
    }
  };

  useEffect(() => {
    if (searchRef && isSearchRef) {
      searchRef.focus();
      setIsSearchRef(false);
    }
  });

  const moveHighLightedText = () => {
    const MessageChat = document.getElementById('ticket-messages');
    if (MessageChat) {
      const highlightedTag = MessageChat.querySelectorAll('.highlight_msg');
      if (highlightedTag && highlightedTag.length > 0) {
        highlightedTag[highlightedTag.length - 1].scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      setTicketSearchValue(matchParams.conversationid, searchValue);
      setTimeout(() => {
        moveHighLightedText();
      }, 0);
    }
    if (e.keyCode === 27) {
      setIsSearch(false);
      setSearchValue('');
      setTicketSearchValue(matchParams.conversationid, '');
    }
  };

  const fetchHighLightMsg = () => {
    const chat = document.getElementById('ticket-messages');
    if (chat) {
      const hightLightedMsg = chat.querySelectorAll('.highlight_msg');
      if (hightLightedMsg && hightLightedMsg.length > 0) {
        return hightLightedMsg.length;
      }
    }
    return undefined;
  };

  return (
    <>
      <MessageHeader
        matchParams={matchParams}
        isTodo={isTodo}
        isFlag={isFlag}
        location={location}
        isSearch={isSearch}
        tooltipShow={tooltipShow}
        handleKeyUp={handleKeyUp}
        setIsSearch={setIsSearch}
        searchValue={searchValue}
        setSearchRef={setSearchRef}
        fetchAgentName={fetchAgentName}
        handleIsSearch={handleIsSearch}
        handleSetTooltip={handleSetTooltip}
        fetchHighLightMsg={fetchHighLightMsg}
        handleSearchValue={handleSearchValue}
        headerInfo={chatDetails && chatDetails.previewInfo}
        publicURLCode={chatDetails && chatDetails.messageInfo && chatDetails.messageInfo.length > 0
          && chatDetails.messageInfo[0].public_access_urlcode}
      />
      {participantsModal && <RecipientsDialog />}
    </>
  );
});

MessageHeaderContainer.propTypes = {
  participantsModal: PropTypes.bool,
  isTodo: PropTypes.bool,
  isFlag: PropTypes.bool,
  setTicketSearchValue: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  chatInfo: PropTypes.instanceOf(Object),
};

MessageHeaderContainer.defaultProps = {
  participantsModal: false,
  isTodo: false,
  isFlag: false,
  setTicketSearchValue: noop,
  users: [],
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
  users: state.auth.users,
  participantsModal: state.tickets.participantsModal,
  isTodo: state.localInfo.isTodo,
  isFlag: state.localInfo.isFlag,
});

const mapDispatchToProps = (dispatch) => ({
  setTicketSearchValue: (id, value) => dispatch(ticketActions.setTicketSearchValue(id, value)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(MessageHeaderContainer);
