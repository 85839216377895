import * as actionTypes from './actionTypes';
import { deepCopy, hiddenFeatureOptions } from '../../utils';

const INITIAL_STATE = {
  todoList: [],
  flags: {},
  ...hiddenFeatureOptions.reduce((a, v) => ({ ...a, [v]: false }), {}),
};

export default (state = INITIAL_STATE, action) => {
  const payload = action.payload;
  switch (action.type) {
    case actionTypes.SET_HIDDEN_FEATURES_MODAL:
      return {
        ...state,
        hiddenFeaturesModel: payload,
      };
    case actionTypes.HANDLE_HIDDEN_FEATURES:
      return {
        ...state,
        [payload.type]: payload.value,
      };
    case actionTypes.SET_TODO_LIST:
      return {
        ...state,
        todoList: [
          ...state.todoList,
          ...payload,
        ],
      };
    case actionTypes.REMOVE_TODO:
      return {
        ...state,
        todoList: state.todoList.filter((todo) => todo.id !== payload),
      };
    case actionTypes.SET_FLAGS: {
      if (payload.type === 'update') {
        return {
          ...state,
          flags: {
            ...state.flags,
            [payload.id]: payload.status,
          },
        };
      } if (payload.type === 'remove') {
        const prevFlag = deepCopy(state.flags);
        delete prevFlag[payload.id];
        return {
          ...state,
          flags: prevFlag,
        };
      }
      return {
        ...state,
        flags: payload,
      };
    }
    default:
      return state;
  }
};
