import { useCallback, useEffect } from 'react';
import hotkeys from 'hotkeys-js';

const useHotkeys = (keys, callback, deps = []) => {
  const memoisedCallback = useCallback(callback, deps);

  useEffect(() => {
    hotkeys.filter = () => true;
    hotkeys(keys, memoisedCallback);

    return () => hotkeys.unbind(keys);
  }, [memoisedCallback]);
};

export default useHotkeys;
