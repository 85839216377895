import React from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

import { noop } from '../../../utils';

const IdleTimerSection = ({
  pauseTracking,
  debounce,
  onActive,
  onAction,
  timeout,
  onIdle,
}) => (
  <>
    {!pauseTracking && (
      <IdleTimer
        onIdle={onIdle}
        timeout={timeout}
        element={document}
        onActive={onActive}
        onAction={onAction}
        debounce={debounce}
        events={['keydown', 'mousedown']}
      />
    )}
  </>
);

IdleTimerSection.propTypes = {
  pauseTracking: PropTypes.bool,
  debounce: PropTypes.number,
  timeout: PropTypes.number,
  onActive: PropTypes.func,
  onIdle: PropTypes.func,
  onAction: PropTypes.func,
};

IdleTimerSection.defaultProps = {
  pauseTracking: false,
  debounce: 2000,
  timeout: 10000,
  onActive: noop,
  onIdle: noop,
  onAction: noop,
};

export default IdleTimerSection;
