import React from 'react';
import PropTypes from 'prop-types';
import { FaUsers } from 'react-icons/fa';

import { noop } from '../../../../../../utils';
import { RenderDropDown } from '../../../../../../shared/components/FormControl';

const RecipientInfo = ({
  handleStopPropagation,
  setParticipantsModal,
  BCCRecipient,
  ToRecipient,
  CCRecipient,
}) => (
  <>
    {(ToRecipient && ToRecipient.length > 0)
      || (BCCRecipient && BCCRecipient.length > 0)
      || (CCRecipient && CCRecipient.length > 0) ? (
        <div
          className="participant_info"
          onClick={() => setParticipantsModal(true)}
          role="presentation"
        >
          {ToRecipient.length > 0 && (
            <p className="participant_email">
              <span className="recipient">To:</span>
              <span className="recipient_list">
                {ToRecipient[0].emails}
                {ToRecipient[1] && (
                  <>
                    {'\n'}
                    {ToRecipient[1].emails}
                  </>
                )}
                {ToRecipient.length > 2 && (
                  <RenderDropDown
                    title={`+${ToRecipient.length - 2}`}
                    id={ToRecipient[0] + ToRecipient[1]}
                    className="more_link"
                    clickable={false}
                    popoverClass="more_link_popover"
                  >
                    {ToRecipient.map((emailItem) => <p onClick={handleStopPropagation} role="presentation">{emailItem.emails}</p>)}
                  </RenderDropDown>
                )}
              </span>
            </p>
          )}
          {CCRecipient.length > 0 && (
            <p className="participant_email">
              <span className="recipient">CC:</span>
              <span className="recipient_list">
                {CCRecipient[0].emails}
                {CCRecipient[1] && (
                  <>
                    {'\n'}
                    {CCRecipient[1].emails}
                  </>
                )}
                {CCRecipient.length > 2 && (
                  <RenderDropDown
                    title={`+${CCRecipient.length - 2}`}
                    id={CCRecipient.join(',')}
                    className="more_link"
                    clickable={false}
                    popoverClass="more_link_popover"
                  >
                    {CCRecipient.map((emailItem) => <p onClick={handleStopPropagation} role="presentation">{emailItem.emails}</p>)}
                  </RenderDropDown>
                )}
              </span>
            </p>
          )}
          {BCCRecipient.length > 0 && (
            <p className="participant_email">
              <span className="recipient">BCC:</span>
              <span className="recipient_list">
                {BCCRecipient[0].emails}
                {BCCRecipient[1] && (
                  <>
                    {'\n'}
                    {BCCRecipient[1].emails}
                  </>
                )}
                {BCCRecipient.length > 2 && (
                  <RenderDropDown
                    title={`+${BCCRecipient.length - 2}`}
                    id={BCCRecipient[0] + BCCRecipient[1]}
                    className="more_link"
                    clickable={false}
                    popoverClass="more_link_popover"
                  >
                    {BCCRecipient.map((emailItem) => <p onClick={handleStopPropagation} role="presentation">{emailItem.emails}</p>)}
                  </RenderDropDown>
                )}
              </span>
            </p>
          )}
        </div>
      ) : (
        <p
          className="add_recipients_info"
          onClick={() => setParticipantsModal(true)}
          role="presentation"
        >
          <FaUsers /> Recipients
        </p>
      )}
  </>
);

RecipientInfo.propTypes = {
  setParticipantsModal: PropTypes.func,
  handleStopPropagation: PropTypes.func,
  ToRecipient: PropTypes.instanceOf(Array),
  BCCRecipient: PropTypes.instanceOf(Array),
  CCRecipient: PropTypes.instanceOf(Array),
};

RecipientInfo.defaultProps = {
  setParticipantsModal: noop,
  handleStopPropagation: noop,
  ToRecipient: [],
  BCCRecipient: [],
  CCRecipient: [],
};

export default RecipientInfo;
