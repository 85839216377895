import moment from 'moment';

import * as actionTypes from './actionTypes';
import api from '../../../utils/api';
import { store } from '../../../index';

export const userLogin = (username, password) => ({
  type: actionTypes.USER_LOGIN,
  payload: api.get(`command.jsp?command=login&user=${username}&pass=${password}`),
});

export const userLogout = () => ({
  type: actionTypes.USER_LOGOUT,
  payload: api.get('command.jsp?command=logout'),
});

export const setAgentTicketCount = (id, count) => ({
  type: actionTypes.SET_AGENT_TICKET_COUNT,
  payload: { id, count },
});

export const getUserInfo = () => ({
  type: actionTypes.GET_USER_INFO,
  payload: api.get('command.jsp?command=users'),
});

export const getLoginStatus = () => ({
  type: actionTypes.GET_LOGIN_STATUS,
  payload: api.get('command.jsp?command=status'),
});

export const setOffsetTime = (offset) => ({
  type: actionTypes.SET_OFFSET_TIME,
  payload: offset,
});

export const setServerTime = (date) => ({
  type: actionTypes.SET_SERVER_TIME,
  payload: date,
});

export const showErrorModal = (isOpen) => ({
  type: actionTypes.SHOW_ERROR_MODAL,
  payload: isOpen,
});

export const setTrackingModal = (tracking) => ({
  type: actionTypes.SET_TRACKING_MODAL,
  payload: tracking,
});

export const setTimeTracking = () => {
  const storeItem = store.getState();
  console.log('54-> storeItem', storeItem);
  if (storeItem && storeItem.auth && storeItem.auth.pauseTracking) {
    console.log('54-> storeItem.auth.pauseTracking #1', storeItem.auth.pauseTracking);
    console.log('Time-> noop Action called when pauseTracking = true', { pauseTracking: storeItem.auth.pauseTracking });
    return { type: 'noop', payload: Promise.resolve('Success') };
  }
  if (storeItem && storeItem.auth && storeItem.auth.lastActivityTime && moment().diff(moment(storeItem.auth.lastActivityTime), 'seconds') < 10) {
    console.log('54-> storeItem.auth.pauseTracking #2', storeItem.auth.lastActivityTime);
    return { type: 'noop', payload: Promise.resolve('Success') };
  }
  console.log('Time-> SET_TIME_TRACKING Action called when pauseTracking = false', { pauseTracking: storeItem.auth.pauseTracking });
  console.log('Time-> timestamp', { timestamp: moment().format() });
  console.log('54-> SET_TIME_TRACKING #3');
  return {
    type: actionTypes.SET_TIME_TRACKING,
    payload: api.get('command.jsp?command=time_save&mobile=N'),
  };
};

export const setTimeTrackingForMobile = () => {
  const storeItem = store.getState();
  console.log('54-> storeItem', storeItem);
  if (storeItem && storeItem.auth && storeItem.auth.pauseTracking) {
    console.log('54-> storeItem.auth.pauseTracking #1', storeItem.auth.pauseTracking);
    console.log('Time-> noop Action called when pauseTracking = true', { pauseTracking: storeItem.auth.pauseTracking });
    return { type: 'noop', payload: Promise.resolve('Success') };
  }
  if (storeItem && storeItem.auth && storeItem.auth.lastActivityTime && moment().diff(moment(storeItem.auth.lastActivityTime), 'seconds') < 10) {
    console.log('54-> storeItem.auth.pauseTracking #2', storeItem.auth.lastActivityTime);
    return { type: 'noop', payload: Promise.resolve('Success') };
  }
  console.log('Time-> SET_TIME_TRACKING_FOR_MOBILE Action called when pauseTracking = false', { pauseTracking: storeItem.auth.pauseTracking });
  console.log('Time-> timestamp', { timestamp: moment().format() });
  console.log('54-> SET_TIME_TRACKING_FOR_MOBILE #3');
  return {
    type: actionTypes.SET_TIME_TRACKING_FOR_MOBILE,
    payload: api.get('command.jsp?command=time_save&mobile=Y'),
  };
};

export const setLastPauseTime = () => {
  console.log('Time-> setLastPauseTime called');
  return {
    type: actionTypes.STE_LAST_PAUSE_TIME,
  };
};

export const getTotalTime = () => ({
  type: actionTypes.SET_TOTAL_TIME_TRACKING,
  payload: api.get('command.jsp?command=time_save&history=true'),
});

export const getUserStatus = () => ({
  type: actionTypes.GET_USER_STATUS,
  payload: api.get('command.jsp?command=time_get&last_active=true'),
});

export const getWorkingHoursInfo = () => ({
  type: actionTypes.GET_WORKING_HOURS_INFO,
  payload: api.get('command.jsp?command=time_get&daily=true'),
});

export const setWorkingHoursDialog = (isVisible) => ({
  type: actionTypes.SET_WORKING_HOURS_DIALOG,
  payload: isVisible,
});

export const setChangePasswordDialog = (isVisible) => ({
  type: actionTypes.SET_CHANGE_PASSWORD_DIALOG,
  payload: isVisible,
});

export const setChangeUserPassword = (cpassword, npassword, repassword) => ({
  type: actionTypes.SET_CHANGE_USER_PASSWORD,
  payload: api.get(`command.jsp?command=passwd&password=${cpassword}&password1=${npassword}&password2=${repassword}`),
});

export const setInvoice = () => ({
  type: actionTypes.SET_INVOICE,
  payload: api.get('command.jsp?command=time_get&daily=true&invoice=true'),
});

export const setMultiThemeModal = (isOpen) => ({
  type: actionTypes.SET_MULTI_THEME_MODAL,
  payload: isOpen,
});

export const setPauseTracking = (isPause) => ({
  type: actionTypes.STE_PAUSE_TRACKING,
  payload: isPause,
});
