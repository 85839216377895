/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const SanitizingHtml = ({ htmlToRender, ...rest }) => (
  <div
    {...rest}
    dangerouslySetInnerHTML={{ __html: htmlToRender }}
  />
);

SanitizingHtml.propTypes = {
  htmlToRender: PropTypes.string.isRequired,
};

export default SanitizingHtml;
