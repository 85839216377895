import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FaRegClock,
  FaRegPauseCircle,
  FaMobileAlt,
  FaDesktop,
} from 'react-icons/fa';
import moment from 'moment';

import { noop, formatTime } from '../../../utils';

const ActivityTimer = React.memo(({
  serverTotalTimeTracking,
  serverTotalMobileSecs,
  handleTimeTracking,
  serverTimeTracking,
  serverMobileSecs,
  lastActivityTime,
  getTotalTime,
  pauseTracking,
}) => (
  <div
    className={classnames('activity_time_tracker', { pauseTracking }, { blink_animate: pauseTracking })}
    role="presentation"
    onClick={(e) => handleTimeTracking(e)}
  >
    <div className="activity_timer_section">
      {serverTimeTracking || serverMobileSecs ? (
        <div
          className="local_time"
        >
          {pauseTracking ? <FaRegPauseCircle /> : <FaRegClock />}
          <div style={{ display: 'flex', gridGap: '5px' }}>
            <div
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', gridGap: '3px',
              }}
            >
              <FaDesktop style={{ margin: 0 }} />
              <p style={{ fontSize: '0.8rem', lineHeight: 1 }}>{formatTime(serverTimeTracking) || ''}</p>
            </div>
            <div
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', gridGap: '3px',
              }}
            >
              <FaMobileAlt style={{ margin: 0 }} />
              <p style={{ fontSize: '0.8rem', lineHeight: 1 }}>{formatTime(serverMobileSecs) || ''}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="local_time">
          <FaRegClock />
          --:--
        </div>
      )}
      {serverTotalTimeTracking || serverTotalMobileSecs ? (
        <div
          className="local_time"
          onClick={getTotalTime}
          title="Time refreshes every hour, click to force refresh"
          role="presentation"
        >
          <span className="separator">/</span>
          <div style={{ display: 'flex', gridGap: '5px' }}>
            <div
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', gridGap: '3px',
              }}
            >
              <FaDesktop style={{ margin: 0 }} />
              <p style={{ fontSize: '0.8rem', lineHeight: 1 }}>{formatTime(serverTotalTimeTracking) || ''}</p>
            </div>
            <div
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', gridGap: '3px',
              }}
            >
              <FaMobileAlt style={{ margin: 0 }} />
              <p style={{ fontSize: '0.8rem', lineHeight: 1 }}>{formatTime(serverTotalMobileSecs) || ''}</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="local_time"
          onClick={getTotalTime}
          title="Time refreshes every hour, click to force refresh"
          role="presentation"
        >
          <span className="separator">/</span>
          --:--
        </div>
      )}
    </div>
    <p className="last_activity_time">Last activity on: {`${lastActivityTime ? moment(lastActivityTime).format('LTS') : '-'}`}</p>
  </div>
));

ActivityTimer.propTypes = {
  serverTimeTracking: PropTypes.string,
  serverMobileSecs: PropTypes.string,
  serverTotalTimeTracking: PropTypes.string,
  serverTotalMobileSecs: PropTypes.string,
  lastActivityTime: PropTypes.string,
  pauseTracking: PropTypes.bool,
  getTotalTime: PropTypes.func,
  handleTimeTracking: PropTypes.func,
};

ActivityTimer.defaultProps = {
  serverTimeTracking: undefined,
  serverMobileSecs: undefined,
  serverTotalTimeTracking: undefined,
  serverTotalMobileSecs: undefined,
  lastActivityTime: undefined,
  pauseTracking: false,
  getTotalTime: noop,
  handleTimeTracking: noop,
};

export default ActivityTimer;
