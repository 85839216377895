import React from 'react';
import PropTypes from 'prop-types';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { noop } from '../../../../utils';

const HiddenFeatureDialog = ({
  removeLocalStorage,
  isHiddenFeatures,
  handleHiddenFeatures,
  handleModal,
  isSorting,
  isTheme,
  isModal,
  isTodo,
  isFlag,
  isOpenTicket,
  isFlagOnTop,
  isTodoOnTop,
}) => (
  <CustomDialog
    isOpen={isModal}
    toggle={handleModal}
    title="Hidden Features"
    classNames="hidden_feature_dialog"
  >
    <label>
      <input
        type="checkbox"
        checked={isHiddenFeatures}
        onChange={() => {
          localStorage.setItem('isHiddenFeatures', !isHiddenFeatures);
          handleHiddenFeatures('isHiddenFeatures', !isHiddenFeatures);
        }}
      />
      <span>Enable hidden features</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isTheme}
        onChange={() => {
          localStorage.setItem('isTheme', !isTheme);
          handleHiddenFeatures('isTheme', !isTheme);
        }}
      />
      <span>Enable Themes</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isFlag}
        onChange={() => {
          localStorage.setItem('isFlag', !isFlag);
          handleHiddenFeatures('isFlag', !isFlag);
        }}
      />
      <span>Enable Flags</span>
    </label>
    <label className="ml_2">
      <input
        type="checkbox"
        checked={isFlagOnTop}
        onChange={() => {
          localStorage.setItem('isFlagOnTop', !isFlagOnTop);
          handleHiddenFeatures('isFlagOnTop', !isFlagOnTop);
        }}
      />
      <span>Show flagged items on top</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isTodo}
        onChange={() => {
          localStorage.setItem('isTodo', !isTodo);
          handleHiddenFeatures('isTodo', !isTodo);
        }}
      />
      <span>Enable Todo</span>
    </label>
    <label className="ml_2">
      <input
        type="checkbox"
        checked={isTodoOnTop}
        onChange={() => {
          localStorage.setItem('isTodoOnTop', !isTodoOnTop);
          handleHiddenFeatures('isTodoOnTop', !isTodoOnTop);
        }}
      />
      <span>Show Todo items on top</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isSorting}
        onChange={() => {
          localStorage.setItem('isSorting', !isSorting);
          handleHiddenFeatures('isSorting', !isSorting);
        }}
      />
      <span>Enable sorting</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isOpenTicket}
        onChange={() => {
          localStorage.setItem('isOpenTicket', !isOpenTicket);
          handleHiddenFeatures('isOpenTicket', !isOpenTicket);
        }}
      />
      <span>Indicate open tickets</span>
    </label>
    <div onClick={removeLocalStorage} role="presentation" className="remove_btn">
      Reset all
    </div>
  </CustomDialog>
);

HiddenFeatureDialog.propTypes = {
  isModal: PropTypes.bool,
  isHiddenFeatures: PropTypes.bool,
  isTodo: PropTypes.bool,
  isSorting: PropTypes.bool,
  isFlag: PropTypes.bool,
  isFlagOnTop: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  isTodoOnTop: PropTypes.bool,
  isTheme: PropTypes.bool,
  handleModal: PropTypes.func,
  handleHiddenFeatures: PropTypes.func,
  removeLocalStorage: PropTypes.func,
};

HiddenFeatureDialog.defaultProps = {
  isModal: false,
  isHiddenFeatures: false,
  isTodo: false,
  isSorting: false,
  isFlag: false,
  isOpenTicket: false,
  isFlagOnTop: false,
  isTodoOnTop: false,
  isTheme: false,
  handleModal: noop,
  handleHiddenFeatures: noop,
  removeLocalStorage: noop,
};

export default HiddenFeatureDialog;
