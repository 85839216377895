import React from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../../../../utils';

const TypingDraft = ({
  typingInfo,
  viewInfo,
}) => (
  typingInfo && typingInfo.length > 0 && (
    viewInfo(typingInfo) && (
      <div className="typing_draft_section">
        {viewInfo(typingInfo)} is typing...
      </div>
    )
  )
);

TypingDraft.propTypes = {
  viewInfo: PropTypes.func,
  typingInfo: PropTypes.instanceOf(Array),
};

TypingDraft.defaultProps = {
  viewInfo: noop,
  typingInfo: [],
};

export default TypingDraft;
