import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import CustomDropZone from './CustomDropZone';
import { noop } from '../../../utils';

import './custom_drop_zone.scss';

const CustomDropZoneContainer = ({
  handleDropZone,
  className,
  title,
  id,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleDropZone(acceptedFiles);
  }, []);

  const {
    getRootProps, getInputProps, isDragActive,
  } = useDropzone({ onDrop });

  return (
    <CustomDropZone
      id={id}
      title={title}
      className={className}
      isDragActive={isDragActive}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
    />
  );
};

CustomDropZoneContainer.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  handleDropZone: PropTypes.func,
};

CustomDropZoneContainer.defaultProps = {
  title: 'upload here',
  className: '',
  id: 'custom-dropzone',
  handleDropZone: noop,
};

export default CustomDropZoneContainer;
