import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MultiThemeDialog from './MultiThemeDialog';
import { actions as authActions } from '../../../../modules/authForms';
import { noop } from '../../../../utils';

import './multi_theme_dialog.scss';

const MultiThemeDialogContainer = React.memo(({
  setMultiThemeModal,
  multiThemeModal,
}) => {
  const [themeStyle, setThemeStyle] = useState('light');
  const [accentStyle, setAccentStyle] = useState('orange');

  const trans = () => {
    document.documentElement.classList.add('transition');
    window.setTimeout(() => {
      document.documentElement.classList.remove('transition');
    }, 200);
  };

  const handleThemeStyle = (theme) => {
    trans();
    setThemeStyle(theme);
    document.documentElement.setAttribute('data-theme', `${theme}-${accentStyle}`);
    localStorage.setItem('theme-style', `${theme}-${accentStyle}`);
  };

  const handleAccentStyle = (accent) => {
    trans();
    setAccentStyle(accent);
    document.documentElement.setAttribute('data-theme', `${themeStyle}-${accent}`);
    localStorage.setItem('theme-style', `${themeStyle}-${accent}`);
  };

  useEffect(() => {
    const localTheme = localStorage.getItem('theme-style');
    if (localTheme) {
      document.documentElement.setAttribute('data-theme', localTheme);
      setThemeStyle(localTheme.split('-')[0]);
      setAccentStyle(localTheme.split('-')[1]);
    } else {
      const getTheme = document.documentElement.getAttribute('data-theme');
      if (getTheme) {
        localStorage.setItem('theme-style', getTheme);
        setThemeStyle(getTheme.split('-')[0]);
        setAccentStyle(getTheme.split('-')[1]);
      }
    }
  }, []);

  return (
    <MultiThemeDialog
      themeStyle={themeStyle}
      multiThemeModal={multiThemeModal}
      handleThemeStyle={handleThemeStyle}
      handleAccentStyle={handleAccentStyle}
      setMultiThemeModal={setMultiThemeModal}
      accentStyle={accentStyle}
    />
  );
});

MultiThemeDialogContainer.propTypes = {
  multiThemeModal: PropTypes.bool,
  setMultiThemeModal: PropTypes.func,
};

MultiThemeDialogContainer.defaultProps = {
  multiThemeModal: false,
  setMultiThemeModal: noop,
};

const mapStateToProps = (state) => ({
  multiThemeModal: state.auth.multiThemeModal,
});

const mapDispatchToProps = (dispatch) => ({
  setMultiThemeModal: (isOpen) => dispatch(authActions.setMultiThemeModal(isOpen)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(MultiThemeDialogContainer);
