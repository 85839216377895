import React from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import RightButtons from './RightButtons';
import * as actions from '../../../../redux/actions';
import api from '../../../../../../utils/api';
import useHotkeys from '../../../../../../utils/useHotkeys';
import { showNotification } from '../../../../../../utils/Notifications';
import { withDeviceType } from '../../../../../../hoc';
import { noop } from '../../../../../../utils';

import './right_buttons.scss';

const RightButtonsContainer = ({
  footerActionsLoading,
  updateResolvedTicket,
  setButtonInputClick,
  setWebNotification,
  setInputSubmitType,
  removeActiveTab,
  activeTabsInfo,
  setActiveTabs,
  setInputType,
  deviceType,
  chatInfo,
  userInfo,
}) => {
  const matchParams = useParams();
  const history = useHistory();
  const date = moment(new Date()).format('lll');

  const onHandlePostpone = () => {
    setInputType('N');
    setInputSubmitType('postpone');
    setButtonInputClick('Ok');
  };

  const onHandleTransfer = () => {
    setInputType('N');
    setInputSubmitType('transfer');
    setButtonInputClick('Ok');
  };

  const onHandlerReopen = () => {
    const rstatus = chatInfo[matchParams.conversationid]
    && chatInfo[matchParams.conversationid].previewInfo.rstatus;
    if (rstatus === 'R' || rstatus === 'A') {
      setInputType('N');
      setInputSubmitType('reopen');
      setButtonInputClick('Ok');
    }
  };

  const onCloseTab = () => {
    removeActiveTab(matchParams.conversationid, 'ticket');
    const prevTabInfo = activeTabsInfo.map(({
      messageCount, isLoading, length, ...rest
    }) => ({ ...rest }));
    const remainingTicket = prevTabInfo
      .filter((ticketInfo) => ticketInfo.key !== matchParams.conversationid);
    setActiveTabs(
      base64.encode(encodeURIComponent(JSON.stringify(remainingTicket))),
    );
    history.push('/ticket');
  };

  const resolvedSubmitAction = (isClose) => {
    if (chatInfo[matchParams.conversationid].previewInfo.rstatus !== 'R') {
      footerActionsLoading(matchParams.conversationid, true);
      api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=R&rstatus=R`)
        .then(async (res) => {
          if (res.data[0].response_msg === 'OK') {
            setInputType('');
            setInputSubmitType('');
            if (isClose === 'closeTab') {
              onCloseTab();
            } else {
              await updateResolvedTicket(matchParams.conversationid, date,
                userInfo.firstname, res.data[0].message_groupid);
            }
            setWebNotification({
              notify: true, audible: true, title: 'Ticket resolved', timeout: 5000, audibleType: 'resolved',
            });
            footerActionsLoading(matchParams.conversationid, false);
          } else {
            showNotification(`Failed ${res.data[0].error || ''}`, 'error', false);
            footerActionsLoading(matchParams.conversationid, false);
          }
        });
    }
  };

  useHotkeys('alt+e, ctrl+e', () => resolvedSubmitAction('closeTab'));

  return (
    <RightButtons
      deviceType={deviceType}
      onHandlerReopen={onHandlerReopen}
      onHandlePostpone={onHandlePostpone}
      onHandleTransfer={onHandleTransfer}
      resolvedSubmitAction={resolvedSubmitAction}
      chatInfo={chatInfo[matchParams.conversationid]}
    />
  );
};

RightButtonsContainer.propTypes = {
  deviceType: PropTypes.string,
  setInputType: PropTypes.func,
  setInputSubmitType: PropTypes.func,
  setButtonInputClick: PropTypes.func,
  updateResolvedTicket: PropTypes.func,
  removeActiveTab: PropTypes.func,
  setActiveTabs: PropTypes.func,
  footerActionsLoading: PropTypes.func,
  setWebNotification: PropTypes.func,
  activeTabsInfo: PropTypes.instanceOf(Array),
  chatInfo: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
};

RightButtonsContainer.defaultProps = {
  deviceType: '',
  setInputType: noop,
  setInputSubmitType: noop,
  setButtonInputClick: noop,
  updateResolvedTicket: noop,
  removeActiveTab: noop,
  setActiveTabs: noop,
  footerActionsLoading: noop,
  setWebNotification: noop,
  activeTabsInfo: [],
  chatInfo: {},
  userInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
  activeTabsInfo: state.tickets.activeTabsInfo,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setInputType: (type) => dispatch(actions.setInputType(type)),
  setInputSubmitType: (type) => dispatch(actions.setInputSubmitType(type)),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
  updateResolvedTicket: (conversationid, date, firstname, groupid) => dispatch(
    actions.updateResolvedTicket(conversationid, date, firstname, groupid),
  ),
  removeActiveTab: (key, type) => dispatch(
    actions.removeActiveTab(key, type),
  ),
  setActiveTabs: (tabs) => dispatch(actions.setActiveTabs(tabs)),
  setWebNotification: (webInfo) => dispatch(actions.setWebNotification(webInfo)),
  footerActionsLoading: (id, isloading) => dispatch(actions.footerActionsLoading(id, isloading)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(RightButtonsContainer));
