import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import InternalTicketForm from './InternalTicketForm';
import { actions } from '../../../../../modules/tickets';
import { required } from '../../../../../utils/validators';
import { noop } from '../../../../../utils';

import './internal_ticket_form.scss';

const InternalTicketFormContainer = ({
  createNewTicketLoading,
  setNewInternalTicket,
  setNewTicketModal,
  resetForm,
}) => {
  const history = useHistory();

  const [toInternalEmailRef, setToInternalEmailRef] = useState(null);
  const [focus, setFocus] = useState(true);

  const onSubmit = (values) => {
    createNewTicketLoading(true);
    setNewInternalTicket(values.Subject, values.Message).then((internalRes) => {
      if (internalRes.value.data) {
        const res = internalRes.value.data[0];
        history.push(`/ticket/${res.code}/${res.conversationid}`);
        setNewTicketModal(false, undefined);
        resetForm('newInternalTicketForm');
        createNewTicketLoading(false);
      }
    });
  };

  const validateForm = (values) => {
    const errors = {};
    errors.Subject = required(values.Subject) && 'Subject is required';
    return errors;
  };

  useEffect(() => {
    if (toInternalEmailRef && focus) {
      toInternalEmailRef.focus();
      setFocus(false);
    }
  });

  return (
    <InternalTicketForm
      onSubmit={onSubmit}
      validate={validateForm}
      setToEmailRef={setToInternalEmailRef}
    />
  );
};

InternalTicketFormContainer.propTypes = {
  setNewTicketModal: PropTypes.func,
  resetForm: PropTypes.func,
  createNewTicketLoading: PropTypes.func,
  setNewInternalTicket: PropTypes.func,
};

InternalTicketFormContainer.defaultProps = {
  setNewTicketModal: noop,
  resetForm: noop,
  createNewTicketLoading: noop,
  setNewInternalTicket: noop,
};

const mapDispatchToProps = (dispatch) => ({
  resetForm: (formName) => dispatch(reset(formName)),
  createNewTicketLoading: (isLoading) => dispatch(actions.createNewTicketLoading(isLoading)),
  setNewTicketModal: (isOpen, type) => dispatch(actions.setNewTicketModal(isOpen, type)),
  setNewInternalTicket: (subject, message) => dispatch(
    actions.setNewInternalTicket(subject, message),
  ),
});

export default connect(
  null, mapDispatchToProps,
)(InternalTicketFormContainer);
