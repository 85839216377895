/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export default (ComposedComponent) => {
  const WithDeviceType = ({ deviceType, orientation, ...props }) => (
    <ComposedComponent
      deviceType={deviceType}
      orientation={orientation}
      {...props}
    />
  );

  WithDeviceType.propTypes = {
    deviceType: PropTypes.string,
    orientation: PropTypes.string,
  };

  WithDeviceType.defaultProps = {
    deviceType: '',
    orientation: '',
  };

  const mapStateToProps = (state) => ({
    deviceType: state.deviceDetector.deviceType,
    orientation: state.deviceDetector.orientation,
  });

  return connect(mapStateToProps, null)(WithDeviceType);
};
