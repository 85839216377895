import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';

import { RenderInput, RenderButton } from '../../../FormControl';
import { noop } from '../../../../../utils';

const ChangePasswordForm = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)} className="change_password_form">
    <div className="password_input_wrapper">
      <span>Current Password: </span>
      <Field
        name="currentpassword"
        id="currentpassword"
        placeholder="Current Password"
        type="password"
        component={RenderInput}
      />
    </div>
    <div className="password_input_wrapper">
      <span>Password: </span>
      <Field
        name="password"
        id="password"
        placeholder="Password"
        type="password"
        component={RenderInput}
      />
    </div>
    <div className="password_input_wrapper">
      <span>Re-enter Password: </span>
      <Field
        name="reenterpassword"
        id="reenterpassword"
        placeholder="Re-enter Password"
        type="password"
        component={RenderInput}
      />
    </div>
    <div className="change_password_btn">
      <RenderButton>
        Submit
      </RenderButton>
    </div>
  </Form>
);

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

ChangePasswordForm.defaultProps = {
  onSubmit: noop,
};

export default reduxForm({
  form: 'ChangePasswordForm',
})(ChangePasswordForm);
