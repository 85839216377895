import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '../../../../../../modules/tickets/redux/actions';
import * as authActions from '../../../../../../modules/authForms/redux/actions';
import AgentList from './AgentList';

import './agent_list.scss';
import { noop } from '../../../../../../utils';

const SHOW_STATUS_FOR_USERS = ['ben@crushftp.com', 'vipullimbachiya@gmail.com'];

const AgentListContainer = React.memo(({
  getCustomSearchTicket,
  setAgentTicketCount,
  getUserStatus,
  pauseTracking,
  agentList,
  userInfo,
  users,
}) => {
  const [isShowUserStatus, setShowUserStatus] = useState(false);
  const handleTotalTickets = async (agentid) => {
    const tickets = await getCustomSearchTicket('', 'C', agentid, 'false', 'false', '', '');
    setAgentTicketCount(agentid, tickets.value.data.length);
  };

  const fetchUserInfo = async () => {
    window.ticketPolling = 1;
    if (window.ticketPolling) { clearTimeout(window.ticketPolling); }
    await agentList.map((info) => handleTotalTickets(info.accountuserid));
    window.ticketPolling = setTimeout(() => {
      if (window.ticketPolling) { fetchUserInfo(); }
    }, 300000);
  };

  const fetchUserStatus = () => {
    window.userStatus = 1;
    if (window.userStatus) { clearTimeout(window.userStatus); }
    getUserStatus().finally(() => {
      window.userStatus = setTimeout(() => {
        if (window.userStatus) { fetchUserStatus(); }
      }, 60000);
    });
  };

  useEffect(() => {
    fetchUserInfo();
    return () => {
      clearTimeout(window.chatTypingPolling);
      window.chatTypingPolling = false;
    };
  }, [agentList]);

  useEffect(() => {
    if (!pauseTracking) {
      setShowUserStatus(SHOW_STATUS_FOR_USERS.includes(userInfo.email));
    } else {
      clearTimeout(window.userStatus);
      setShowUserStatus(false);
    }
  }, [userInfo, pauseTracking]);

  useEffect(() => {
    if (isShowUserStatus) {
      fetchUserStatus();
    }
  }, [isShowUserStatus]);

  return (
    <AgentList
      users={users}
      isShowUserStatus={isShowUserStatus}
    />
  );
});

AgentListContainer.propTypes = {
  getCustomSearchTicket: PropTypes.func,
  setAgentTicketCount: PropTypes.func,
  getUserStatus: PropTypes.func,
  pauseTracking: PropTypes.bool,
  userInfo: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Array),
  agentList: PropTypes.instanceOf(Array),
};

AgentListContainer.defaultProps = {
  getCustomSearchTicket: noop,
  setAgentTicketCount: noop,
  getUserStatus: noop,
  pauseTracking: false,
  users: [],
  agentList: [],
  userInfo: {},
};

const mapStateToProps = (state) => ({
  users: state.auth.users,
  agentList: state.auth.agentList,
  userInfo: state.auth.userInfo,
  pauseTracking: state.auth.pauseTracking,
});

const mapDispatchToProps = (dispatch) => ({
  setAgentTicketCount: (id, value) => dispatch(authActions.setAgentTicketCount(id, value)),
  getUserStatus: () => dispatch(authActions.getUserStatus()),
  getCustomSearchTicket: (q, status, agentid, unassigned, invert, startDate,
    endDate) => dispatch(actions.getCustomSearchTicket(q, status, agentid, unassigned,
    invert, startDate, endDate)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AgentListContainer);
