import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Field, reduxForm, Form } from 'redux-form';

import CrushftpLogo from '../../../../assets/images/crushftp-logo.png';
import { RenderButton, RenderInput } from '../../../../shared/components/FormControl';
import { SpinnerLoader } from '../../../../shared/components/Loader';
import { noop } from '../../../../utils';

const Signin = ({
  handleSubmit,
  onSubmit,
  LoadingState,
  isRememberMe,
  handleRememberMe,
}) => (
  <BlockUi
    tag="div"
    blocking={LoadingState}
    className="full_height full_width block-ui-background"
    loader={<SpinnerLoader />}
  >
    <div className="signin_section">
      <Form className="form_section" onSubmit={handleSubmit(onSubmit)}>
        <img alt="logo" src={CrushftpLogo} className="logo" />
        <div className="form_input">
          <Field
            name="username"
            id="username"
            placeholder="Email"
            type="text"
            component={RenderInput}
          />
          <Field
            name="password"
            id="password"
            placeholder="Password"
            type="password"
            component={RenderInput}
          />
        </div>
        <label className="remember_me">
          <input
            type="checkbox"
            checked={isRememberMe}
            onChange={(e) => handleRememberMe(e.target.checked)}
          />
          <span>Remember me</span>
        </label>
        {/* <span className="forgot_link">Forgot Password?</span> */}
        <div className="form_btn">
          <RenderButton type="submit">
            Login
          </RenderButton>
        </div>
        {/* <span className="signup_link">Not a member? Sign up now</span> */}
      </Form>
    </div>
  </BlockUi>
);

Signin.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  handleRememberMe: PropTypes.func,
  LoadingState: PropTypes.bool,
  isRememberMe: PropTypes.bool,
};

Signin.defaultProps = {
  onSubmit: noop,
  handleRememberMe: noop,
  LoadingState: false,
  isRememberMe: false,
};

export default reduxForm({
  form: 'signinForm',
})(Signin);
