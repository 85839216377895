import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import {
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaReply,
  FaCodeBranch,
  FaRegClipboard,
} from 'react-icons/fa';
import { MdMoreVert } from 'react-icons/md';

import SantizingHtml from '../SanitizingHtml/SanitizingHtml';
import Expander from '../Expander/ExpanderContainer';
import Note from '../Note/Note';
import AttachmentInfo from '../AttachmentInfo/AttachmentInfo';
import { RenderDropDown } from '../FormControl';
import {
  formatHTML,
  noop,
  getTimeValue,
  displayDateTimeFormat,
} from '../../../utils';
import { BASE_URL } from '../../../config';

const MessageBox = ({
  handleAttachmentDownload,
  handleLightBoxPreview,
  handleReadMoreLess,
  handleForwardClick,
  filterMessageData,
  handleSplitClick,
  handleImageClick,
  handleRenderHtml,
  additionalInfo,
  fileAttachment,
  searchValue,
  readMoreLess,
  messageInfo,
  handleClick,
  offsetTime,
  isOurReply,
  ccemail,
  toemail,
}) => (
  (filterMessageData.length > 0
    || fileAttachment.length > 0) && (
    <div
      key={`${messageInfo.message_groupid}-messagebox`}
      className={classnames(
        'message_box_section',
        { new_messagges: messageInfo.newMessage },
        { isOurReply },
      )}
    >
      <div className="message_box_container" key={messageInfo.datecreated}>
        <div className="message_box_header">
          <div className="profile_section">
            <span className="user_name">{messageInfo.user_full_name}</span>
            {!isOurReply && !additionalInfo.From && (
              <span className="user_email">{messageInfo.emails}</span>
            )}
            {!isOurReply && additionalInfo.From && (
              <span>From: {additionalInfo.From}</span>
            )}
            {isOurReply && toemail.length > 0 && (
              <>
                <span>To: {toemail[0]}, {toemail[1]}</span>
                {toemail.length > 2 && (
                  <>
                    <RenderDropDown
                      title={`+${toemail.length - 2}`}
                      id={messageInfo.message_groupid + toemail[0]}
                      className="more_link"
                      popoverClass="more_link_popover"
                      clickable={false}
                    >
                      {toemail.map((emailItem) => <p>{emailItem}</p>)}
                    </RenderDropDown>
                  </>
                )}
              </>
            )}
            {ccemail.length > 0 && (
              <>
                <span>CC: {ccemail[0]}, {ccemail[1]}</span>
                {ccemail.length > 2 && (
                  <RenderDropDown
                    title={`+${ccemail.length - 2}`}
                    id={messageInfo.message_groupid + ccemail[0]}
                    className="more_link"
                    popoverClass="more_link_popover"
                    clickable={false}
                  >
                    {ccemail.map((emailItem) => <p>{emailItem}</p>)}
                  </RenderDropDown>
                )}
              </>
            )}
          </div>
          {messageInfo.datecreated && (
            <div className="datecreated">
              {messageInfo.isPushedRecord
                && moment(messageInfo.datecreated).format(displayDateTimeFormat)}
              {messageInfo.isPushedRecord
                && `${' '}(${moment(messageInfo.datecreated).fromNow()})`}
              {!messageInfo.isPushedRecord
                && getTimeValue(messageInfo.datecreated, 'both', offsetTime)}
            </div>
          )}
          <RenderDropDown
            icon={<MdMoreVert />}
            id={messageInfo.message_groupid}
            className="messgae_box_more_options"
            popoverClass="more_options_list"
          >
            <li
              onClick={() => handleForwardClick(messageInfo.message_groupid)}
              role="presentation"
            >
              <FaReply />
              Forward
            </li>
            <li
              onClick={() => handleSplitClick(messageInfo.conversationid,
                messageInfo.message_groupid)}
              role="presentation"
              className="split_ticket"
            >
              <FaCodeBranch />
              Split
            </li>
            <li
              role="presentation"
              onClick={() => handleRenderHtml(
                messageInfo.conversationid, messageInfo.message_groupid,
              )}
            >
              <FaRegClipboard />
              {filterMessageData.find((msgFormat) => msgFormat.format === 'H') ? 'Render as Text' : 'Render as HTML'}
            </li>
          </RenderDropDown>
        </div>
        <div className="message_box_content">
          {filterMessageData
            && filterMessageData.map((data) => {
              if (data.rtype === 'Q') {
                return (
                  <Expander key={`${data.messageid}-mesasgebox-expander`}>
                    <SantizingHtml
                      key={`${data.messageid}-mesasgebox-expander-santizingHtml`}
                      onClick={(evt) => handleClick(evt)}
                      htmlToRender={formatHTML(data.message, data.format, null, searchValue)}
                      role="presentation"
                    />
                  </Expander>
                );
              }
              if (data.rtype === 'N') {
                return (
                  <Note key={`${data.messageid}-mesasgebox-note`}>
                    <SantizingHtml
                      key={`${data.messageid}-mesasgebox-note-SantizingHtml`}
                      onClick={(evt) => handleClick(evt)}
                      htmlToRender={formatHTML(data.message, data.format, null, searchValue)}
                      role="presentation"
                    />
                  </Note>
                );
              }
              return data.message.length < 50000
                ? (
                  <SantizingHtml
                    key={data.messageid}
                    onClick={(evt) => handleClick(evt)}
                    className="message_content"
                    role="presentation"
                    htmlToRender={formatHTML(data.message, data.format, null, searchValue)}
                  />
                ) : (
                  <>
                    <SantizingHtml
                      key={data.messageid}
                      onClick={(evt) => handleClick(evt)}
                      className="message_content"
                      role="presentation"
                      htmlToRender={
                        formatHTML(`${readMoreLess ? data.message : data.message.slice(0, 5000)}`, data.format)
                      }
                    />
                    <span
                      onClick={handleReadMoreLess}
                      role="presentation"
                      className={`read_more_less_content ${readMoreLess ? 'less' : 'more'}`}
                    >
                      {readMoreLess ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
                      {''}
                      Show {readMoreLess ? 'less' : 'more'}
                    </span>
                  </>
                );
            })}
          {fileAttachment
            && fileAttachment.length !== 0
            && fileAttachment.map(
              (file, index) => file.attachment
                && file.attachment.map((attachmentItem) => (
                  <AttachmentInfo
                    src={`${BASE_URL}command.jsp?command=file_link&file=${file.message}`}
                    fileName={attachmentItem.filename}
                    btntitle="download"
                    onClick={() => handleAttachmentDownload(file.message)}
                    handleViewClick={
                      (e) => {
                        e.stopPropagation();
                        console.log('216-> e', e);
                        handleImageClick(
                          attachmentItem.filename,
                          file.message,
                          attachmentItem.filetype,
                        );
                      }
                    }
                    handleImageClick={() => handleLightBoxPreview(index)}
                    fileType={attachmentItem.filetype}
                    fileSize={attachmentItem.filesize}
                  />
                )),
            )}
        </div>
      </div>
    </div>
  )
);

MessageBox.propTypes = {
  offsetTime: PropTypes.string,
  searchValue: PropTypes.string,
  isOurReply: PropTypes.bool,
  readMoreLess: PropTypes.bool,
  handleAttachmentDownload: PropTypes.func,
  handleImageClick: PropTypes.func,
  handleSplitClick: PropTypes.func,
  handleClick: PropTypes.func,
  handleReadMoreLess: PropTypes.func,
  handleForwardClick: PropTypes.func,
  handleLightBoxPreview: PropTypes.func,
  handleRenderHtml: PropTypes.func,
  messageInfo: PropTypes.instanceOf(Object),
  additionalInfo: PropTypes.instanceOf(Object),
  filterMessageData: PropTypes.instanceOf(Array),
  fileAttachment: PropTypes.instanceOf(Array),
  ccemail: PropTypes.instanceOf(Array),
  toemail: PropTypes.instanceOf(Array),
};

MessageBox.defaultProps = {
  offsetTime: '',
  searchValue: undefined,
  isOurReply: false,
  readMoreLess: false,
  handleAttachmentDownload: noop,
  handleImageClick: noop,
  handleSplitClick: noop,
  handleClick: noop,
  handleReadMoreLess: noop,
  handleForwardClick: noop,
  handleLightBoxPreview: noop,
  handleRenderHtml: noop,
  messageInfo: {},
  additionalInfo: {},
  filterMessageData: [],
  fileAttachment: [],
  ccemail: [],
  toemail: [],
};

export default MessageBox;
