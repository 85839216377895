import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import RequestStates from '../../../../utils/request-states';
import WorkingHoursDialog from './WorkingHoursDialog';
import { actions as authFormsActions } from '../../../../modules/authForms';
import { actions as ticketActios } from '../../../../modules/tickets';
import { noop, formatTime, generateHoursInfoTable } from '../../../../utils';

import './working_hours_dialog.scss';

const WorkingHoursDialogContainer = ({
  workingHoursInfoLoading,
  setWorkingHoursDialog,
  setNewInternalTicket,
  getWorkingHoursInfo,
  workingHoursModal,
  setTransferTicket,
  workingHoursInfo,
  getTotalTime,
  setInvoice,
  userInfo,
  users,
}) => {
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const history = useHistory();

  const handleInvoiceSubmit = () => {
    const stateDate = Object.entries(workingHoursInfo[workingHoursInfo.length - 1]).flat()[0];
    const endDate = Object.entries(workingHoursInfo[0]).flat()[0];
    setInvoiceLoading(true);
    getTotalTime().then(async (totalTimeRes) => {
      if (totalTimeRes.value.data[0]) {
        const totalHours = formatTime(totalTimeRes.value.data[0].total_secs);
        const subject = `Invoice: ${userInfo.email} ${stateDate} - ${endDate} : ${totalHours}`;
        const bodyMessage = `Date - Hours - Ignored Hours\n${generateHoursInfoTable(workingHoursInfo)}Total - ${totalHours}`;
        setNewInternalTicket(subject, bodyMessage).then((intTicRes) => {
          const userDetails = users.find((userItem) => userItem.firstname.toLowerCase() === 'ben');
          const ticketRes = intTicRes.value.data[0];
          setTransferTicket(ticketRes.conversationid, userDetails.firstname, 'M', userDetails.accountuserid, '', '').then((assignRes) => {
            if (assignRes.value.data[0].response_msg === 'OK') {
              setInvoice().then((res) => {
                if (res.value.data) {
                  getTotalTime();
                }
              });
              setInvoiceLoading(false);
              setWorkingHoursDialog(false);
              history.push(`/ticket/${ticketRes.code}/${ticketRes.conversationid}`);
            }
          });
        });
      }
    });
  };

  useEffect(() => {
    getWorkingHoursInfo();
  }, []);

  return (
    <WorkingHoursDialog
      workingHoursInfo={workingHoursInfo}
      isLoading={workingHoursInfoLoading || invoiceLoading}
      workingHoursModal={workingHoursModal}
      setWorkingHoursDialog={setWorkingHoursDialog}
      handleInvoiceSubmit={handleInvoiceSubmit}
    />
  );
};

WorkingHoursDialogContainer.propTypes = {
  workingHoursModal: PropTypes.bool,
  workingHoursInfoLoading: PropTypes.bool,
  getWorkingHoursInfo: PropTypes.func,
  setWorkingHoursDialog: PropTypes.func,
  setInvoice: PropTypes.func,
  setNewInternalTicket: PropTypes.func,
  getTotalTime: PropTypes.func,
  setTransferTicket: PropTypes.func,
  workingHoursInfo: PropTypes.instanceOf(Array),
  users: PropTypes.instanceOf(Array),
  userInfo: PropTypes.instanceOf(Object),
};

WorkingHoursDialogContainer.defaultProps = {
  workingHoursModal: false,
  workingHoursInfoLoading: false,
  getWorkingHoursInfo: noop,
  setWorkingHoursDialog: noop,
  setInvoice: noop,
  setNewInternalTicket: noop,
  getTotalTime: noop,
  setTransferTicket: noop,
  workingHoursInfo: [],
  users: [],
  userInfo: {},
};

const mapStateToProps = (state) => ({
  workingHoursInfoLoading: state.auth.workingHoursInfoState === RequestStates.loading,
  workingHoursInfo: state.auth.workingHoursInfo,
  workingHoursModal: state.auth.workingHoursModal,
  userInfo: state.auth.userInfo,
  users: state.auth.users,
});

const mapDispatchToProps = (dispatch) => ({
  setWorkingHoursDialog: (isVisible) => dispatch(authFormsActions.setWorkingHoursDialog(isVisible)),
  setInvoice: () => dispatch(authFormsActions.setInvoice()),
  getTotalTime: () => dispatch(authFormsActions.getTotalTime()),
  getWorkingHoursInfo: () => dispatch(authFormsActions.getWorkingHoursInfo()),
  setNewInternalTicket:
    (subject, message) => dispatch(ticketActios.setNewInternalTicket(subject, message)),
  setTransferTicket: (cid, agentName, status, agentId, body, attachment) => dispatch(
    ticketActios.setTransferTicket(cid, agentName, status, agentId, body, attachment),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(WorkingHoursDialogContainer);
