import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { noop } from '../../../../utils';

const SearchMove = ({
  handleMoveUpDown,
  count,
  active,
}) => (
  <>
    {count && (
      <div className="search_move">
        <span style={{ marginRight: 5 }}>{parseInt(active, 10)} of {parseInt(count, 10)}</span>
        <FaChevronUp onClick={(e) => handleMoveUpDown(e, 'up')} className={parseInt(active, 10) === 1 ? 'disable' : ''} />
        <FaChevronDown onClick={(e) => handleMoveUpDown(e, 'down')} className={parseInt(active, 10) === parseInt(count, 10) ? 'disable' : ''} />
      </div>
    )}
  </>
);

SearchMove.propTypes = {
  count: PropTypes.number,
  active: PropTypes.number,
  handleMoveUpDown: PropTypes.func,
};

SearchMove.defaultProps = {
  count: undefined,
  active: undefined,
  handleMoveUpDown: noop,
};

export default SearchMove;
