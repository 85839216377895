import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MessageBox from './MessageBox';
import ImageLightBox from '../ImageLightBox/ImageLightBoxContainer';
import { actions as ticketsActions } from '../../../modules/tickets';
import { BASE_URL } from '../../../config';
import { getMimeType, noop } from '../../../utils';

import './message_box.scss';

const MessageBoxContainer = React.memo(({
  changeRenderMessageType,
  setForwardMessageInfo,
  setButtonInputClick,
  setInputSubmitType,
  setSplitTicket,
  additionalInfo,
  searchValue,
  setInputType,
  messageInfo,
  offsetTime,
  isOurReply,
}) => {
  const history = useHistory();
  const [readMoreLess, setReadMoreLess] = useState(false);
  const [lightBoxPreview, setLightBoxPreview] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const handleReadMoreLess = () => {
    setReadMoreLess(!readMoreLess);
  };

  const handleAttachmentDownload = (link) => {
    const a = document.createElement('a');
    a.href = `${BASE_URL}command.jsp?command=file_link&file=${link}&download=true`;
    a.click();
  };

  const handleImageClick = (fileName, link, type) => {
    const ext = fileName.split('.').pop();
    const mimeType = getMimeType(ext, type);
    window.open(
      `${BASE_URL}command.jsp?command=file_link&file=${link}&mimetype=${mimeType}`,
    );
  };

  const handleClick = (evt) => {
    const { target } = evt;
    if (target && target.classList.contains('ticket-code')) {
      const code = target.innerText;
      history.push(`/ticket?q=${code}`);
    }
  };

  const handleForwardClick = (msgId) => {
    setForwardMessageInfo(msgId, 'accounting@CrushFTP.com');
    setInputType('M');
    setInputSubmitType('forward');
    setButtonInputClick('Ok');
  };

  const handleSplitClick = (cid, groupid) => {
    setSplitTicket(cid, groupid).then((res) => {
      if (res.value && res.value.data[0]) {
        history.push(`/ticket/${res.value.data[0].code}/${res.value.data[0].conversationid}`);
      }
    });
  };

  const fetchImageUrls = () => {
    const fileAttachment = messageInfo.messages.filter((msgAttachment) => msgAttachment.rtype === 'F');
    if (fileAttachment && fileAttachment.length > 0) {
      const urls = fileAttachment.map((fileItem) => ({
        src: `${BASE_URL}command.jsp?command=file_link&file=${fileItem.message}`,
        alt: fileItem.attachment[0].filename,
      }));
      return urls;
    } return [];
  };

  const handleLightBoxPreview = (id) => {
    setImgIndex(id);
    setLightBoxPreview(true);
  };

  const handleRenderHtml = (cid, msgGid) => {
    changeRenderMessageType(cid, msgGid);
  };

  const handlePreviewClose = () => {
    const previewDiv = document.getElementsByClassName('react-viewer')[0].parentNode;
    previewDiv.remove();
    setLightBoxPreview(false);
  };

  return (
    <>
      <MessageBox
        offsetTime={offsetTime}
        isOurReply={isOurReply}
        messageInfo={messageInfo}
        handleClick={handleClick}
        readMoreLess={readMoreLess}
        additionalInfo={additionalInfo}
        handleImageClick={handleImageClick}
        handleSplitClick={handleSplitClick}
        handleRenderHtml={handleRenderHtml}
        handleForwardClick={handleForwardClick}
        handleReadMoreLess={handleReadMoreLess}
        handleLightBoxPreview={handleLightBoxPreview}
        searchValue={searchValue}
        handleAttachmentDownload={handleAttachmentDownload}
        ccemail={additionalInfo.CC && additionalInfo.CC.split(',')}
        toemail={additionalInfo.To && additionalInfo.To.split(',')}
        filterMessageData={messageInfo && messageInfo.messages.filter((msg) => msg
          .rtype === 'M' || msg.rtype === 'Z' || msg.rtype === 'N' || msg.rtype === 'Q')}
        fileAttachment={messageInfo.messages.filter((msgAttachment) => msgAttachment.rtype === 'F')}
      />
      {lightBoxPreview && fetchImageUrls().length > 0 && (
        <ImageLightBox
          images={fetchImageUrls()}
          visible={lightBoxPreview}
          onClose={handlePreviewClose}
          activeIndex={imgIndex}
        />
      )}
    </>
  );
});

MessageBoxContainer.propTypes = {
  offsetTime: PropTypes.string,
  searchValue: PropTypes.string,
  isOurReply: PropTypes.bool,
  setInputType: PropTypes.func,
  setInputSubmitType: PropTypes.func,
  setButtonInputClick: PropTypes.func,
  setForwardMessageInfo: PropTypes.func,
  setSplitTicket: PropTypes.func,
  changeRenderMessageType: PropTypes.func,
  messageInfo: PropTypes.instanceOf(Object),
  additionalInfo: PropTypes.instanceOf(Object),
};

MessageBoxContainer.defaultProps = {
  offsetTime: '',
  searchValue: undefined,
  isOurReply: false,
  messageInfo: {},
  additionalInfo: {},
  setInputType: noop,
  setInputSubmitType: noop,
  setButtonInputClick: noop,
  setForwardMessageInfo: noop,
  setSplitTicket: noop,
  changeRenderMessageType: noop,
};

const mapStateToProps = (state) => ({
  offsetTime: state.auth.offsetTime,
});

const mapDispatchToProps = (dispatch) => ({
  setInputType: (type) => dispatch(ticketsActions.setInputType(type)),
  setInputSubmitType: (type) => dispatch(ticketsActions.setInputSubmitType(type)),
  setButtonInputClick: (isclick) => dispatch(ticketsActions.setButtonInputClick(isclick)),
  setForwardMessageInfo: (id, email) => dispatch(ticketsActions.setForwardMessageInfo(id, email)),
  setSplitTicket: (cid, gid) => dispatch(ticketsActions.setSplitTicket(cid, gid)),
  changeRenderMessageType: (cid, msgId) => dispatch(
    ticketsActions.changeRenderMessageType(cid, msgId),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(MessageBoxContainer);
