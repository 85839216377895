import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IdleTimerSection from './IdleTimerSection';
import { actions as formActions } from '../../../modules/authForms';
import { actions as ticketActions } from '../../../modules/tickets';
import { hasMobileDevice, noop } from '../../../utils';
import {
  idleActivityTimeoutInMilliSeconds,
} from '../../../config';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const IdleTimerSectionContainer = React.memo(({
  setWebNotification,
  setTrackingModal,
  setPauseTracking,
  pauseTracking,
  setTimeTracking,
  setTimeTrackingForMobile,
}) => {
  const onIdle = () => {
    if (!pauseTracking) {
      setPauseTracking(!pauseTracking);
      setTrackingModal(true);
      setWebNotification({
        notify: true,
        audible: true,
        title: 'Due to inactivity your time tracking is stopped',
        timeout: 5000,
        audibleType: 'idle',
      });
    }
  };

  const onAction = () => {
    console.log('54-> IdleTimerSectionContainer #onAction setTimeTracking');
    debounce(() => {
      if (hasMobileDevice()) {
        setTimeTrackingForMobile();
      } else {
        setTimeTracking();
      }
    }, 1000);
  };

  return (
    <IdleTimerSection
      debounce={500}
      pauseTracking={pauseTracking}
      onIdle={onIdle}
      timeout={idleActivityTimeoutInMilliSeconds}
      onAction={onAction}
    />
  );
});

IdleTimerSectionContainer.propTypes = {
  pauseTracking: PropTypes.bool,
  setTrackingModal: PropTypes.func,
  setWebNotification: PropTypes.func,
  setPauseTracking: PropTypes.func,
  setTimeTracking: PropTypes.func,
  setTimeTrackingForMobile: PropTypes.func,
};

IdleTimerSectionContainer.defaultProps = {
  pauseTracking: false,
  setTrackingModal: noop,
  setWebNotification: noop,
  setPauseTracking: noop,
  setTimeTracking: noop,
  setTimeTrackingForMobile: noop,
};

const mapStateToProps = (state) => ({
  pauseTracking: state.auth.pauseTracking,
});

const mapDispatchToProps = (dispatch) => ({
  setTrackingModal: (isOpen) => dispatch(formActions.setTrackingModal(isOpen)),
  setPauseTracking: (isPause) => dispatch(formActions.setPauseTracking(isPause)),
  setWebNotification: (webInfo) => dispatch(ticketActions.setWebNotification(webInfo)),
  setTimeTracking: () => dispatch(formActions.setTimeTracking()),
  setTimeTrackingForMobile: () => dispatch(formActions.setTimeTrackingForMobile()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(IdleTimerSectionContainer);
