import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Favico from 'favico.js';
import { connect } from 'react-redux';

import * as ticketActions from '../redux/actions';
import { noop } from '../../../utils';

const preventRendering = (prevProps, nextProps) => {
  if ((JSON.stringify(nextProps.assignTickets)
  !== JSON.stringify(prevProps.assignTickets))) {
    return false;
  }
  return true;
};

const FaviconTicket = React.memo(({
  saveAssignTicketsLength,
  assignTicketsLength,
  assignTickets,
}) => {
  let favicon = {};
  const setFaviconLength = () => {
    if (assignTicketsLength !== assignTickets.length) {
      favicon = new Favico({
        animation: 'slide',
        bgColor: '#F6B450',
        textColor: '#000',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
      });
      favicon.badge(assignTickets.length);
      saveAssignTicketsLength(assignTickets.length);
    }
  };

  useEffect(() => {
    setFaviconLength();
  }, [assignTickets.length]);

  return (
    <></>
  );
},
(prevProps, nextProps) => preventRendering(prevProps, nextProps));

FaviconTicket.propTypes = {
  assignTicketsLength: PropTypes.number,
  saveAssignTicketsLength: PropTypes.func,
  assignTickets: PropTypes.instanceOf(Array),
};

FaviconTicket.defaultProps = {
  assignTicketsLength: 0,
  saveAssignTicketsLength: noop,
  assignTickets: [],
};

const mapStateToProps = (state) => ({
  assignTickets: state.tickets.assignTickets,
  assignTicketsLength: state.tickets.assignTicketsLength,
});

const mapDispatchToProps = (dispatch) => ({
  saveAssignTicketsLength: (length) => dispatch(ticketActions.saveAssignTicketsLength(length)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(FaviconTicket);
