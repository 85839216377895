import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import InputBox from './InputBox';
import * as ticketActions from '../../../redux/actions';
import api from '../../../../../utils/api';
import { noop } from '../../../../../utils';

import './input_box.scss';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const InputBoxContainer = React.memo(({
  pushedGroupChatMessage,
  setGroupChatInputvalue,
  footerActionsLoading,
  removeAttchmentItem,
  setUploadFileInfo,
  setTypingMessage,
  scrollToBottom,
  groupChatInfo,
  removeNewMsg,
  ticketStatus,
  userInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isDropZone, setDropZone] = useState(false);

  const onSetTypingMsg = (value) => debounce(() => {
    setTypingMessage(groupChatInfo.groupChatId, 'N', value);
  }, 500);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setGroupChatInputvalue(value);
    onSetTypingMsg(value);
  };

  const onChatCancel = () => {
    setTypingMessage(groupChatInfo.groupChatId, 'N', '');
    setGroupChatInputvalue('');
  };

  const fetchAttachmentInfo = (Files) => Promise.all(
    Files.map((file) => setUploadFileInfo(groupChatInfo.groupChatId, file)
      .then((res) => res.value.data[0])),
  );

  const onChatSubmit = async () => {
    const linkList = [];
    if (!loading) {
      if (uploadFiles && uploadFiles.length > 0) {
        await fetchAttachmentInfo(uploadFiles)
          .then((res) => res.map((file) => linkList.push(file.linkid)));
      }
      const links = linkList ? linkList.join(',') : '';
      if ((groupChatInfo.inputValue && groupChatInfo.inputValue.trim()) || links) {
        setLoading(true);
        footerActionsLoading(groupChatInfo.groupChatId, true);
        await setGroupChatInputvalue('');
        await api.get(`command.jsp?command=update_ticket&conversationid=${groupChatInfo.groupChatId}&status=N&message=${encodeURIComponent(groupChatInfo.inputValue || '')}&rstatus=${ticketStatus}&filelinks=${links || ''}`)
          .then(async (resInfo) => {
            const res = resInfo.data[0];
            setGroupChatInputvalue('');
            await pushedGroupChatMessage(groupChatInfo.groupChatId, userInfo.firstname, moment(new Date()).format('YYYY-MM-DD h:mm:ss'), res.message_groupid, res.message2, userInfo.agentid);
            await setTypingMessage(groupChatInfo.groupChatId, 'N', '');
            await removeAttchmentItem(groupChatInfo.groupChatId);
            await scrollToBottom();
            setUploadFiles([]);
            setDropZone(false);
            removeNewMsg();
            const textarea = document.getElementById('gorup_chat_textarea');
            textarea.style.cssText = 'height:35px';
          }).finally(() => {
            setLoading(false);
            footerActionsLoading(groupChatInfo.groupChatId, false);
          });
      }
    }
  };

  const handleAttachmentClick = () => {
    if (isDropZone) {
      setUploadFiles([]);
      setDropZone(false);
    } else {
      setDropZone(true);
      setTimeout(() => {
        const element = document.getElementById('chatsection-dropzone');
        if (element) { element.click(); }
      }, 0);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && !e.altKey && !e.ctrlKey) {
      e.preventDefault();
      onChatSubmit();
    }
    if (e.keyCode === 27) onChatCancel();
  };

  const autoResizeInputBox = () => {
    const textarea = document.getElementById('gorup_chat_textarea');
    const autosize = () => {
      setTimeout(() => {
        textarea.style.cssText = 'height:auto;';
        textarea.style.cssText = `height:${textarea.scrollHeight}px`;
      }, 0);
    };

    if (textarea && textarea.scrollHeight > 35 && textarea.scrollHeight < 150) {
      textarea.addEventListener('keydown', autosize);
    }
  };

  const _draggleLister = () => {
    const id = document.getElementById('group-chatsection-dropzone');
    if (!id) { setDropZone(true); }
  };

  const previewAdded = (fileItem) => fileItem.map(
    (file) => Object.assign(file, { preview: URL.createObjectURL(file) }),
  );

  const fetchFile = (prevState, fileinfo) => {
    const fileItems = previewAdded(fileinfo);
    if (prevState) { fileItems.push(...prevState); }
    return previewAdded(fileItems);
  };

  const _pasteListener = (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    let item;
    if (items.length === 1) {
      item = items[0];
    } else if (items.length === 2) {
      item = items[1];
    }
    const file = item.getAsFile();
    if (file && file.type.indexOf('image') === 0) {
      setUploadFiles((prevState) => fetchFile(prevState, [file]));
      setDropZone(true);
    }
  };

  useEffect(() => {
    const textEditor = document.getElementById('group_chat_container');
    if (textEditor) {
      textEditor.addEventListener('dragover', _draggleLister);
      textEditor.addEventListener('paste', _pasteListener);
    }
    if (groupChatInfo.inputValue) {
      setTypingMessage(groupChatInfo.groupChatId, 'N', groupChatInfo.inputValue);
    }
    const textarea = document.getElementById('gorup_chat_textarea');
    setTimeout(() => {
      textarea.style.cssText = 'height:auto;';
      textarea.style.cssText = `height:${textarea.scrollHeight}px`;
    }, 0);
  }, []);

  useEffect(() => {
    autoResizeInputBox();
  });

  return (
    <InputBox
      handleAttachmentClick={handleAttachmentClick}
      handleInputChange={handleInputChange}
      setUploadFiles={setUploadFiles}
      chatInputValue={groupChatInfo.inputValue}
      handleKeyDown={handleKeyDown}
      onChatSubmit={onChatSubmit}
      uploadFiles={uploadFiles}
      isDropZone={isDropZone}
      loading={loading}
    />
  );
});

InputBoxContainer.propTypes = {
  ticketStatus: PropTypes.string,
  setTypingMessage: PropTypes.func,
  pushedGroupChatMessage: PropTypes.func,
  footerActionsLoading: PropTypes.func,
  setUploadFileInfo: PropTypes.func,
  removeAttchmentItem: PropTypes.func,
  scrollToBottom: PropTypes.func,
  setGroupChatInputvalue: PropTypes.func,
  removeNewMsg: PropTypes.func,
  userInfo: PropTypes.instanceOf(Object),
  groupChatInfo: PropTypes.instanceOf(Object),
};

InputBoxContainer.defaultProps = {
  ticketStatus: 'C',
  setTypingMessage: noop,
  pushedGroupChatMessage: noop,
  footerActionsLoading: noop,
  setUploadFileInfo: noop,
  removeAttchmentItem: noop,
  scrollToBottom: noop,
  setGroupChatInputvalue: noop,
  removeNewMsg: noop,
  userInfo: {},
  groupChatInfo: {},
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  groupChatInfo: state.tickets.groupChatInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setUploadFileInfo: (id, file) => dispatch(ticketActions.setUploadFileInfo(id, file)),
  removeAttchmentItem: (id) => dispatch(ticketActions.removeAttchmentItem(id)),
  setGroupChatInputvalue: (value) => dispatch(ticketActions.setGroupChatInputvalue(value)),
  footerActionsLoading:
    (id, isloading) => dispatch(ticketActions.footerActionsLoading(id, isloading)),
  setTypingMessage: (conversationid, type, message) => dispatch(
    ticketActions.setTypingMessage(conversationid, type, message),
  ),
  pushedGroupChatMessage: (conversationid, name, date, groupid, message, userid) => dispatch(
    ticketActions.pushedGroupChatMessage(conversationid, name, date, groupid, message, userid),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(InputBoxContainer);
