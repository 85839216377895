import React from 'react';
import PropTypes from 'prop-types';

import HiddenFeature from '../../components/HiddenFeature';
import { ErrorHandlingDialog, WorkingHoursDialog, MultiThemeDialog } from '../../components/Dialogs';

const MainTemplate = ({
  workingHoursModal,
  children,
  isTheme,
}) => (
  <>
    <div className="app_content_container">
      {children}
    </div>
    <ErrorHandlingDialog />
    {workingHoursModal && <WorkingHoursDialog />}
    <HiddenFeature hiddenChildValue={isTheme}>
      <MultiThemeDialog />
    </HiddenFeature>
  </>
);

MainTemplate.propTypes = {
  workingHoursModal: PropTypes.bool,
  isTheme: PropTypes.bool,
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

MainTemplate.defaultProps = {
  workingHoursModal: false,
  isTheme: false,
};

export default MainTemplate;
