/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import './render_input.scss';
import { noop } from '../../../../utils';

const SelectType = (type, properties) => {
  switch (type) {
    case 'textarea':
      return (
        <textarea
          {...properties}
        />
      );
    default:
      return (
        <input
          {...properties}
        />
      );
  }
};

const RenderInput = ({
  input, meta: { touched, error }, type, name, id, placeholder, disabled, className, innerRef,
}) => {
  const properties = {
    id,
    type,
    name,
    value: input.value,
    onBlur: () => input.onBlur(input.value),
    onChange: (e) => input.onChange(e.target.value),
    disabled,
    placeholder,
    ref: innerRef,
    className: `input_control ${touched && error ? 'error-field' : ''}`,
  };

  return (
    <div className={`input_wrapper ${className}`}>
      {SelectType(type, properties)}
      {touched && error && <p className="error_text">{error}</p>}
    </div>
  );
};

RenderInput.propTypes = {
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  innerRef: PropTypes.func,
};

RenderInput.defaultProps = {
  input: {},
  meta: {},
  type: '',
  name: '',
  id: '',
  className: '',
  placeholder: '',
  disabled: false,
  innerRef: noop,
};

export default RenderInput;
