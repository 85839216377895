import React from 'react';
import {
  Route, HashRouter, Switch, Redirect,
} from 'react-router-dom';
import { SideNavigationContainer, TopNavigationContainer } from './shared/components/AppNavigation';

import redirect from './hoc/redirect';
import Playground from './modules/playground/Playground';
import GroupChat from './modules/tickets/components/GroupChat/GroupChatContainer';
import NotFound from './scenes/notFound/NotFound';
import { Signin, Logout } from './scenes/authForms';
import { Tickets, TicketDetails } from './scenes/tickets';

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/ticket" />} />
      <Route exact path="/signin" component={redirect(Signin)} />
      <Route exact path="/logout" component={Logout} />
      <div className="main_template">
        <TopNavigationContainer />
        <div className="app_content_section">
          <SideNavigationContainer />
          <Route
            exact
            path="/ticket"
            render={({ location }) => <Tickets key={location.search || ''} />}
          />
          <Route
            exact
            path="/ticket/:code/:conversationid"
            render={({ match }) => <TicketDetails key={match.params.conversationid || ''} />}
          />
          <Route exact path="/group-chat" component={GroupChat} />
        </div>
      </div>
      <Route exact path="/playground" component={Playground} />
      <Route path="*" component={NotFound} />
    </Switch>
  </HashRouter>
);

export default Routes;
