import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TicketTableMobile from './TicketTableMobile';

import './ticket_table.scss';

const TicketTableMobileContainer = ({
  offsetTime,
  gridData,
  users,
  isFlag,
}) => {
  const agentName = (agentid) => {
    let info = {};
    if (gridData) {
      info = users.find((agent) => agent.accountuserid === agentid);
    }
    return info && info.firstname;
  };
  return (
    <TicketTableMobile
      isFlag={isFlag}
      gridData={gridData}
      agentName={agentName}
      offsetTime={offsetTime}
    />
  );
};

TicketTableMobileContainer.propTypes = {
  offsetTime: PropTypes.string,
  isFlag: PropTypes.bool,
  users: PropTypes.instanceOf(Array),
  gridData: PropTypes.instanceOf(Array),
};

TicketTableMobileContainer.defaultProps = {
  offsetTime: '',
  isFlag: false,
  users: [],
  gridData: [],
};

const mapStateToProps = (state) => ({
  offsetTime: state.auth.offsetTime,
  isFlag: state.localInfo.isFlag,
});

export default connect(
  mapStateToProps,
)(TicketTableMobileContainer);
