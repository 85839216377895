import React from 'react';
import PropTypes from 'prop-types';

import MobileNavigation from './MobileNavigation/MobileNavigation';
import { noop } from '../../../../../utils';

const SideNavigation = ({
  setChangePasswordDialog,
  handleMobileExpander,
  setCustomSearchModal,
  isMobileExpanded,
  selectedUrl,
  deviceType,
}) => (
  <>
    {deviceType === 'mobile'
      && (
      <MobileNavigation
        selectedUrl={selectedUrl}
        isExpanded={isMobileExpanded}
        handleExpander={handleMobileExpander}
        setCustomSearchModal={setCustomSearchModal}
        setChangePasswordDialog={setChangePasswordDialog}
      />
      )}
  </>
);

SideNavigation.propTypes = {
  selectedUrl: PropTypes.string,
  deviceType: PropTypes.string,
  isMobileExpanded: PropTypes.bool,
  handleMobileExpander: PropTypes.func,
  setChangePasswordDialog: PropTypes.func,
  setCustomSearchModal: PropTypes.func,
};

SideNavigation.defaultProps = {
  selectedUrl: '',
  deviceType: '',
  isMobileExpanded: false,
  handleMobileExpander: noop,
  setChangePasswordDialog: noop,
  setCustomSearchModal: noop,
};

export default SideNavigation;
