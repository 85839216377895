/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../../../utils';

import './simple_input.scss';

const SelectType = (type, properties) => {
  switch (type) {
    case 'textarea':
      return (
        <textarea
          {...properties}
        />
      );
    default:
      return (
        <input
          {...properties}
        />
      );
  }
};

const SimpleInput = ({
  type, onChange, value, className, innerRef, ...rest
}) => {
  const properties = {
    value,
    onChange,
    className: `simple_input_control ${className}`,
    ref: innerRef,
    ...rest,
  };

  return (
    <>
      {SelectType(type, properties)}
    </>
  );
};

SimpleInput.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  innerRef: PropTypes.func,
};

SimpleInput.defaultProps = {
  value: '',
  type: 'text',
  className: '',
  onChange: noop,
  innerRef: noop,
};

export default SimpleInput;
