import React from 'react';
import PropTypes from 'prop-types';

const SpinnerLoader = ({
  width, height, className, color,
}) => (
  <svg
    className={`spinner ${className}`}
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 0 0"
  >
    <path
      fill={color}
      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1s"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

SpinnerLoader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SpinnerLoader.defaultProps = {
  className: '',
  color: '#F6B450',
  height: 100,
  width: 100,
};

export default SpinnerLoader;
