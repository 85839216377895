import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomSearch from './CustomSearchDialog';
import { actions } from '../../../../modules/tickets';
import { noop } from '../../../../utils';

import './custom_search_dialog.scss';

const CustomSearchDialogContiner = ({
  users,
  customSearchModal,
  setCustomSearchModal,
}) => {
  const history = useHistory();

  const [searchString, setSearchString] = useState('');
  const [searchTabName, setSearchTabName] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAgents, setselectedAgents] = useState([]);
  const [unassignChecked, setUnassignChecked] = useState(false);
  const [invertChecked, setInvertChecked] = useState(false);
  const [isDateEnable, setDateEnable] = useState(false);
  const [subjectRef, setSubjectRef] = useState(null);
  const [focus, setFocus] = useState(true);

  const [dateRangePicker, setDateRangePicker] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const ticketStatus = [
    { value: 'All', label: 'All' },
    { value: 'C', label: 'Open' },
    { value: 'R', label: 'Resolved' },
    { value: 'W', label: 'Postponed' },
  ];

  const userInfoList = users && users.reduce((init, currentValue) => ([...init, {
    value: currentValue.firstname,
    label: currentValue.firstname,
    agentid: currentValue.accountuserid,
  }]), []);

  const agentsListAll = [
    { value: 'All', label: 'All' },
    ...userInfoList,
  ];

  const handleStatusSelect = (e) => {
    if (e && e.find((statusItem) => statusItem && statusItem.value === 'All')) {
      setSelectedStatus([
        { value: 'C', label: 'Open' },
        { value: 'R', label: 'Resolved' },
        { value: 'W', label: 'Postponed' },
      ]);
    } else {
      setSelectedStatus(e);
    }
  };

  const handleAgentsSelect = (e) => {
    if (e && e.find((statusItem) => statusItem && statusItem.value === 'All')) {
      setselectedAgents(userInfoList);
    } else {
      setselectedAgents(e);
    }
  };

  const handleCustomSearch = (e) => {
    e.preventDefault();
    const statusKey = selectedStatus && selectedStatus.map((statusItem) => statusItem && statusItem.value).join('');
    const agentidKey = selectedAgents && selectedAgents.map((agentItem) => agentItem && agentItem.agentid).join(',');
    if (isDateEnable) {
      if (moment(dateRangePicker[0].endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        const startDateDiff = moment(dateRangePicker[0].startDate, 'DD-MM-YYYY');
        const endDateDiff = moment(dateRangePicker[0].endDate, 'DD-MM-YYYY');
        history.push(`/ticket?q=${searchString}&status=${statusKey || ''}&agentid=${(agentidKey) || ''}&unassigned=${unassignChecked}&invert=${invertChecked}&date_start=&date_end=&meta=d&tab_name=${searchTabName}&last_x_days=${endDateDiff.diff(startDateDiff, 'days') + 1}`);
      } else {
        const endDate = moment(dateRangePicker[0].endDate).format('YYYY-MM-DD') === moment(dateRangePicker[0].startDate).format('YYYY-MM-DD') ? '' : moment(dateRangePicker[0].endDate).format('YYYY-MM-DD');
        history.push(`/ticket?q=${searchString}&status=${statusKey || ''}&agentid=${(agentidKey) || ''}&unassigned=${unassignChecked}&invert=${invertChecked}&date_start=${moment(dateRangePicker[0].startDate).format('YYYY-MM-DD') || ''}&date_end=${endDate || ''}&meta=d&tab_name=${searchTabName}`);
      }
      setCustomSearchModal();
    } else {
      history.push(`/ticket?q=${searchString}&status=${statusKey || ''}&agentid=${(agentidKey) || ''}&unassigned=${unassignChecked}&invert=${invertChecked}&meta=nd&tab_name=${searchTabName}`);
      setCustomSearchModal();
    }
  };

  const resetSetting = () => {
    setSearchString('');
    setSelectedStatus([]);
    setselectedAgents([]);
    setUnassignChecked(false);
    setInvertChecked(false);
    setDateEnable(false);
    setFocus(false);
    setSubjectRef(null);
  };

  const handleCancel = (e) => {
    if (e.keyCode === 27) {
      resetSetting();
      setCustomSearchModal();
    }
  };

  useEffect(() => {
    if (subjectRef && focus) {
      subjectRef.focus();
      setFocus(false);
    }
  });

  return (
    <CustomSearch
      customSearchModal={customSearchModal}
      ticketStatus={ticketStatus}
      selectedStatus={selectedStatus}
      handleStatusSelect={handleStatusSelect}
      searchString={searchString}
      setSearchTabName={setSearchTabName}
      setSearchString={setSearchString}
      agentsListAll={agentsListAll}
      selectedAgents={selectedAgents}
      handleAgentsSelect={handleAgentsSelect}
      unassignChecked={unassignChecked}
      setUnassignChecked={setUnassignChecked}
      setInvertChecked={setInvertChecked}
      invertChecked={invertChecked}
      setCustomSearchModal={setCustomSearchModal}
      handleCustomSearch={handleCustomSearch}
      setDateRangePicker={setDateRangePicker}
      dateRangePicker={dateRangePicker}
      setDateEnable={setDateEnable}
      isDateEnable={isDateEnable}
      setSubjectRef={setSubjectRef}
      searchTabName={searchTabName}
      handleCancel={handleCancel}
    />
  );
};

CustomSearchDialogContiner.propTypes = {
  customSearchModal: PropTypes.bool,
  setCustomSearchModal: PropTypes.func,
  users: PropTypes.instanceOf(Array),
};

CustomSearchDialogContiner.defaultProps = {
  customSearchModal: false,
  setCustomSearchModal: noop,
  users: [],
};

const mapStateToProps = (state) => ({
  users: state.auth.users,
  customSearchModal: state.tickets.customSearchModal,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomSearchModal: () => dispatch(actions.setCustomSearchModal()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(CustomSearchDialogContiner);
