import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import base64 from 'base-64';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import ActionsButton from './ActionsButton';
import * as actions from '../../../redux/actions';
import useHotkeys from '../../../../../utils/useHotkeys';
import api from '../../../../../utils/api';
import { withDeviceType } from '../../../../../hoc';
import { showNotification } from '../../../../../utils/Notifications';
import {
  noop, getTimeValuePlus, stripDownEmails, validateMultiEmails, spliceString,
} from '../../../../../utils';

import './actions_button.scss';

const ActionsButtonContainer = ({
  updateTransferTicket,
  setForwardEmailInput,
  footerActionsLoading,
  updatePostponeTicket,
  setButtonInputClick,
  removeTypingMessage,
  removeAttchmentItem,
  updateReopenTicket,
  setWebNotification,
  setInputSubmitType,
  updateMessageData,
  setUploadFileInfo,
  attchmentVisible,
  setTypingMessage,
  selectedTransfer,
  setMessagesRead,
  getTempCodeInfo,
  removeActiveTab,
  setPostponeDate,
  setForwardError,
  setInputMessage,
  setUploadFiles,
  updateNoteData,
  scrollToBottom,
  messageSnippet,
  setNoteMessage,
  activeTabsInfo,
  getUploadLink,
  setActiveTabs,
  isSubmitting,
  footerActios,
  setInputType,
  statusTicket,
  postponeDate,
  setTransfer,
  uploadFiles,
  setEditNote,
  offsetTime,
  deviceType,
  typingInfo,
  chatInfo,
  userInfo,
}) => {
  const history = useHistory();
  const matchParams = useParams();
  const date = moment(new Date()).format('lll');
  let linkList = [];
  const handleCommonActions = (conversationid, type) => {
    setTypingMessage(conversationid, type, '');
    scrollToBottom();
    setInputType('');
    setInputSubmitType('');
    setInputMessage('');
    setNoteMessage('');
    attchmentVisible(false);
    setUploadFiles([]);
    removeAttchmentItem(conversationid);
    removeTypingMessage(conversationid);
  };

  const onCloseTab = () => {
    removeActiveTab(matchParams.conversationid, 'ticket');
    const prevTabInfo = activeTabsInfo.map(({
      messageCount, isLoading, length, ...rest
    }) => ({ ...rest }));
    const remainingTicket = prevTabInfo
      .filter((ticketInfo) => ticketInfo.key !== matchParams.conversationid);
    setWebNotification({
      notify: true, audible: true, title: 'Reply sent', timeout: 5000, audibleType: 'sent',
    });
    setActiveTabs(
      base64.encode(encodeURIComponent(JSON.stringify(remainingTicket))),
    );
    history.push('/ticket');
  };

  const fetchAttachmentInfo = (Files) => Promise.all(
    Files.map((file) => setUploadFileInfo(matchParams.conversationid, file)
      .then((res) => res.value.data[0])),
  );

  const messageSubmit = async (type, isClose) => {
    footerActionsLoading(matchParams.conversationid, true);
    if (uploadFiles.length > 0) {
      await fetchAttachmentInfo(uploadFiles)
        .then((res) => res.map((file) => linkList.push(file.linkid)));
    }
    const links = linkList ? linkList.join(',') : '';
    await api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=${type}&message=${encodeURIComponent(footerActios.inputMessage || '')}&filelinks=${links || ''}`)
      .then(async (res) => {
        if (res.data[0].response_msg === 'OK') {
          linkList = [];
          if (isClose === 'closeTab') {
            onCloseTab();
          } else {
            await updateMessageData(matchParams.conversationid,
              userInfo.firstname, date, type, res.data[0].message2, res.data[0].message_groupid);
            await setWebNotification({
              notify: true, audible: true, title: 'Reply sent', timeout: 5000, audibleType: 'sent',
            });
          }
          handleCommonActions(matchParams.conversationid, 'M');
        } else if (res.data[0].response_msg === 'FAILURE') {
          showNotification(res.data[0].response_msg, 'error', false);
        }
      }).catch((e) => {
        showNotification(`${e}`, 'error', false);
      }).finally(() => {
        footerActionsLoading(matchParams.conversationid, false);
      });
  };

  const noteSubmit = async (type, isClose) => {
    footerActionsLoading(matchParams.conversationid, true);
    if (uploadFiles.length > 0) {
      await fetchAttachmentInfo(uploadFiles)
        .then((res) => res.map((file) => linkList.push(file.linkid)));
    }
    const links = linkList ? linkList.join(',') : '';
    api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=${type}&message=${encodeURIComponent(footerActios.noteMessage || '')}&rstatus=${statusTicket}&filelinks=${links || ''}`)
      .then(async (res) => {
        if (res.data[0].response_msg === 'OK') {
          linkList = [];
          if (isClose === 'closeTab') {
            onCloseTab();
          } else {
            await updateNoteData(matchParams.conversationid, userInfo.firstname,
              date, type, res.data[0].message2, statusTicket, res.data[0].message_groupid);
            await setWebNotification({
              notify: true, audible: true, title: 'Note added', timeout: 5000, audibleType: 'sent',
            });
          }
          handleCommonActions(matchParams.conversationid, 'N');
        } else {
          showNotification(`Failed ${res.data[0].error || ''}`, 'error', false);
        }
      }).catch((e) => {
        showNotification(`${e}`, 'error', false);
      }).finally(() => {
        footerActionsLoading(matchParams.conversationid, false);
      });
  };

  const transferSubmit = async (isClose) => {
    if (isClose === 'closeTab') {
      if (uploadFiles.length > 0) {
        await fetchAttachmentInfo(uploadFiles)
          .then((res) => res.map((file) => linkList.push(file.linkid)));
      }
      const links = linkList ? linkList.join(',') : '';
      api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=T&agentname2=${selectedTransfer.value || ''}&rstatus=${statusTicket}&agentid2=${selectedTransfer.agentid || ''}&message=${encodeURIComponent(footerActios.noteMessage || '')}&filelinks=${links || ''}`);
      handleCommonActions(matchParams.conversationid, 'N');
      onCloseTab();
    } else {
      footerActionsLoading(matchParams.conversationid, true);
      if (uploadFiles.length > 0) {
        await fetchAttachmentInfo(uploadFiles)
          .then((res) => res.map((file) => linkList.push(file.linkid)));
      }
      const links = linkList ? linkList.join(',') : '';
      api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=T&agentname2=${selectedTransfer.value || ''}&rstatus=${statusTicket}&agentid2=${selectedTransfer.agentid || ''}&message=${encodeURIComponent(footerActios.noteMessage || '')}&filelinks=${links || ''}`)
        .then(async (res) => {
          if (res.data[0].response_msg === 'OK') {
            linkList = [];
            await updateTransferTicket(
              matchParams.conversationid,
              userInfo.firstname,
              selectedTransfer.agentid,
              date,
              selectedTransfer.value,
              footerActios.noteMessage,
              statusTicket,
              res.data[0].message_groupid,
            );
            await setWebNotification({
              notify: true, audible: true, title: 'Transferred successfully', timeout: 5000, audibleType: 'sent',
            });
            handleCommonActions(matchParams.conversationid, 'N');
          } else {
            showNotification(`Failed ${res.data[0].error || ''}`, 'error', false);
          }
        }).finally(() => {
          footerActionsLoading(matchParams.conversationid, false);
        });
    }
  };

  const reopenSubmit = async (type, isClose) => {
    setInputSubmitType('reopen');
    footerActionsLoading(matchParams.conversationid, true);
    if (uploadFiles.length > 0) {
      await fetchAttachmentInfo(uploadFiles)
        .then((res) => res.map((file) => linkList.push(file.linkid)));
    }
    const links = linkList ? linkList.join(',') : '';
    api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=I&message=${encodeURIComponent(footerActios.noteMessage || '')}&filelinks=${links || ''}`)
      .then(async (res) => {
        if (res.data[0].response_msg === 'OK') {
          linkList = [];
          if (isClose === 'closeTab') {
            onCloseTab();
          } else {
            await updateReopenTicket(matchParams.conversationid,
              userInfo.firstname, date, type, footerActios.noteMessage,
              res.data[0].message_groupid);
            await setWebNotification({
              notify: true, audible: true, title: 'Ticket reopened', timeout: 5000, audibleType: 'sent',
            });
          }
          handleCommonActions(matchParams.conversationid, 'N');
        } else {
          showNotification(`Failed ${res.data[0].error || ''}`, 'error', false);
        }
      }).finally(() => {
        footerActionsLoading(matchParams.conversationid, false);
      });
  };

  const postponeTicket = async (isClose) => {
    footerActionsLoading(matchParams.conversationid, true);
    if (uploadFiles.length > 0) {
      await fetchAttachmentInfo(uploadFiles)
        .then((res) => res.map((file) => linkList.push(file.linkid)));
    }
    const links = linkList ? linkList.join(',') : '';
    api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=W&rstatus=${statusTicket}&message=${encodeURIComponent(footerActios.noteMessage || '')}&datereopen=${getTimeValuePlus(postponeDate, offsetTime)}&filelinks=${links || ''}`)
      .then(async (res) => {
        if (res.data[0].response_msg === 'OK') {
          linkList = [];
          if (isClose === 'closeTab') {
            onCloseTab();
          } else {
            await updatePostponeTicket(
              matchParams.conversationid,
              userInfo.firstname,
              date,
              footerActios.noteMessage,
              getTimeValuePlus(postponeDate, offsetTime),
              statusTicket,
              res.data[0].message_groupid,
            );
            await setWebNotification({
              notify: true, audible: true, title: 'Ticket postponed', timeout: 5000, audibleType: 'sent',
            });
            await setPostponeDate(new Date());
          }
          handleCommonActions(matchParams.conversationid, 'N');
        } else {
          showNotification(`Failed ${res.data[0].error || ''}`, 'error', false);
        }
      }).finally(() => {
        footerActionsLoading(matchParams.conversationid, false);
      });
  };

  const forwardTicket = async (isClose) => {
    if (footerActios.forwardEmail) {
      const emailList = stripDownEmails(footerActios.forwardEmail);
      if (validateMultiEmails(emailList)) {
        footerActionsLoading(matchParams.conversationid, true);
        if (uploadFiles.length > 0) {
          await fetchAttachmentInfo(uploadFiles)
            .then((res) => res.map((file) => linkList.push(file.linkid)));
        }
        const links = linkList ? linkList.join(',') : '';
        api.get(`command.jsp?command=update_ticket&conversationid=${matchParams.conversationid}&status=O&message=${encodeURIComponent(footerActios.inputMessage || '')}&forward_to=${emailList}&message_groupid=${footerActios.forwardId}&filelinks=${links || ''}`)
          .then(async (res) => {
            if (res.data[0].response_msg === 'OK') {
              linkList = [];
              if (isClose === 'closeTab') {
                onCloseTab();
              } else {
                await updateMessageData(matchParams.conversationid,
                  userInfo.firstname, date, 'O', res.data[0].message2, res.data[0].message_groupid);
                await setWebNotification({
                  notify: true, audible: true, title: 'Ticket forwarded', timeout: 5000, audibleType: 'sent',
                });
              }
              await setForwardEmailInput('');
              handleCommonActions(matchParams.conversationid, 'M');
            } else {
              showNotification(`Failed ${res.data[0].error || ''}`, 'error', false);
            }
          }).finally(() => {
            footerActionsLoading(matchParams.conversationid, false);
          });
      } else {
        setForwardError(true);
      }
    } else {
      setForwardError(true);
    }
  };

  const setMessageReadable = () => {
    const newMessage = activeTabsInfo && activeTabsInfo.filter((tabItem) => tabItem.messageCount);
    if (newMessage && newMessage.length > 0) { setMessagesRead(matchParams.conversationid); }
  };

  const onSubmitHandler = (e, isClose, shortcut = false) => {
    if ((shortcut || e.detail === 1) && !isSubmitting) {
      if (footerActios.inputType === 'M' && footerActios.submitType === '' && footerActios.inputMessage) {
        messageSubmit(footerActios.inputType, isClose);
        setMessageReadable();
      } else if (footerActios.inputType === 'N' && footerActios.submitType === '' && footerActios.noteMessage) {
        noteSubmit(footerActios.inputType, isClose);
        setMessageReadable();
      } else if (footerActios.inputType === 'N' && footerActios.submitType === 'transfer') {
        transferSubmit(isClose);
        setMessageReadable();
      } else if (footerActios.inputType === 'N' && footerActios.submitType === 'reopen') {
        reopenSubmit(footerActios.inputType, isClose);
        setMessageReadable();
      } else if (footerActios.inputType === 'N' && footerActios.submitType === 'postpone') {
        postponeTicket(isClose);
        setMessageReadable();
      } else if (footerActios.inputType === 'M' && footerActios.submitType === 'forward') {
        forwardTicket(isClose);
        setMessageReadable();
      }
    }
  };

  const onCancel = async () => {
    if (footerActios.inputType && !footerActios.isLoading) {
      setEditNote('', '', false, '', '');
      await setInputType('');
      await setTypingMessage(matchParams.conversationid, footerActios.inputType, '');
      await setTransfer(false);
      await setPostponeDate(new Date());
      await attchmentVisible(false);
      await setButtonInputClick(undefined);
      await setForwardError(false);
      await setUploadFiles([]);
      await removeAttchmentItem(matchParams.conversationid);
    }
  };

  const handleChangeSnippet = (id) => {
    const snippetMessage = messageSnippet.find((snippet) => snippet.id === id);
    setInputMessage(`Hi,\n\n${snippetMessage.message}\n\nThanks,\n${userInfo.firstname || ''}`, snippetMessage.cursorLocation);
    setTypingMessage(matchParams.conversationid, 'M', `Hi,\n\n${snippetMessage.message}\n\nThanks,\n${userInfo.firstname || ''}`);
  };

  const onHandleAttchment = () => {
    if (footerActios.isAttchment) {
      attchmentVisible(false);
    } else {
      attchmentVisible(true);
      setTimeout(() => {
        const element = document.getElementById('chatsection-dropzone');
        if (element) { element.click(); }
      }, 0);
    }
  };

  const fetchGetUploadLinkInfo = () => {
    const ctl = document.getElementById('msg-text-editor');
    const startPos = ctl.selectionStart;
    getUploadLink(matchParams.code).then((res) => {
      const message = spliceString(startPos, footerActios.inputMessage, res.value.data[0].url);
      setInputMessage(message);
      setTypingMessage(matchParams.conversationid, 'M', message);
    });
  };

  const handleTempCodeInfo = () => {
    const previewInfo = chatInfo[matchParams.conversationid]
      && chatInfo[matchParams.conversationid].previewInfo;
    const ctl = document.getElementById('msg-text-editor');
    const startPos = ctl.selectionStart;
    getTempCodeInfo(previewInfo.firstname, previewInfo.emails).then((resInfo) => {
      const res = resInfo.value.data[0];
      const tampInfo = `\n-------------------------------\nName: ${res.name}\nEmail: ${res.email}\nCode: ${res.code}\nSpelled out code: ${res.code_spelled}\n${res.instructions}-------------------------------`;
      const message = spliceString(startPos, footerActios.inputMessage, tampInfo);
      setInputMessage(message);
      setTypingMessage(matchParams.conversationid, 'M', message);
    });
  };

  useHotkeys('alt+s, ctrl+s', () => onSubmitHandler('', 'closeTab', true), [footerActios, selectedTransfer]);

  return (
    <ActionsButton
      onCancel={onCancel}
      deviceType={deviceType}
      footerActios={footerActios}
      onSubmitHandler={onSubmitHandler}
      onHandleAttchment={onHandleAttchment}
      handleTempCodeInfo={handleTempCodeInfo}
      handleChangeSnippet={handleChangeSnippet}
      fetchGetUploadLinkInfo={fetchGetUploadLinkInfo}
      typingInfo={typingInfo && typingInfo[matchParams.conversationid]
        && typingInfo[matchParams.conversationid]
          .filter((typingItem) => typingItem.userid !== userInfo.agentid)}
    />
  );
};

ActionsButtonContainer.propTypes = {
  postponeDate: PropTypes.string,
  offsetTime: PropTypes.string,
  statusTicket: PropTypes.string,
  deviceType: PropTypes.string,
  isSubmitting: PropTypes.bool,
  setInputType: PropTypes.func,
  setTransfer: PropTypes.func,
  scrollToBottom: PropTypes.func,
  setInputSubmitType: PropTypes.func,
  updateMessageData: PropTypes.func,
  updateNoteData: PropTypes.func,
  updateTransferTicket: PropTypes.func,
  updateReopenTicket: PropTypes.func,
  removeActiveTab: PropTypes.func,
  setActiveTabs: PropTypes.func,
  setTypingMessage: PropTypes.func,
  updatePostponeTicket: PropTypes.func,
  setPostponeDate: PropTypes.func,
  setButtonInputClick: PropTypes.func,
  removeTypingMessage: PropTypes.func,
  setForwardEmailInput: PropTypes.func,
  setInputMessage: PropTypes.func,
  setNoteMessage: PropTypes.func,
  setForwardError: PropTypes.func,
  attchmentVisible: PropTypes.func,
  setUploadFiles: PropTypes.func,
  setUploadFileInfo: PropTypes.func,
  removeAttchmentItem: PropTypes.func,
  footerActionsLoading: PropTypes.func,
  setWebNotification: PropTypes.func,
  getUploadLink: PropTypes.func,
  getTempCodeInfo: PropTypes.func,
  setEditNote: PropTypes.func,
  setMessagesRead: PropTypes.func,
  activeTabsInfo: PropTypes.instanceOf(Array),
  uploadFiles: PropTypes.instanceOf(Array),
  messageSnippet: PropTypes.instanceOf(Array),
  footerActios: PropTypes.instanceOf(Object),
  selectedTransfer: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  typingInfo: PropTypes.instanceOf(Object),
  chatInfo: PropTypes.instanceOf(Object),
};

ActionsButtonContainer.defaultProps = {
  postponeDate: '',
  offsetTime: '',
  statusTicket: 'C',
  deviceType: '',
  isSubmitting: false,
  setInputType: noop,
  setTransfer: noop,
  scrollToBottom: noop,
  setInputSubmitType: noop,
  updateMessageData: noop,
  updateNoteData: noop,
  updateTransferTicket: noop,
  updateReopenTicket: noop,
  removeActiveTab: noop,
  setActiveTabs: noop,
  setTypingMessage: noop,
  updatePostponeTicket: noop,
  setPostponeDate: noop,
  setButtonInputClick: noop,
  removeTypingMessage: noop,
  setForwardEmailInput: noop,
  setInputMessage: noop,
  setNoteMessage: noop,
  setForwardError: noop,
  attchmentVisible: noop,
  setUploadFiles: noop,
  setUploadFileInfo: noop,
  removeAttchmentItem: noop,
  footerActionsLoading: noop,
  setWebNotification: noop,
  getUploadLink: noop,
  getTempCodeInfo: noop,
  setEditNote: noop,
  setMessagesRead: noop,
  activeTabsInfo: [],
  uploadFiles: [],
  messageSnippet: [],
  selectedTransfer: {},
  footerActios: {},
  userInfo: {},
  typingInfo: {},
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  activeTabsInfo: state.tickets.activeTabsInfo,
  offsetTime: state.auth.offsetTime,
  footerActios: state.tickets.footerView,
  messageSnippet: state.tickets.messageSnippet,
  userInfo: state.auth.userInfo,
  typingInfo: state.tickets.typingInfo,
  isSubmitting: state.tickets.isPolling,
  chatInfo: state.tickets.chat,
});

const mapDispatchToProps = (dispatch) => ({
  setEditNote: (id, msg, editMode, inputType, ref) => dispatch(
    actions.setEditNote(id, msg, editMode, inputType, ref),
  ),
  setInputType: (type) => dispatch(actions.setInputType(type)),
  attchmentVisible: (isAttchment) => dispatch(actions.attchmentVisible(isAttchment)),
  setInputSubmitType: (type) => dispatch(actions.setInputSubmitType(type)),
  setMessagesRead: (conversationid) => dispatch(actions.setMessagesRead(conversationid)),
  updatePostponeTicket: (conversationid, name, date, message, postPoneDate, rstatus,
    groupid) => dispatch(actions.updatePostponeTicket(conversationid, name, date, message,
    postPoneDate, rstatus, groupid)),
  updateMessageData: (conversationid, name, date, rtype, message, groupid) => dispatch(
    actions.updateMessageData(conversationid, name, date, rtype, message, groupid),
  ),
  updateNoteData: (conversationid, name, date, rtype, message, statusTicket, groupid) => dispatch(
    actions.updateNoteData(conversationid, name, date, rtype, message, statusTicket, groupid),
  ),
  updateReopenTicket: (conversationid, name, date, rtype, message, groupid) => dispatch(
    actions.updateReopenTicket(conversationid, name, date, rtype, message, groupid),
  ),
  updateTransferTicket: (conversationid, name, userId,
    date, userName, message, rstatus, groupid) => dispatch(actions.updateTransferTicket(
    conversationid, name, userId, date, userName, message, rstatus, groupid,
  )),
  removeActiveTab: (key, type) => dispatch(actions.removeActiveTab(key, type)),
  setActiveTabs: (tabs) => dispatch(actions.setActiveTabs(tabs)),
  setTypingMessage: (conversationid, type, message) => dispatch(
    actions.setTypingMessage(conversationid, type, message),
  ),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
  removeTypingMessage: (conversationid) => dispatch(actions.removeTypingMessage(conversationid)),
  setForwardEmailInput: (message) => dispatch(actions.setForwardEmailInput(message)),
  setInputMessage: (message) => dispatch(actions.setInputMessage(message)),
  setNoteMessage: (message) => dispatch(actions.setNoteMessage(message)),
  setUploadFileInfo: (id, file) => dispatch(actions.setUploadFileInfo(id, file)),
  removeAttchmentItem: (id) => dispatch(actions.removeAttchmentItem(id)),
  footerActionsLoading: (id, isloading) => dispatch(actions.footerActionsLoading(id, isloading)),
  setWebNotification: (webInfo) => dispatch(actions.setWebNotification(webInfo)),
  getTempCodeInfo: (name, email) => dispatch(actions.getTempCodeInfo(name, email)),
  getUploadLink: (code) => dispatch(actions.getUploadLink(code)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(ActionsButtonContainer));
