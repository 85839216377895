import base64 from 'base-64';
import moment from 'moment';

import * as actionTypes from './actionTypes';
import RequestStates from '../../../utils/request-states';
import { deepCopy, normalizeViewState2, setWebNotificationUsingrtype } from '../../../utils';

const INITIAL_STATE = {
  customSearchModal: false,
  participantsModal: false,
  isPolling: false,
  participantsState: RequestStates.init,
  createNewTicket: {},
  requestState: RequestStates.init,
  footerView: {},
  assignTicketsState: RequestStates.init,
  assignTickets: [],
  assignTicketsLength: 0,
  mainTicketScrollto: 0,
  tickets: {},
  chat: {},
  maxDateChanged: '',
  activeTabsState: RequestStates.init,
  activeTabsInfo: [],
  lastActiveTabKey: '',
  typingInfoState: RequestStates.init,
  typingInfo: {},
  viewStatusStates: RequestStates.init,
  viewStatus: {},
  messageSnippet: [],
  fileUploadInfoState: RequestStates.init,
  fileUploadInfo: {},
  fileUploadInfoError: null,
  splitTicketState: RequestStates.init,
  webNotification: {},
  groupChatInfo: {
    groupChatDialog: false,
    isLoading: false,
    groupChatId: undefined,
    groupChatCode: undefined,
    height: '98%',
    width: 300,
  },
  fetchedState: false,
};

export default (state = INITIAL_STATE, action) => {
  const payload = action.payload;
  switch (action.type) {
    case actionTypes.SET_GROUP_CHAT_LOADING:
      return {
        ...state,
        groupChatInfo: {
          ...state.groupChatInfo,
          isLoading: payload,
        },
      };
    case actionTypes.SET_EDIT_NOTE:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          editMode: payload.editMode,
          inputType: payload.inputType,
          noteMessage: payload.msg,
          editId: payload.id,
          viewInputRef: payload.ref,
        },
      };
    case actionTypes.SET_TICKET_SEARCH_VALIUE: {
      const prevMessage = deepCopy(state.chat[payload.id]);
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.id]: {
            ...prevMessage,
            searchValue: payload.value,
          },
        },
      };
    }
    case actionTypes.SET_CUSTOM_SEARCH_MODAL:
      return {
        ...state,
        customSearchModal: !state.customSearchModal,
      };
    case actionTypes.SET_NEW_TICKET_MODAL:
      return {
        ...state,
        createNewTicket: {
          ...state.createNewTicket,
          ticketType: payload.type,
          isModal: payload.isOpen,
        },
      };
    case actionTypes.CREATE_NEW_TICKET_LOADING:
      return {
        ...state,
        createNewTicket: {
          ...state.createNewTicket,
          isLoading: payload,
        },
      };
    case actionTypes.GET_TICKETS_LOADING:
      return {
        ...state,
        assignTicketsState: RequestStates.loading,
      };
    case actionTypes.GET_TICKETS_SUCCESS:
      return {
        ...state,
        assignTicketsState: RequestStates.success,
        assignTickets: payload && payload.data
          && Array.isArray(payload.data) ? payload.data : [],
      };
    case actionTypes.GET_TICKETS_ERROR:
      return {
        ...state,
        assignTicketsState: RequestStates.error,
      };
    case actionTypes.GET_SEARCH_TICKETS_LOADING:
      // Search Tickets from keyword
      return {
        ...state,
        requestState: RequestStates.loading,
      };
    case actionTypes.GET_SEARCH_TICKETS_SUCCESS:
      return {
        ...state,
        requestState: RequestStates.success,
      };
    case actionTypes.GET_SEARCH_TICKETS_ERROR:
      return {
        ...state,
        requestState: RequestStates.error,
      };
    case actionTypes.SET_SEARCHED_TICKETS: {
      const searchData = deepCopy(state.tickets[payload.key]);
      const prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const activeTabsInfo = prevActiveTabInfo.map((tabItem) => (
        tabItem.key === payload.key
          ? ({
            ...tabItem,
            length: payload.data.length,
          }) : tabItem));
      return {
        ...state,
        tickets: {
          ...state.tickets,
          [payload.key]: {
            ...searchData,
            ticketList: payload.data,
          },
        },
        activeTabsInfo,
      };
    }
    case actionTypes.SET_SEARCH_KEYWORD: {
      const _activeTickets = deepCopy(state.tickets);
      const index = payload.keyword.indexOf('-cs');
      const subject = payload.keyword.substring(0, index !== -1 ? index : index.length);
      if (payload) {
        if (!_activeTickets[payload.keyword]) {
          _activeTickets[payload.keyword] = {
            type: payload.type,
            subject,
            isLoading: true,
            scrollTo: undefined,
            ..._activeTickets[payload.keyword],
          };
        }
      }
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ..._activeTickets,
        },
      };
    }
    case actionTypes.REMOVE_ACTIVE_TAB_SUCCESS: {
      const prevTickets = deepCopy(state.tickets);
      const prevMessage = deepCopy(state.chat);
      let activeTabsInfo = deepCopy(state.activeTabsInfo);
      if (payload.type === 'ticket') {
        const findIndex = activeTabsInfo.findIndex((ticketInfo) => ticketInfo.key === payload.key);
        if ((payload.key !== state.groupChatInfo.groupChatId || findIndex === -1)) {
          delete prevMessage[payload.key];
        }
        activeTabsInfo = activeTabsInfo.filter((ticketInfo) => ticketInfo.key !== payload.key);
      } else if (payload.type === 'search') {
        delete prevTickets[payload.key];
        activeTabsInfo = activeTabsInfo.filter((ticketInfo) => ticketInfo.key !== payload.key);
      }
      return {
        ...state,
        tickets: prevTickets,
        chat: prevMessage,
        activeTabsInfo,
      };
    }
    case actionTypes.SET_LOADING_STATE_SUCCESS: {
      const prevTickets = deepCopy(state.tickets);
      return {
        ...state,
        tickets: {
          ...prevTickets,
          [payload.key]: {
            ...prevTickets[payload.key],
            isLoading: payload.isLoading,
          },
        },
      };
    }
    case actionTypes.SET_ACTIVE_TAB_LOADING_SUCCESS: {
      const copyOfActiveTabsInfo = deepCopy(state.activeTabsInfo);
      return {
        ...state,
        activeTabsInfo: copyOfActiveTabsInfo.map((tabItem) => (
          tabItem.key === payload.key
            ? ({
              ...tabItem,
              isLoading: payload.isLoading,
            }) : tabItem)),
      };
    }
    case actionTypes.UPDATE_TRANSFER_TICKET: {
      let previousChat = deepCopy(state.chat[payload.conversationid]);
      const fileUploadInfo = deepCopy(state.fileUploadInfo[payload.conversationid]);
      let prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const findIndex = previousChat && previousChat.messageInfo && previousChat.messageInfo
        .findIndex((chatItem) => chatItem.message_groupid === payload.groupid);
      if (findIndex !== -1) {
        previousChat = previousChat && previousChat.messageInfo
          .filter((chatInfo) => chatInfo.message_groupid !== payload.groupid);
        prevActiveTabInfo = prevActiveTabInfo.map((tabItem) => (
          tabItem.key === payload.conversationid
            ? ({
              ...tabItem,
              length: tabItem.length ? tabItem.length - 1 : undefined,
            }) : tabItem));
      }
      const customMessage = {
        user_full_name: payload.name,
        rtype: 'T',
        datecreated: payload.date,
        userid: payload.userId,
        isPushedRecord: true,
        message_groupid: payload.groupid,
        messages: [{
          datecreated: payload.date,
          rtype: 'T',
          message: `##assigned ticket to## ${payload.userName || ''}`,
          userid: payload.userId,
        }, {
          datecreated: payload.date,
          rtype: 'N',
          message: payload.message,
        }],
      };
      if (fileUploadInfo) {
        const fileInfoCollection = (fileItem) => {
          const uploadItem = {
            userid: fileItem.agentid,
            rtype: 'L',
            format: 'J',
            message: fileItem.linkid,
            attachment: [{
              filename: fileItem.filename,
              fileid: fileItem.fileid,
              hash: fileItem.md5_hash,
              filetype: fileItem.mimetype,
              filesize: 0,
            }],
          };
          return uploadItem;
        };
        fileUploadInfo.map((fileItem) => (
          customMessage.messages.push(fileInfoCollection(fileItem))
        ));
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...previousChat,
            previewInfo: {
              ...previousChat.previewInfo,
              agentid: payload.userId,
            },
            messageInfo: [
              ...previousChat.messageInfo,
              customMessage,
            ],
          },
        },
        prevActiveTabInfo,
      };
    }
    case actionTypes.UPDATE_MESSAGE_DATA: {
      let previousChat = deepCopy(state.chat[payload.conversationid]);
      const fileUploadInfo = deepCopy(state.fileUploadInfo[payload.conversationid]);
      let prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const findIndex = previousChat && previousChat.messageInfo && previousChat.messageInfo
        .findIndex((chatItem) => chatItem.message_groupid === payload.groupid);
      if (findIndex !== -1) {
        previousChat = previousChat && previousChat.messageInfo
          .filter((chatInfo) => chatInfo.message_groupid !== payload.groupid);
        prevActiveTabInfo = prevActiveTabInfo.map((tabItem) => (
          tabItem.key === payload.conversationid
            ? ({
              ...tabItem,
              length: tabItem.length ? tabItem.length - 1 : undefined,
            }) : tabItem));
      }
      const customMessage = {
        isPushedRecord: true,
        user_full_name: payload.name,
        rtype: payload.rtype,
        datecreated: payload.date,
        message_groupid: payload.groupid,
        messages: [{
          rtype: 'H',
          message: `##From:## ${payload.name} <support@crushftp.com>`,
        }, {
          rtype: 'M',
          message: payload.message,
          datecreated: payload.date,
        }],
      };
      if (fileUploadInfo) {
        const fileInfoCollection = (fileItem) => {
          const uploadItem = {
            userid: fileItem.agentid,
            rtype: 'F',
            format: 'J',
            message: fileItem.linkid,
            attachment: [{
              filename: fileItem.filename,
              fileid: fileItem.fileid,
              hash: fileItem.md5_hash,
              filetype: fileItem.mimetype,
              filesize: 0,
            }],
          };
          return uploadItem;
        };
        fileUploadInfo.map((fileItem) => (
          customMessage.messages.push(fileInfoCollection(fileItem))
        ));
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...previousChat,
            messageInfo: [
              ...previousChat.messageInfo,
              customMessage,
            ],
          },
        },
        prevActiveTabInfo,
      };
    }
    case actionTypes.UPDATE_NOTE_DATA: {
      let previousChat = deepCopy(state.chat[payload.conversationid]);
      const fileUploadInfo = deepCopy(state.fileUploadInfo[payload.conversationid]);
      let prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const findIndex = previousChat && previousChat.messageInfo && previousChat.messageInfo
        .findIndex((chatItem) => chatItem.message_groupid === payload.groupid);
      if (findIndex !== -1) {
        previousChat = previousChat && previousChat.messageInfo
          .filter((chatInfo) => chatInfo.message_groupid !== payload.groupid);
        prevActiveTabInfo = prevActiveTabInfo.map((tabItem) => (
          tabItem.key === payload.conversationid
            ? ({
              ...tabItem,
              length: tabItem.length ? tabItem.length - 1 : undefined,
            }) : tabItem));
      }
      const customMessage = {
        isPushedRecord: true,
        user_full_name: payload.name,
        rtype: payload.rtype,
        datecreated: payload.date,
        message_groupid: payload.groupid,
        messages: [{
          rtype: 'T',
          message: '##wrote note##',
          datecreated: payload.date,
        }, {
          rtype: payload.rtype,
          message: payload.message,
          datecreated: payload.date,
        }],
      };
      if (fileUploadInfo) {
        const fileInfoCollection = (fileItem) => {
          const uploadItem = {
            userid: fileItem.agentid,
            rtype: 'L',
            format: 'J',
            message: fileItem.linkid,
            attachment: [{
              filename: fileItem.filename,
              fileid: fileItem.fileid,
              hash: fileItem.md5_hash,
              filetype: fileItem.mimetype,
              filesize: 0,
            }],
          };
          return uploadItem;
        };
        fileUploadInfo.map((fileItem) => (
          customMessage.messages.push(fileInfoCollection(fileItem))
        ));
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...previousChat,
            previewInfo: {
              ...previousChat.previewInfo,
              rstatus: payload.rstatus,
            },
            messageInfo: [
              ...previousChat.messageInfo,
              customMessage,
            ],
          },
        },
        prevActiveTabInfo,
      };
    }
    case actionTypes.UPDATE_REOPEN_TICKET: {
      let previousChat = deepCopy(state.chat[payload.conversationid]);
      const fileUploadInfo = deepCopy(state.fileUploadInfo[payload.conversationid]);
      let prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const findIndex = previousChat && previousChat.messageInfo && previousChat.messageInfo
        .findIndex((chatItem) => chatItem.message_groupid === payload.groupid);
      if (findIndex !== -1) {
        previousChat = previousChat && previousChat.messageInfo
          .filter((chatInfo) => chatInfo.message_groupid !== payload.groupid);
        prevActiveTabInfo = prevActiveTabInfo.map((tabItem) => (
          tabItem.key === payload.conversationid
            ? ({
              ...tabItem,
              length: tabItem.length ? tabItem.length - 1 : undefined,
            }) : tabItem));
      }
      const customMessage = {
        isPushedRecord: true,
        user_full_name: payload.name,
        rtype: payload.rtype,
        datecreated: payload.date,
        message_groupid: payload.groupid,
        messages: [{
          rtype: 'T',
          message: '##wrote note## and reopened ticket',
          datecreated: payload.date,
        }, {
          rtype: payload.rtype,
          message: payload.message,
          datecreated: payload.date,
        }],
      };
      if (fileUploadInfo) {
        const fileInfoCollection = (fileItem) => {
          const uploadItem = {
            userid: fileItem.agentid,
            rtype: 'F',
            format: 'J',
            message: fileItem.linkid,
            attachment: [{
              filename: fileItem.filename,
              fileid: fileItem.fileid,
              hash: fileItem.md5_hash,
              filetype: fileItem.mimetype,
              filesize: 0,
            }],
          };
          return uploadItem;
        };
        fileUploadInfo.map((fileItem) => (
          customMessage.messages.push(fileInfoCollection(fileItem))
        ));
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...previousChat,
            previewInfo: {
              ...previousChat.previewInfo,
              rstatus: 'C',
            },
            messageInfo: [
              ...previousChat.messageInfo,
              customMessage,
            ],
          },
        },
        prevActiveTabInfo,
      };
    }
    case actionTypes.UPDATE_POSTPONE_TICKET: {
      let previousChat = deepCopy(state.chat[payload.conversationid]);
      const fileUploadInfo = deepCopy(state.fileUploadInfo[payload.conversationid]);
      let prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const findIndex = previousChat && previousChat.messageInfo && previousChat.messageInfo
        .findIndex((chatItem) => chatItem.message_groupid === payload.groupid);
      if (findIndex !== -1) {
        previousChat = previousChat && previousChat.messageInfo
          .filter((chatInfo) => chatInfo.message_groupid !== payload.groupid);
        prevActiveTabInfo = prevActiveTabInfo.map((tabItem) => (
          tabItem.key === payload.conversationid
            ? ({
              ...tabItem,
              length: tabItem.length ? tabItem.length - 1 : undefined,
            }) : tabItem));
      }
      const customMessage = {
        isPushedRecord: true,
        user_full_name: payload.name,
        rtype: 'V',
        datecreated: payload.date,
        message_groupid: payload.groupid,
        messages: [{
          rtype: 'T',
          message: `##postponed ticket till## ${payload.postPoneDate}`,
          datecreated: payload.date,
        }, {
          rtype: 'N',
          message: payload.message,
          datecreated: payload.date,
        }],
      };
      if (fileUploadInfo) {
        const fileInfoCollection = (fileItem) => {
          const uploadItem = {
            userid: fileItem.agentid,
            rtype: 'L',
            format: 'J',
            message: fileItem.linkid,
            attachment: [{
              filename: fileItem.filename,
              fileid: fileItem.fileid,
              hash: fileItem.md5_hash,
              filetype: fileItem.mimetype,
              filesize: 0,
            }],
          };
          return uploadItem;
        };
        fileUploadInfo.map((fileItem) => (
          customMessage.messages.push(fileInfoCollection(fileItem))
        ));
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...previousChat,
            messageInfo: [
              ...previousChat.messageInfo,
              customMessage,
            ],
          },
        },
        prevActiveTabInfo,
      };
    }
    case actionTypes.UPDATE_RESOLVED_TICKET: {
      let previousChat = deepCopy(state.chat[payload.conversationid]);
      let prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const findIndex = previousChat && previousChat.messageInfo && previousChat.messageInfo
        .findIndex((chatItem) => chatItem.message_groupid === payload.groupid);
      if (findIndex !== -1) {
        previousChat = previousChat && previousChat.messageInfo
          .filter((chatInfo) => chatInfo.message_groupid !== payload.groupid);
        prevActiveTabInfo = prevActiveTabInfo.map((tabItem) => (
          tabItem.key === payload.conversationid
            ? ({
              ...tabItem,
              length: tabItem.length ? tabItem.length - 1 : undefined,
            }) : tabItem));
      }
      const customMessage = {
        isPushedRecord: true,
        user_full_name: payload.firstname,
        datecreated: payload.date,
        message_groupid: payload.groupid,
        rtype: 'R',
        messageData: [{
          rtype: 'T',
          message: '',
          datecreated: payload.date,
        }],
      };
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...previousChat,
            previewInfo: {
              ...previousChat.previewInfo,
              rstatus: 'R',
            },
            messageInfo: [
              ...previousChat.messageInfo,
              customMessage,
            ],
          },
        },
        prevActiveTabInfo,
      };
    }
    case actionTypes.SET_INPUT_TYPE: {
      return {
        ...state,
        footerView: {
          ...state.footerView,
          inputType: payload,
        },
      };
    }
    case actionTypes.SET_INPUT_SUBMIT_TYPE:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          submitType: payload,
        },
      };
    case actionTypes.SET_BUTTON_INPUT_CLICK:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          viewInputRef: payload,
        },
      };
    case actionTypes.GET_ACTIVE_TABS_LOADING:
      return {
        ...state,
        activeTabsState: RequestStates.loading,
      };
    case actionTypes.GET_ACTIVE_TABS_SUCCESS: {
      let activeTabsInfo = [];
      let fetchedState = false;
      if (payload.data.saved_state) {
        activeTabsInfo = JSON.parse(decodeURIComponent(base64.decode(payload.data.saved_state)));
        fetchedState = true;
        if (activeTabsInfo && activeTabsInfo.length > 0) {
          activeTabsInfo = activeTabsInfo.map((tabItem) => {
            if (tabItem.type === 'search') {
              if (tabItem.dateEnd === '') {
                const startDate = moment(tabItem.dateStart).add(1, 'days').format('YYYY-MM-DD');
                const key = (tabItem.subject || '') + (tabItem.status || '') + (tabItem.agentid ? (tabItem.agentid.replace(/,/g, '') || '') : '') + (tabItem.unassigned || '') + (tabItem.invert || '') + (startDate || '') + (tabItem.dateEnd || '');
                return {
                  ...tabItem,
                  dateStart: startDate,
                  key,
                };
              }
              return tabItem;
            }
            return tabItem;
          });
        }
      }
      return {
        ...state,
        activeTabsState: RequestStates.success,
        activeTabsInfo,
        fetchedState,
      };
    }
    case actionTypes.GET_ACTIVE_TABS_ERROR:
      return {
        ...state,
        activeTabsState: RequestStates.error,
        fetchedState: false,
      };
    case actionTypes.PUSH_ACTIVE_TABS: {
      let tabsInfo = deepCopy(state.activeTabsInfo);
      const filteredtabs = tabsInfo.filter((item) => item.key !== payload.key);
      if (payload.type === 'ticket') {
        tabsInfo = [
          ...filteredtabs,
          {
            type: payload.type,
            key: payload.key,
            subject: payload.subject,
            code: payload.code,
            conversationid: payload.conversationid,
          },
        ];
      } else if (payload.type === 'search') {
        tabsInfo = [
          ...filteredtabs,
          {
            type: payload.type,
            key: payload.key,
            subject: payload.subject,
            status: payload.status,
            agentid: payload.agentid,
            unassigned: payload.unassigned,
            invert: payload.invert,
            dateStart: payload.dateStart,
            dateEnd: payload.dateEnd,
            meta: payload.meta,
            length: payload.length,
            lastDays: payload.lastDays,
            tabName: payload.tabName,
          },
        ];
      }
      return {
        ...state,
        activeTabsInfo: tabsInfo,
      };
    }
    case actionTypes.SET_MAIN_TICKET_SCROLL:
      return {
        ...state,
        mainTicketScrollto: payload,
      };
    case actionTypes.GET_TYPING_MESSAGE_LOADING:
      return {
        ...state,
        typingInfoState: RequestStates.loading,
      };
    case actionTypes.GET_TYPING_MESSAGE_SUCCESS: {
      if (payload.data.length === 0) {
        return {
          ...state,
          typingInfoState: RequestStates.success,
          typingInfo: {
            ...state.typingInfo,
            [action.meta]: [],
          },
        };
      }
      return {
        ...state,
        typingInfoState: RequestStates.success,
        typingInfo: {
          ...state.typingInfo,
          [payload.data[0].conversationid]: [...payload.data],
        },
      };
    }
    case actionTypes.GET_TYPING_MESSAGE_ERROR:
      return {
        ...state,
        typingInfoState: RequestStates.error,
      };
    case actionTypes.REMOVE_TYPING_MESSAGE: {
      const typingMsg = deepCopy(state.typingInfo);
      if (payload) {
        delete typingMsg[payload];
      }
      return {
        ...state,
        typingInfo: {
          ...typingMsg,
        },
      };
    }
    // New reducer actions
    case actionTypes.SET_TICKET_LOADING: {
      const preMessageInfo = deepCopy(state.chat[payload.key]);
      const copyOfActiveTabsInfo = deepCopy(state.activeTabsInfo);
      if (payload.type === 'P') {
        return {
          ...state,
          chat: {
            ...state.chat,
            [payload.key]: {
              ...preMessageInfo,
              previewLoading: payload.isLoading,
            },
          },
        };
      } if (payload.type === 'M') {
        return {
          ...state,
          chat: {
            ...state.chat,
            [payload.key]: {
              ...preMessageInfo,
              messageLoading: payload.isLoading,
            },
          },
          activeTabsInfo: copyOfActiveTabsInfo.map((tabItem) => (
            tabItem.conversationid === payload.key
              ? ({
                ...tabItem,
                isLoading: payload.isLoading,
              }) : tabItem)),
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.SET_CHAT_INFO_LIST_LOADING:
      return {
        ...state,
      };
    case actionTypes.SET_CHAT_INFO_LIST_SUCCESS: {
      const prevMessage = deepCopy(state.chat[payload.id]);
      const prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const newMessages = [];
      let webNotification = {};
      const isTabAvailable = prevActiveTabInfo.find((tab) => tab.conversationid === payload.id);
      if (!state.isPolling && ((isTabAvailable && Object.keys(isTabAvailable).length > 0)
        || (payload.id === state.groupChatInfo.groupChatId
          && prevMessage && prevMessage.messageInfo))) {
        if (prevMessage && prevMessage.messageInfo && prevMessage.messageInfo.length > 0) {
          payload.message.map((messageItem) => {
            const filterData = prevMessage.messageInfo.find((prevItem) => prevItem
              .message_groupid === messageItem.message_groupid);
            return filterData && Object.keys(filterData).length > 0
              ? newMessages.push(filterData)
              : newMessages.push({ ...messageItem, newMessage: true });
          });
          const notifyInfoData = newMessages.map((msgData) => (msgData.newMessage
            ? { ...msgData, notify: msgData.notify ? msgData.notify + 1 : 1 }
            : msgData));
          const newFilterMessage = notifyInfoData.filter((msgItem) => msgItem.newMessage);
          const count = newFilterMessage.length;
          if (count !== 0) {
            const newMsgInfo = newFilterMessage[count - 1];
            const filterTab = prevActiveTabInfo && prevActiveTabInfo.filter((tabItem) => tabItem.type === 'ticket');
            const tabInfo = filterTab && filterTab
              .find((tabItem) => tabItem.conversationid === payload.id);
            let code = '';
            if (tabInfo) {
              code = tabInfo.code;
            } else if (payload.id === state.groupChatInfo.groupChatId) {
              code = state.groupChatInfo.groupChatCode;
            }
            if (newMsgInfo.notify === 1) {
              if (state.groupChatInfo.groupChatId
                && state.groupChatInfo.groupChatId === newMsgInfo.conversationid) {
                webNotification = setWebNotificationUsingrtype({
                  rtype: newMsgInfo.rtype,
                  title: newMsgInfo.messages[1].message || '',
                  info: newMsgInfo,
                  code: newMsgInfo.firstname,
                  groupChat: 'Yes',
                });
              } else {
                webNotification = setWebNotificationUsingrtype({
                  rtype: newMsgInfo.rtype,
                  title: newMsgInfo.firstname || newMsgInfo.emails,
                  info: newMsgInfo,
                  code,
                });
              }
            }
          }
          const activeTabsInfo = prevActiveTabInfo.map((tabItem) => (
            tabItem.conversationid === payload.id
              ? ({
                ...tabItem,
                messageCount: count === 0 ? undefined : count,
              }) : tabItem));
          return {
            ...state,
            chat: {
              ...state.chat,
              [payload.id]: {
                ...prevMessage,
                messageInfo: notifyInfoData,
              },
            },
            webNotification,
            activeTabsInfo,
          };
        } if (!prevMessage || !prevMessage.messageInfo) {
          return {
            ...state,
            chat: {
              ...state.chat,
              [payload.id]: {
                ...prevMessage,
                messageLoading: false,
                messageInfo: payload.message,
              },
            },
          };
        } if (prevMessage && prevMessage.messageInfo && prevMessage.messageInfo.length === 0) {
          return {
            ...state,
            chat: {
              ...state.chat,
              [payload.id]: {
                ...prevMessage,
                messageLoading: false,
                messageInfo: payload.message,
              },
            },
          };
        }
      } if (!prevMessage || !prevMessage.messageInfo) {
        return {
          ...state,
          chat: {
            ...state.chat,
            [payload.id]: {
              ...prevMessage,
              messageLoading: false,
              messageInfo: payload.message,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.SET_CHAT_INFO_LIST_ERROR:
      return {
        ...state,
      };
    case actionTypes.SET_MESSAGES_READ: {
      const prevMessage = deepCopy(state.chat[payload.id]);
      const prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      let messageGroup = [];
      let activetabs = [];
      if (prevMessage) {
        messageGroup = prevMessage.messageInfo.map((messageItem) => (messageItem
          .newMessage ? ({ ...messageItem, newMessage: false }) : messageItem));
      }
      if (prevActiveTabInfo) {
        activetabs = prevActiveTabInfo.map((tabItem) => (tabItem.conversationid === payload
          .id ? ({ ...tabItem, messageCount: undefined }) : tabItem));
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.id]: {
            ...prevMessage,
            messageInfo: [
              ...messageGroup,
            ],
          },
        },
        activeTabsInfo: activetabs,
      };
    }
    case actionTypes.GET_CHAT_PREVIEW_INFO_LOADING:
      return {
        ...state,
      };
    case actionTypes.GET_CHAT_PREVIEW_INFO_SUCCESS: {
      const conversationid = payload && payload.data[0] && payload.data[0].conversationid;
      const prevMessage = deepCopy(state.chat[conversationid]);
      if (prevMessage) {
        return {
          ...state,
          chat: {
            ...state.chat,
            [conversationid]: {
              ...prevMessage,
              previewLoading: false,
              previewInfo: payload.data[0],
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.GET_CHAT_PREVIEW_INFO_ERROR:
      return {
        ...state,
      };
    case actionTypes.SET_SCROLL_POSITION: {
      if (payload.type === 'ticket') {
        const prevMessageInfo = deepCopy(state.chat[payload.key]);
        return {
          ...state,
          chat: {
            ...state.chat,
            [payload.key]: {
              ...prevMessageInfo,
              scrollTo: payload.position,
            },
          },
        };
      } if (payload.type === 'search') {
        const prevTicketInfo = deepCopy(state.tickets[payload.key]);
        return {
          ...state,
          tickets: {
            ...state.tickets,
            [payload.key]: {
              ...prevTicketInfo,
              scrollTo: payload.position,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.SET_MAX_DATE:
      return {
        ...state,
        maxDateChanged: payload,
      };
    case actionTypes.SET_VIEW_STATUS_AGENT_LOADING:
      return {
        ...state,
        viewStatusStates: RequestStates.loading,
      };
    case actionTypes.SET_VIEW_STATUS_AGENT_SUCCESS:
      return {
        ...state,
        viewStatusStates: RequestStates.success,
        // viewStatus: normalizeViewState2(payload.data),
      };
    case actionTypes.SET_VIEW_STATUS_AGENT_ERROR:
      return {
        ...state,
        viewStatusStates: RequestStates.error,
      };
    case actionTypes.GET_VIEW_STATUS_OF_AGENT_LOADING:
      return {
        ...state,
        viewStatusStates: RequestStates.loading,
      };
    case actionTypes.GET_VIEW_STATUS_OF_AGENT_SUCCESS:
      return {
        ...state,
        viewStatusStates: RequestStates.success,
        viewStatus: normalizeViewState2(payload.data),
      };
    case actionTypes.GET_VIEW_STATUS_OF_AGENT_ERROR:
      return {
        ...state,
        viewStatusStates: RequestStates.error,
      };
    case actionTypes.SET_FORWARD_EMAIL_INPUT:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          forwardEmail: payload,
        },
      };
    case actionTypes.SET_INPUT_MESSAGE:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          inputMessage: payload.message,
          cursorLocation: payload.cursorLocation,
        },
      };
    case actionTypes.SET_NOTE_MESSAGE:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          noteMessage: payload,
        },
      };
    case actionTypes.SET_MESSAGE_SNIPPET:
      return {
        ...state,
        messageSnippet: payload,
      };
    case actionTypes.SET_TAB_TITLE: {
      const prevActiveTabInfo = deepCopy(state.activeTabsInfo);
      const activeTabsInfo = prevActiveTabInfo.map((ticketInfo) => (ticketInfo.key === payload.key
        ? ({ ...ticketInfo, tabName: payload.title }) : ticketInfo));
      return {
        ...state,
        activeTabsInfo,
      };
    }
    case actionTypes.SET_UPLOAD_FILE_INFO_LOADING:
      return {
        ...state,
        fileUploadInfoState: RequestStates.loading,
      };
    case actionTypes.SET_UPLOAD_FILE_INFO_SUCCESS: {
      const prevfileUploadInfo = deepCopy(state.fileUploadInfo[action.meta]);
      let fileInfo = [];
      if (prevfileUploadInfo) {
        prevfileUploadInfo.push(...payload.data);
        fileInfo = prevfileUploadInfo;
      } else {
        fileInfo.push(...payload.data);
      }
      return {
        ...state,
        fileUploadInfoState: RequestStates.success,
        fileUploadInfo: {
          ...state.fileUploadInfo,
          [action.meta]: fileInfo,
        },
      };
    }
    case actionTypes.SET_UPLOAD_FILE_INFO_ERROR:
      return {
        ...state,
        fileUploadInfoState: RequestStates.error,
        fileUploadInfoError: payload,
      };
    case actionTypes.ATTCHMENT_VISIBLE:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          isAttchment: payload,
        },
      };
    case actionTypes.REMOVE_ATTCHMENT_ITEM: {
      const prevfileUploadInfo = deepCopy(state.fileUploadInfo[payload.id]);
      if (prevfileUploadInfo) {
        return {
          ...state,
          fileUploadInfo: delete prevfileUploadInfo[payload.id],
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.FOOTER_ACTIONS_LOADING: {
      const prevChatInfo = deepCopy(state.chat[payload.id]);
      return {
        ...state,
        isPolling: payload.isLoading,
        chat: {
          ...state.chat,
          [payload.id]: {
            ...prevChatInfo,
            submitLoader: payload.isLoading,
          },
        },
      };
    }
    case actionTypes.SET_FORWARD_MESSAGE_INFO:
      return {
        ...state,
        footerView: {
          ...state.footerView,
          forwardId: payload.id,
          forwardEmail: payload.email,
        },
      };
    case actionTypes.GET_PARTICIPANTS_LOADING:
      return {
        ...state,
        participantsState: RequestStates.loading,
      };
    case actionTypes.GET_PARTICIPANTS_SUCCESS: {
      const prevChatInfo = deepCopy(state.chat[action.meta]);
      return {
        ...state,
        participantsState: RequestStates.success,
        chat: {
          ...state.chat,
          [action.meta]: {
            ...prevChatInfo,
            participants: payload.data,
          },
        },
      };
    }
    case actionTypes.GET_PARTICIPANTS_ERROR:
      return {
        ...state,
        participantsState: RequestStates.error,
      };
    case actionTypes.SET_PARTICIPANTS_LOADING:
      return {
        ...state,
        participantsState: RequestStates.loading,
      };
    case actionTypes.SET_PARTICIPANTS_SUCCESS: {
      const prevChatInfo = deepCopy(state.chat[action.meta.cid]);
      const participants = [];
      if (payload.data.response_msg === 'Success') {
        if (action.meta.To) {
          const ToList = action.meta.To.split(',');
          ToList.map((toItem) => participants.push({ role: 'To', emails: toItem }));
        }
        if (action.meta.CC) {
          const CCList = action.meta.CC.split(',');
          CCList.map((ccItem) => participants.push({ role: 'CC', emails: ccItem }));
        }
        if (action.meta.BCC) {
          const BCCList = action.meta.BCC.split(',');
          BCCList.map((bccItem) => participants.push({ role: 'BCC', emails: bccItem }));
        }
        return {
          ...state,
          participantsState: RequestStates.success,
          chat: {
            ...state.chat,
            [action.meta.cid]: {
              ...prevChatInfo,
              participants,
            },
          },
        };
      }
      return {
        ...state,
        participantsState: RequestStates.success,
      };
    }
    case actionTypes.SET_PARTICIPANTS_ERROR:
      return {
        ...state,
        participantsState: RequestStates.error,
      };
    case actionTypes.SET_SPLIT_TICKET_LOADING:
      return {
        ...state,
        splitTicketState: RequestStates.loading,
      };
    case actionTypes.SET_SPLIT_TICKET_SUCCESS: {
      return {
        ...state,
        splitTicketState: RequestStates.success,
      };
    }
    case actionTypes.SET_SPLIT_TICKET_ERROR:
      return {
        ...state,
        splitTicketState: RequestStates.error,
      };
    case actionTypes.SET_PARTICIPANTS_MODAL:
      return {
        ...state,
        participantsModal: payload,
      };
    case actionTypes.SET_WEB_NOTIFICATION:
      return {
        ...state,
        webNotification: {
          notify: payload.notify,
          audible: payload.audible,
          title: payload.title,
          timeout: payload.timeout,
          audibleType: payload.audibleType,
        },
      };
    case actionTypes.SET_PREV_ACTIVE_TAB_INFO:
      return {
        ...state,
        lastActiveTabKey: payload,
      };
    case actionTypes.SAVE_ASSIGN_TICKETS_LENGTH:
      return {
        ...state,
        assignTicketsLength: payload,
      };
    case actionTypes.SET_GROUP_CHAT_INFO: {
      const prevChatInfo = deepCopy(state.chat[payload.conversationid]);
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...prevChatInfo,
            messageInfo: payload.messages,
          },
        },
      };
    }
    case actionTypes.PUSHED_GROUP_CHAT_MESSAGE: {
      let prevGroupChatMessage = deepCopy(state.chat[payload.conversationid]);
      const fileUploadInfo = deepCopy(state.fileUploadInfo[payload.conversationid]);
      const findIndex = prevGroupChatMessage && prevGroupChatMessage.messageInfo
        && prevGroupChatMessage.messageInfo
          .findIndex((chatItem) => chatItem.message_groupid === payload.groupid);
      if (findIndex !== -1) {
        prevGroupChatMessage = prevGroupChatMessage && prevGroupChatMessage.messageInfo
          .filter((chatInfo) => chatInfo.message_groupid !== payload.groupid);
      }
      const customMessage = {
        firstname: payload.name,
        rtype: 'N',
        isPushedRecord: true,
        datecreated: payload.date,
        message_groupid: payload.groupid,
        userid: payload.userid,
        messages: [{
          rtype: 'T',
          message: '##wrote note##',
          datecreated: payload.date,
        }, {
          rtype: 'N',
          message: payload.message,
          datecreated: payload.date,
        }],
      };
      if (fileUploadInfo) {
        const fileInfoCollection = (fileItem) => {
          const uploadItem = {
            userid: fileItem.agentid,
            rtype: 'F',
            format: 'J',
            message: fileItem.linkid,
            attachment: [{
              filename: fileItem.filename,
              fileid: fileItem.fileid,
              hash: fileItem.md5_hash,
              filetype: fileItem.mimetype,
              filesize: 0,
            }],
          };
          return uploadItem;
        };
        fileUploadInfo.map((fileItem) => (
          customMessage.messages.push(fileInfoCollection(fileItem))
        ));
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...prevGroupChatMessage,
            messageInfo: [
              ...prevGroupChatMessage.messageInfo,
              customMessage,
            ],
          },
        },
      };
    }
    case actionTypes.PUSH_ACTIVE_TAB_LIST:
      return {
        ...state,
        activeTabsInfo: payload,
      };
    case actionTypes.CHANGE_RENDER_MESSAGE_TYPE: {
      const prevChatInfo = deepCopy(state.chat[payload.conversationid]);
      const filterSpeicifcMsg = prevChatInfo.messageInfo.findIndex(
        (chatItem) => chatItem.message_groupid === payload.message_groupid,
      );
      const messageList = prevChatInfo.messageInfo[filterSpeicifcMsg];
      const Message = messageList.messages.map((msgItem) => (msgItem.rtype === 'M' ? { ...msgItem, format: msgItem.format === 'H' ? 'T' : 'H' } : msgItem));
      messageList.messages = [];
      messageList.messages = Message;
      prevChatInfo.messageInfo[filterSpeicifcMsg] = messageList;
      const messageInfo = prevChatInfo.messageInfo;
      return {
        ...state,
        chat: {
          ...state.chat,
          [payload.conversationid]: {
            ...prevChatInfo,
            messageInfo,
          },
        },
      };
    }
    // case actionTypes.REMOVE_CHAT_OBJECT: {
    //   const prevChat = deepCopy(state.chat);
    //   const prevActiveTabInfo = deepCopy(state.activeTabsInfo);
    //   const ticketPrevTab = prevActiveTabInfo &&
    // prevActiveTabInfo.filter((tab) => tab.type === 'ticket');
    //   let filteredObj = {};
    //   if (ticketPrevTab) {
    //     filteredObj = Object.keys(prevChat).reduce((acc, cur) => (
    //       eslint-disable-next-line no-nested-ternary
    //       state.groupChatInfo.groupChatId !== cur
    //         ? ticketPrevTab.findIndex((item) => item.conversationid === cur) > -1
    //           ? ({ ...acc, [cur]: prevChat[cur] })
    //           : acc
    //         : ({ ...acc, [cur]: prevChat[cur] })), {});
    //   }
    //   return {
    //     ...state,
    //     chat: filteredObj,
    //   };
    // }
    // ********** groupChatInfo **********
    case actionTypes.SET_GROUP_CHAT_INPUT_VALIUE:
      return {
        ...state,
        groupChatInfo: {
          ...state.groupChatInfo,
          inputValue: payload,
        },
      };
    case actionTypes.SET_GROUP_CHAT_WIDTH:
      return {
        ...state,
        groupChatInfo: {
          ...state.groupChatInfo,
          width: payload,
        },
      };
    case actionTypes.SET_GROUP_CHAT_DIALOG:
      return {
        ...state,
        groupChatInfo: {
          ...state.groupChatInfo,
          groupChatDialog: payload,
        },
      };
    case actionTypes.SET_GROUP_CHAT_ID:
      return {
        ...state,
        groupChatInfo: {
          ...state.groupChatInfo,
          groupChatId: payload.id,
          groupChatCode: payload.code,
        },
      };
    default:
      return state;
  }
};
