import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CreateTicketDialog from './CreateTicketDialog';
import { actions } from '../../../../modules/tickets';
import { noop } from '../../../../utils';

import './create_ticket_dialog.scss';

const CreateTicketDialogContainer = ({
  setNewTicketModal,
  createNewTicket,
}) => {
  const handleModalShow = () => {
    setNewTicketModal(false, undefined);
  };

  return (
    <CreateTicketDialog
      handleModalShow={handleModalShow}
      newTicketModal={createNewTicket.isModal}
      isLoading={createNewTicket.isLoading}
      ticketType={createNewTicket.ticketType}
    />
  );
};

CreateTicketDialogContainer.propTypes = {
  setNewTicketModal: PropTypes.func,
  createNewTicket: PropTypes.instanceOf(Object),
};

CreateTicketDialogContainer.defaultProps = {
  setNewTicketModal: noop,
  createNewTicket: {},
};

const mapStateToProps = (state) => ({
  createNewTicket: state.tickets.createNewTicket,
});

const mapDispatchToProps = (dispatch) => ({
  setNewTicketModal: (isOpen, type) => dispatch(actions.setNewTicketModal(isOpen, type)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(CreateTicketDialogContainer);
