import { toast } from 'react-toastify';

const curMsgs = {};
export const showNotification = (message, type, timeOut, position) => {
  if (curMsgs[message]) {
    toast.update(curMsgs[message], {
      type, // allowed types ["info","success","warning","error","default"]
      autoClose: timeOut,
      hideProgressBar: false,
      position,
      draggable: false,
      closeOnClick: false,
      onClose: () => {
        delete curMsgs[message];
      },
    });
    return curMsgs[message];
  }
  curMsgs[message] = toast(`${message}`, {
    type, // allowed types ["info","success","warning","error","default"]
    autoClose: timeOut,
    hideProgressBar: false,
    position,
    draggable: false,
    closeOnClick: false,
    onClose: () => {
      delete curMsgs[message];
    },
  });
  return curMsgs[message];
};

export const dismissNotification = (toastId) => toast.dismiss(toastId);
