/* eslint-disable react/jsx-props-no-spreading */
/*eslint-disable */
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SpinnerLoader } from '../shared/components/Loader';
import { actions } from '../modules/authForms';
import { noop } from '../utils';

export default (ComposedComponent) => {
  class Redirect extends Component {
    constructor(props) {
      super(props);
      this.state = {
        pageAccessible: true,
      };
    }

    componentWillMount() {
      const {
        getLoginStatus,
        history,
      } = this.props;
        getLoginStatus()
        .then((res) => {
          if (res.value.data.response_msg === 'LOGGED_IN') {
            history.push('/ticket'); 
            this.setState({ pageAccessible: false });
            } else {
              history.push('/signin')
              this.setState({ pageAccessible: false });
            }
          });
    }

    render() {
      const { pageAccessible } = this.state;
      return (
        <BlockUi
          tag="div"
          blocking={pageAccessible}
          loader={<SpinnerLoader />}
          renderChildren={false}
          className="full_height full_width block-ui-background"
        >
          <ComposedComponent {...this.props} />
        </BlockUi>
      );
    }
  }

  Redirect.propTypes = {
    getLoginStatus: PropTypes.func,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  Redirect.defaultProps = {
    getLoginStatus: noop,
  };

  const mapDispatchToProps = (dispatch) => ({
    getLoginStatus: () => dispatch(actions.getLoginStatus()),
  });

  return connect(null, mapDispatchToProps)(withRouter(Redirect));
};
