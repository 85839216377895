import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FaStar } from 'react-icons/fa';
import { MdStarBorder } from 'react-icons/md';

import * as localInfoActions from '../../TodoRedux/actions';
import { noop } from '../../../utils';

import './flag_start.scss';

const FlagStar = React.memo(({
  conversationid,
  classNames,
  setFlags,
  flags,
}) => {
  const star = flags[conversationid];
  const localflags = localStorage.getItem('flags');

  const setLocalFlag = (id) => {
    if (localflags) {
      localStorage.setItem('flags', JSON.stringify({ ...JSON.parse(localflags), [conversationid]: id }));
    } else {
      localStorage.setItem('flags', JSON.stringify({ [conversationid]: id }));
    }
  };

  const handleStarChange = (e) => {
    e.stopPropagation();
    if (!star || star === 1) {
      setFlags({ id: conversationid, status: 2, type: 'update' });
      setLocalFlag(2);
    } else if (star === 2) {
      setFlags({ id: conversationid, status: 3, type: 'update' });
      setLocalFlag(3);
    } else if (star === 3) {
      setFlags({ id: conversationid, status: 4, type: 'update' });
      setLocalFlag(4);
    } else if (star === 4) {
      setFlags({ id: conversationid, status: 1, type: 'remove' });
      setLocalFlag(1);
      const flagList = JSON.parse(localflags);
      delete flagList[conversationid];
      localStorage.setItem('flags', JSON.stringify(flagList));
    }
  };

  return (
    <span id={conversationid} onClick={handleStarChange} className={`flag_section ${classNames}`} role="presentation">
      {!star || star === 1
        ? <MdStarBorder className="star" />
        : <FaStar className={classnames('star', { yellow: star === 2 }, { red: star === 3 }, { green: star === 4 })} />}
    </span>
  );
});

FlagStar.propTypes = {
  conversationid: PropTypes.string,
  classNames: PropTypes.string,
  setFlags: PropTypes.func,
  flags: PropTypes.instanceOf(Object),
};

FlagStar.defaultProps = {
  conversationid: '',
  classNames: '',
  setFlags: noop,
  flags: {},
};

const mapStateToProps = (state) => ({
  flags: state.localInfo.flags,
});

const mapDispatchToProps = (dispatch) => ({
  setFlags: (data) => dispatch(localInfoActions.setFlags(data)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(FlagStar);
