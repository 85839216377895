import React from 'react';
import PropTypes from 'prop-types';
import Viewer from 'react-viewer';

import { noop } from '../../../utils';

const ImageLightBox = ({
  activeIndex,
  setVisible,
  visible,
  images,
}) => (
  <Viewer
    images={images}
    visible={visible}
    onClose={setVisible}
    activeIndex={activeIndex}
    onMaskClick={setVisible}
    drag
  />
);

ImageLightBox.propTypes = {
  activeIndex: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  images: PropTypes.instanceOf(Array),
};

ImageLightBox.defaultProps = {
  activeIndex: 0,
  visible: false,
  setVisible: noop,
  images: [],
};

export default ImageLightBox;
