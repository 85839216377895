import React from 'react';
import { Link } from 'react-router-dom';

import './not_found.scss';

const NotFound = () => (
  <div className="not_found_section">
    <div className="not_found_container">
      <span>404</span>
      <p>This is not the page you are looking for</p>
      <Link to="/">Go to home page</Link>
    </div>
  </div>
);

export default NotFound;
