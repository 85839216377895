import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';

import { RenderInput, RenderButton } from '../../../FormControl';
import { noop } from '../../../../../utils';

const ExternalTicketForm = ({
  setToEmailRef,
  handleSubmit,
  handleEmail,
  bccemail,
  onSubmit,
  ccemail,
}) => (
  <Form className="external_ticket_form" onSubmit={handleSubmit(onSubmit)}>
    <div className="ticket_input_wrapper">
      <p className="input_label fixed_width">To: </p>
      <Field
        name="To"
        id="To"
        placeholder="To"
        type="text"
        component={RenderInput}
        className="create_ticket_input"
        innerRef={setToEmailRef}
      />
      <p
        onClick={() => handleEmail('CC')}
        role="presentation"
        className="input_label"
      >
        CC
      </p>
      <p
        onClick={() => handleEmail('BCC')}
        role="presentation"
        className="input_label"
      >
        BCC
      </p>
    </div>
    {ccemail && (
      <div className="ticket_input_wrapper">
        <p className="input_label fixed_width">CC: </p>
        <Field
          name="CC"
          id="CC"
          placeholder="CC"
          type="text"
          component={RenderInput}
          className="create_ticket_input"
        />
      </div>
    )}
    {bccemail && (
      <div className="ticket_input_wrapper">
        <p className="input_label fixed_width">BCC: </p>
        <Field
          name="BCC"
          id="BCC"
          placeholder="BCC"
          type="text"
          component={RenderInput}
          className="create_ticket_input"
        />
      </div>
    )}
    <div className="ticket_input_wrapper">
      <p className="input_label fixed_width">Subject: </p>
      <Field
        name="Subject"
        id="Subject"
        placeholder="Subject"
        type="text"
        component={RenderInput}
        className="create_ticket_input"
      />
    </div>
    <RenderButton className="create_ticket_submit">
      Build
    </RenderButton>
  </Form>
);

ExternalTicketForm.propTypes = {
  bccemail: PropTypes.bool,
  ccemail: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleEmail: PropTypes.func,
  setToEmailRef: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

ExternalTicketForm.defaultProps = {
  bccemail: false,
  ccemail: false,
  onSubmit: noop,
  handleEmail: noop,
  setToEmailRef: noop,
};

export default reduxForm({
  form: 'newExternalTicketForm',
})(ExternalTicketForm);
