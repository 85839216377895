/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import ChatContentSection from './ChatContentSection';
import { groupChatDateTime } from '../../../../../utils';

const ChatContentSectionContainer = React.memo(({
  groupChatInfo,
  offsetTime,
  chatInfo,
  userInfo,
}) => {
  let prevDate = '';
  let prevName = '';
  let currentTimeCount = 0;

  const fetchDateChanged = (messages, pushRecord, dateinfo, firstname) => {
    if (currentTimeCount === 0) {
      const data = messages.filter((msgItem) => moment(msgItem.datecreated).format('YYYY-DD-MM HH:mm') === moment(dateinfo).format('YYYY-DD-MM HH:mm'));
      currentTimeCount = data.length;
    }
    let date = '';
    let name = '';
    let time = '';
    let datestamp = '';
    if (pushRecord && dateinfo) {
      datestamp = groupChatDateTime(dateinfo, offsetTime, 'plus');
    } else {
      datestamp = groupChatDateTime(dateinfo, offsetTime, 'minus');
    }
    if (prevName !== firstname) {
      prevName = firstname;
      name = firstname;
    }
    if (prevDate !== datestamp.date) {
      prevDate = datestamp.date;
      date = datestamp.date;
      name = firstname;
    }
    if (currentTimeCount === 1) {
      time = datestamp.time;
      currentTimeCount -= 1;
    } else {
      time = '';
      currentTimeCount -= 1;
    }
    const agentName = name !== userInfo.firstname ? name : '';
    return { date, name: agentName, time };
  };

  return (
    <ChatContentSection
      fetchDateChanged={fetchDateChanged}
      messageList={chatInfo && chatInfo[groupChatInfo.groupChatId]
        && chatInfo[groupChatInfo.groupChatId].messageInfo
        && chatInfo[groupChatInfo.groupChatId].messageInfo
          .filter((msgData) => msgData.rtype === 'I' || msgData.rtype === 'N' || msgData.rtype === 'T')}
      userInfo={userInfo}
    />
  );
});

ChatContentSectionContainer.propTypes = {
  offsetTime: PropTypes.string,
  chatInfo: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  groupChatInfo: PropTypes.instanceOf(Object),
};

ChatContentSectionContainer.defaultProps = {
  offsetTime: '',
  chatInfo: {},
  userInfo: {},
  groupChatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
  userInfo: state.auth.userInfo,
  offsetTime: state.auth.offsetTime,
  groupChatInfo: state.tickets.groupChatInfo,
});

export default connect(
  mapStateToProps,
)(ChatContentSectionContainer);
