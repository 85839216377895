import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { withDeviceType } from '../../../../../hoc';
import DefaultNote from './DefaultNote';
import * as actions from '../../../redux/actions';
import { noop } from '../../../../../utils';

import './default_note.scss';

const DefaultNoteContainer = ({
  setButtonInputClick,
  setInputSubmitType,
  setInputType,
  deviceType,
  chatInfo,
}) => {
  const matchParams = useParams();
  const handleReply = () => {
    setInputType('M');
    setInputSubmitType('');
    setButtonInputClick('OK');
  };

  const handleNote = () => {
    setInputType('N');
    setInputSubmitType('');
    setButtonInputClick('OK');
  };

  return (
    <DefaultNote
      handleNote={handleNote}
      deviceType={deviceType}
      handleReply={handleReply}
      previewInfo={chatInfo[matchParams.conversationid].previewInfo}
    />
  );
};

DefaultNoteContainer.propTypes = {
  deviceType: PropTypes.string,
  setInputType: PropTypes.func,
  setInputSubmitType: PropTypes.func,
  setButtonInputClick: PropTypes.func,
  chatInfo: PropTypes.instanceOf(Object),
};

DefaultNoteContainer.defaultProps = {
  deviceType: '',
  setInputType: noop,
  setInputSubmitType: noop,
  setButtonInputClick: noop,
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
});

const mapDispatchToProps = (dispatch) => ({
  setInputType: (type) => dispatch(actions.setInputType(type)),
  setInputSubmitType: (type) => dispatch(actions.setInputSubmitType(type)),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(DefaultNoteContainer));
