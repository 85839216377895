import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MainTemplate from './MainTemplate';

import './main_template.scss';

const MainTemplateContainer = React.memo(({
  workingHoursModal,
  children,
  isTheme,
}) => (
  <MainTemplate
    workingHoursModal={workingHoursModal}
    isTheme={isTheme}
  >
    {children}
  </MainTemplate>
));

MainTemplateContainer.propTypes = {
  workingHoursModal: PropTypes.bool,
  isTheme: PropTypes.bool,
};

MainTemplateContainer.defaultProps = {
  workingHoursModal: false,
  isTheme: false,
};

MainTemplateContainer.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

const mapStateToProps = (state) => ({
  workingHoursModal: state.auth.workingHoursModal,
  isTheme: state.localInfo.isTheme,
});

export default connect(
  mapStateToProps,
)(MainTemplateContainer);
