import React, { useState } from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import { connect } from 'react-redux';

import CustomBadge from './CustomBadge';
import { actions } from '../../../modules/tickets';
import { noop, moveArrayItemToNewIndex, deepCopy } from '../../../utils';

import './custom_badge.scss';

const CustomBadgeContainer = ({
  pushActiveTabList,
  activeTabsInfo,
  linearLoading,
  setActiveTabs,
  rightClick,
  totalcount,
  rightIcon,
  className,
  tabType,
  keyText,
  active,
  length,
  title,
}) => {
  const [editable, setEditable] = useState(false);

  const handleCloseEditable = () => {
    setEditable(false);
  };

  const leftHandler = (e) => {
    e.stopPropagation();
    setEditable(true);
  };

  const rightHandler = (e) => {
    e.stopPropagation();
    rightClick();
  };

  const fetchActiveTabs = (tabList) => {
    pushActiveTabList(tabList);
    const metaBase64 = base64.encode(
      encodeURIComponent(JSON.stringify(tabList)),
    );
    setActiveTabs(metaBase64);
  };

  const handleMoveTab = (direction, key) => {
    const tabsList = deepCopy(activeTabsInfo);
    const max = tabsList.length - 1;
    const currentIndex = tabsList.findIndex((tabItem) => tabItem.key === key);
    let newMovedtabs = [];
    if (direction === 'right' && currentIndex !== max) {
      newMovedtabs = moveArrayItemToNewIndex(activeTabsInfo, currentIndex, currentIndex + 1);
      fetchActiveTabs(newMovedtabs);
    } else if (direction === 'left' && currentIndex !== 0) {
      newMovedtabs = moveArrayItemToNewIndex(activeTabsInfo, currentIndex, currentIndex - 1);
      fetchActiveTabs(newMovedtabs);
    }
  };

  return (
    <CustomBadge
      title={title}
      active={active}
      length={length}
      keyText={keyText}
      tabType={tabType}
      editable={editable}
      rightIcon={rightIcon}
      className={className}
      totalcount={totalcount}
      leftHandler={leftHandler}
      rightHandler={rightHandler}
      linearLoading={linearLoading}
      handleMoveTab={handleMoveTab}
      handleCloseEditable={handleCloseEditable}
      activeTabsLength={activeTabsInfo && activeTabsInfo.length}
      tabIndexState={activeTabsInfo.findIndex((tabItem) => tabItem.key === keyText)}
    />
  );
};

CustomBadgeContainer.propTypes = {
  title: PropTypes.string,
  keyText: PropTypes.string,
  className: PropTypes.string,
  totalcount: PropTypes.string,
  length: PropTypes.number,
  tabType: PropTypes.string,
  active: PropTypes.bool,
  linearLoading: PropTypes.bool,
  rightClick: PropTypes.func,
  setActiveTabs: PropTypes.func,
  pushActiveTabList: PropTypes.func,
  activeTabsInfo: PropTypes.instanceOf(Array),
  rightIcon: PropTypes.oneOfType([PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

CustomBadgeContainer.defaultProps = {
  title: '',
  keyText: '',
  className: '',
  totalcount: '',
  length: 0,
  tabType: '',
  active: false,
  linearLoading: false,
  rightClick: noop,
  setActiveTabs: noop,
  pushActiveTabList: noop,
  activeTabsInfo: [],
};

const mapStateToProps = (state) => ({
  activeTabsInfo: state.tickets.activeTabsInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTabs: (code) => dispatch(actions.setActiveTabs(code)),
  pushActiveTabList: (tabs) => dispatch(actions.pushActiveTabList(tabs)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(CustomBadgeContainer);
