import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Resizable } from 're-resizable';

import { SimpleInput } from '../../../../../shared/components/FormControl';
import { noop } from '../../../../../utils';

const TextEditor = ({
  handleForwardInput,
  forwardError,
  footerActions,
  setInputText,
  setInputRef,
  deviceType,
}) => (
  <>
    {footerActions.submitType === 'forward' && (
      <div className={classnames('forward_input_wrapper', { forwardError })}>
        <span>Forward to:</span>
        <div className="forward_input">
          <SimpleInput
            type="text"
            value={footerActions.forwardEmail}
            onChange={handleForwardInput}
          />
          {forwardError && <p>Invalid email address</p>}
        </div>
      </div>
    )}
    <Resizable
      minWidth="calc(100% - 3px)"
      maxHeight={deviceType === 'desktop' ? '300' : '150'}
      minHeight={deviceType === 'desktop' ? '140' : '100'}
      defaultSize={{
        width: '99.5%',
        height: deviceType === 'desktop' ? '300' : '100',
      }}
    >
      <SimpleInput
        type="textarea"
        value={footerActions.inputMessage}
        onChange={setInputText}
        className="text_editor_section"
        innerRef={setInputRef}
        id="msg-text-editor"
      />
    </Resizable>
  </>
);

TextEditor.propTypes = {
  deviceType: PropTypes.string,
  forwardError: PropTypes.bool,
  setInputText: PropTypes.func,
  setInputRef: PropTypes.func,
  handleForwardInput: PropTypes.func,
  footerActions: PropTypes.instanceOf(Object),
};

TextEditor.defaultProps = {
  deviceType: '',
  forwardError: false,
  setInputText: noop,
  setInputRef: noop,
  handleForwardInput: noop,
  footerActions: {},
};

export default TextEditor;
