import React from 'react';
import PropTypes from 'prop-types';
import { MdMoreVert } from 'react-icons/md';
import {
  FaHourglassStart, FaRedo, FaRandom, FaRegHandshake,
} from 'react-icons/fa';

import { RenderButton, RenderDropDown } from '../../../../../../shared/components/FormControl';
import { noop } from '../../../../../../utils';

const RightButtons = ({
  resolvedSubmitAction,
  onHandlePostpone,
  onHandleTransfer,
  onHandlerReopen,
  deviceType,
  chatInfo,
}) => (
  <>
    {deviceType === 'desktop' && (
      <>
        <RenderButton onClick={() => { onHandlePostpone(); }}>
          <FaHourglassStart />
          PostPone
        </RenderButton>
        <RenderButton onClick={() => { onHandleTransfer(); }}>
          <FaRandom />
          Transfer
        </RenderButton>
        {chatInfo.previewInfo
        && (chatInfo.previewInfo.rstatus === 'R'
          || chatInfo.previewInfo.rstatus === 'A') ? (
            <RenderButton onClick={() => { onHandlerReopen(); }}>
              <FaRedo />
              Reopen
            </RenderButton>
          ) : (
            <>
              <RenderButton onClick={() => { resolvedSubmitAction(); }}>
                <FaRegHandshake />
                Resolve
              </RenderButton>
              <RenderButton
                onClick={() => { resolvedSubmitAction('closeTab'); }}
              >
                <FaRegHandshake />
                Resolve & Close
              </RenderButton>
            </>
          )}
      </>
    )}
    {deviceType === 'mobile' && (
      <RenderDropDown icon={<MdMoreVert />} id="moredropdown">
        <li onClick={() => { onHandlePostpone(); }} role="presentation">
          <FaHourglassStart />
          Postpone
        </li>
        <li onClick={() => { onHandleTransfer(); }} role="presentation">
          <FaRandom />
          Transfer
        </li>
        {chatInfo.previewInfo
        && (chatInfo.previewInfo.rstatus === 'R'
          || chatInfo.previewInfo.rstatus === 'A') ? (
            <li onClick={() => { onHandlerReopen(); }} role="presentation">
              <FaRedo />
            Reopen
            </li>
          ) : (
            <>
              <li onClick={() => { resolvedSubmitAction(); }} role="presentation">
                <FaRegHandshake />
              Resolve
              </li>
              <li
                onClick={() => { resolvedSubmitAction('closeTab'); }}
                role="presentation"
              >
                <FaRegHandshake />
                Resolve & Close
              </li>
            </>
          )}
      </RenderDropDown>
    )}
  </>
);

RightButtons.propTypes = {
  deviceType: PropTypes.string,
  onHandlePostpone: PropTypes.func,
  onHandleTransfer: PropTypes.func,
  onHandlerReopen: PropTypes.func,
  resolvedSubmitAction: PropTypes.func,
  chatInfo: PropTypes.instanceOf(Object),
};

RightButtons.defaultProps = {
  deviceType: '',
  onHandlePostpone: noop,
  onHandleTransfer: noop,
  onHandlerReopen: noop,
  resolvedSubmitAction: noop,
  chatInfo: {},
};

export default RightButtons;
