import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import TodoDialog from './TodoDialog';
import * as localInfoActions from '../../../TodoRedux/actions';
import { noop } from '../../../../utils';
import { required } from '../../../../utils/validators';

import './todo_dialog.scss';

const TodoDialogConatiner = React.memo(({
  handleModal,
  setTodoList,
  isModal,
  subject,
  url,
  id,
}) => {
  const onSubmit = (value) => {
    const uniqueId = id || value.title + (value.description || '') + moment().format();
    const prevTodoList = localStorage.getItem('todo-list') ? JSON.parse(localStorage.getItem('todo-list')) : '';
    let todo;
    const TODO_DATA = {
      subject: value.title, subTitle: value.description, link: url, id: uniqueId, date: moment(),
    };
    if (prevTodoList) {
      todo = [...prevTodoList, TODO_DATA];
    } else {
      todo = [TODO_DATA];
    }
    setTodoList([{
      subject: value.title, subTitle: value.description, link: url, id: uniqueId, date: moment(),
    }]);
    localStorage.setItem('todo-list', JSON.stringify(todo));
    handleModal();
  };

  const validateForm = (values) => {
    const errors = {};
    errors.title = required(values.title) && 'Title is required';
    return errors;
  };

  return (
    <TodoDialog
      initialValues={{
        title: subject || '',
      }}
      isModal={isModal}
      onSubmit={onSubmit}
      handleModal={handleModal}
      validate={validateForm}
    />
  );
});

TodoDialogConatiner.propTypes = {
  subject: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  isModal: PropTypes.bool,
  handleModal: PropTypes.func,
  setTodoList: PropTypes.func,
};

TodoDialogConatiner.defaultProps = {
  subject: '',
  id: '',
  url: '',
  isModal: false,
  handleModal: noop,
  setTodoList: noop,
};

const mapDispatchToProps = (dispatch) => ({
  setTodoList: (todo) => dispatch(localInfoActions.setTodoList(todo)),
});

export default connect(
  null, mapDispatchToProps,
)(TodoDialogConatiner);
