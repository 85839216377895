import React from 'react';
import PropTypes from 'prop-types';

import ImageLightBox from './ImageLightBox';
import { noop } from '../../../utils';

const preventRendering = (prevProps, nextProps) => {
  if (JSON.stringify(nextProps) !== JSON.stringify(prevProps)) {
    return false;
  } return true;
};

const ImageLightBoxContainer = React.memo(({
  visible,
  onClose,
  images,
  activeIndex,
}) => (
  <ImageLightBox
    images={images}
    visible={visible}
    setVisible={onClose}
    activeIndex={activeIndex}
  />
),
(prevProps, nextProps) => preventRendering(prevProps, nextProps));

ImageLightBoxContainer.propTypes = {
  activeIndex: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  images: PropTypes.instanceOf(Array),
};

ImageLightBoxContainer.defaultProps = {
  activeIndex: 0,
  visible: false,
  onClose: noop,
  images: [],
};

export default ImageLightBoxContainer;
