import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import api from '../../../../utils/api';
import TicketListView from './TicketListView';
import TicketsContainer from '../TicketsContainer';
import * as actions from '../../redux/actions';
import { withDeviceType } from '../../../../hoc';
import { noop, deepCopy, keyGeneration } from '../../../../utils';
import { showNotification } from '../../../../utils/Notifications';

import './ticket_list_view.scss';

const TicketListViewContainer = React.memo(({
  getCustomSearchTicket,
  setActiveTabLoading,
  setSearchedTickets,
  getSearchTickets,
  setSearchKeyword,
  setLoadingState,
  removeActiveTab,
  pushActiveTabs,
  activeTabsInfo,
  assignTickets,
  setActiveTabs,
  deviceType,
  getTickets,
  tickets,
  users,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { search } = location;
  const params = new URLSearchParams(search);
  const q = params.get('q');
  const status = params.get('status');
  const agentid = params.get('agentid');
  const unassigned = params.get('unassigned');
  const invert = params.get('invert');
  const dateStart = params.get('date_start');
  const dateEnd = params.get('date_end');
  const meta = params.get('meta');
  const tabName = params.get('tab_name') || params.get('tab_tame');
  const lastDays = params.get('last_x_days');
  let GridTicketsData = [];
  let ticketLoading = false;

  const fetchStartingDate = () => moment().subtract(lastDays - 1, 'days').format('YYYY-MM-DD');

  const keyGen = keyGeneration(search);

  const setActiveBadge = async (title, ticketLength) => {
    const copyOfTabsInfo = deepCopy(activeTabsInfo);
    let prevTabInfo;
    if (copyOfTabsInfo && copyOfTabsInfo.length === 0) {
      await api.get('command.jsp?command=get_state').then((tabsRes) => {
        const tabs = tabsRes.data.saved_state;
        if (tabs) {
          prevTabInfo = JSON.parse(decodeURIComponent(base64.decode(tabs)));
        }
      });
    } else {
      prevTabInfo = copyOfTabsInfo.map(({
        messageCount, isLoading, length, ...rest
      }) => ({ ...rest }));
    }
    const index = prevTabInfo.findIndex((ticketInfo) => ticketInfo.key === title);
    if (index === -1) {
      prevTabInfo = [
        ...prevTabInfo,
        {
          type: 'search', key: title, subject: q, status, agentid, unassigned, invert, dateStart, dateEnd, meta, length: ticketLength, lastDays, tabName,
        },
      ];
      const metaBase64 = base64.encode(
        encodeURIComponent(JSON.stringify(prevTabInfo)),
      );
      if (metaBase64.length < 50000) {
        const encodeUrl = (metaBase64);
        setActiveTabs(encodeUrl).then((res) => {
          if (res.value.data.response_msg === 'SAVED') {
            pushActiveTabs({
              type: 'search', key: title, subject: q, status, agentid, unassigned, invert, dateStart, dateEnd, meta, length: ticketLength, lastDays, tabName,
            });
          }
        });
      } else {
        showNotification('You reach maximum tab Limit', 'error', 5000);
      }
    }
  };

  const fetchSearchTicket = () => {
    clearTimeout(window.fetchSearchTimeout);
    getSearchTickets(q).then((res) => {
      if (res.value.data.length === 1) {
        removeActiveTab(q, 'search').then(() => {
          history.push(`/ticket/${res.value.data[0].code}/${res.value.data[0].conversationid}`);
        });
      } else {
        setActiveBadge(q, res.value.data.length);
        setSearchedTickets(q, res.value.data);
        setLoadingState(q, false);
        setActiveTabLoading(q, false);
      }
      window.fetchSearchTimeout = setTimeout(() => {
        fetchSearchTicket();
      }, 30000);
    });
  };

  const fetchGetTicket = () => {
    ticketLoading = true;
    clearTimeout(window.clearTickets);
    getTickets().finally(() => {
      window.clearTickets = setTimeout(() => {
        if (window.clearTickets) { fetchGetTicket(); }
        ticketLoading = false;
      }, 30000);
    });
  };

  const fetchCustomSearchTicket = () => {
    clearTimeout(window.fetchSearchTimeout);
    const startingDate = lastDays ? fetchStartingDate() : dateStart;
    getCustomSearchTicket(q, status, agentid, unassigned, invert, startingDate, dateEnd)
      .then((resInfo) => {
        const res = resInfo.value.data;
        const tabItemIndex = activeTabsInfo.findIndex((tabItem) => tabItem.key === keyGen);
        if (tabItemIndex === -1 && res.length === 1) {
          setTimeout(() => {
            history.push(`/ticket/${res[0].code}/${res[0].conversationid}`);
          }, 500);
        }
        setLoadingState(keyGen, false);
        setActiveTabLoading(keyGen, false);
        setActiveBadge(keyGen, res.length);
        setSearchedTickets(keyGen, res);
        window.fetchSearchTimeout = setTimeout(() => {
          fetchCustomSearchTicket();
        }, 30000);
      });
  };

  const handleSearchTicket = async (searchKey) => {
    await setSearchKeyword(searchKey);
    await setLoadingState(searchKey, true);
    if (meta) {
      fetchCustomSearchTicket();
    } else {
      fetchSearchTicket();
    }
  };

  if (search) {
    if (tickets[keyGen]) {
      GridTicketsData = tickets[keyGen].ticketList;
      ticketLoading = tickets[keyGen] && tickets[keyGen].isLoading;
    }
  } else {
    GridTicketsData = assignTickets;
  }

  useEffect(() => {
    if (search) {
      if (keyGen) {
        if ((!tickets[keyGen] || !tickets[keyGen].ticketList)
          || tickets[keyGen].ticketList.length <= 0) {
          handleSearchTicket(keyGen);
        } else {
          setActiveTabLoading(keyGen, true);
          setSearchKeyword(keyGen);
          if (meta) {
            fetchCustomSearchTicket();
          } else {
            fetchSearchTicket();
          }
        }
      }
    } else if (location.pathname === '/ticket') {
      setTimeout(() => {
        fetchGetTicket();
      }, 30000);
    }
    return () => {
      clearTimeout(window.fetchSearchTimeout);
      clearTimeout(window.clearTickets);
    };
  }, []);

  return (
    <TicketsContainer>
      {GridTicketsData && (
        <Helmet>
          <title>
            {!search ? 'Tickets' : `(${GridTicketsData.length}) ${(tabName || q || 'Custom Search') || 'Tickets'}`}
          </title>
        </Helmet>
      )}
      <TicketListView
        gridData={GridTicketsData || []}
        ticketLoading={ticketLoading}
        deviceType={deviceType}
        users={users}
      />
    </TicketsContainer>
  );
});

TicketListViewContainer.propTypes = {
  deviceType: PropTypes.string,
  getSearchTickets: PropTypes.func,
  setSearchKeyword: PropTypes.func,
  setLoadingState: PropTypes.func,
  setActiveTabs: PropTypes.func,
  pushActiveTabs: PropTypes.func,
  removeActiveTab: PropTypes.func,
  setSearchedTickets: PropTypes.func,
  getTickets: PropTypes.func,
  getCustomSearchTicket: PropTypes.func,
  setActiveTabLoading: PropTypes.func,
  assignTickets: PropTypes.instanceOf(Array),
  users: PropTypes.instanceOf(Array),
  activeTabsInfo: PropTypes.instanceOf(Array),
  tickets: PropTypes.instanceOf(Object),
};

TicketListViewContainer.defaultProps = {
  deviceType: '',
  getSearchTickets: noop,
  setSearchKeyword: noop,
  setLoadingState: noop,
  setActiveTabs: noop,
  pushActiveTabs: noop,
  removeActiveTab: noop,
  setSearchedTickets: noop,
  getTickets: noop,
  getCustomSearchTicket: noop,
  setActiveTabLoading: noop,
  tickets: {},
  assignTickets: [],
  users: [],
  activeTabsInfo: [],
};

const mapStateToProps = (state) => ({
  assignTickets: state.tickets.assignTickets,
  tickets: state.tickets.tickets,
  users: state.auth.users,
  activeTabsInfo: state.tickets.activeTabsInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchKeyword: (q) => dispatch(actions.setSearchKeyword(q)),
  getSearchTickets: (q) => dispatch(actions.getSearchTickets(q)),
  getTickets: () => dispatch(actions.getTickets()),
  setLoadingState: (key, isLoading) => dispatch(
    actions.setLoadingState(key, isLoading),
  ),
  setActiveTabLoading: (key, isLoading) => dispatch(
    actions.setActiveTabLoading(key, isLoading),
  ),
  setActiveTabs: (code) => dispatch(actions.setActiveTabs(code)),
  pushActiveTabs: (tabInfo) => dispatch(actions.pushActiveTabs(tabInfo)),
  removeActiveTab: (key, type) => dispatch(actions.removeActiveTab(key, type)),
  setSearchedTickets: (key, data) => dispatch(actions.setSearchedTickets(key, data)),
  getCustomSearchTicket: (q, status, agentid, unassigned, invert, startDate,
    endDate) => dispatch(actions.getCustomSearchTicket(q, status, agentid, unassigned,
    invert, startDate, endDate)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(TicketListViewContainer));
