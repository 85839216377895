import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const HiddenFeature = ({
  hiddenChildValue,
  isHiddenFeatures,
  children,
}) => (
  <>
    {isHiddenFeatures && hiddenChildValue && children}
  </>
);

HiddenFeature.propTypes = {
  isHiddenFeatures: PropTypes.bool,
  hiddenChildValue: PropTypes.bool,
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

HiddenFeature.defaultProps = {
  isHiddenFeatures: false,
  hiddenChildValue: true,
};

const mapStateToProps = (state) => ({
  isHiddenFeatures: state.localInfo.isHiddenFeatures,
});

export default connect(
  mapStateToProps,
)(HiddenFeature);
