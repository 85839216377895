import React from 'react';
import Clock from 'react-live-clock';
import moment from 'moment';

import times from '../../../config/world-time';

import './world_clock.scss';

const WorldClock = React.memo(() => (
  <div className="world_clock_section">
    {times.map((time) => (
      <div className="word_clock_item" key={time.label}>
        <span>{time.label}</span>
        <Clock
          filter={
      (dt) => {
        const curDate = parseInt(moment().format('D'));//eslint-disable-line
        const date = moment(dt, 'YYYY-MM-DD hh:mm:ss a');
        const thsDate = parseInt(date.format('D'));//eslint-disable-line
        let postfix = '';
        if (thsDate > curDate) postfix = '+1';
        else if (thsDate < curDate) postfix = '-1';
        return `${date.format('hh:mm a')} ${postfix}`;
      }
      }
          format="YYYY-MM-DD hh:mm:ss a"
          interval={60000}
          timezone={time.timezone}
        />
      </div>
    ))}
  </div>
));

export default WorldClock;
